.mail-logo {
  text-align: center;
}
.mail-text {
  color: #707575;
  font-size: 16px;
  line-height: 22px;
}
.mail-thank-wrapper {
  width: 95%;
}
.thank-wrapper {
  width: 85%;
}
