.overall-ans {
  box-sizing: border-box;
  height: 35px;
  min-width: 74px;
  border: 1px solid $theme-color-green;
  border-radius: 17.5px;
  background-color: #f0f0f0;
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  padding-top: 5px;
}

.overall-prob-info {
  color: #707575;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.report-head {
  color: $theme-color-green;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
}
.mchart-graph {
  text-align: center;
}
.mchart-graph img {
  width: 281px;
}
.score i {
  font-size: 21px;
  color: #9ca2a2;
}
.mchart-details {
  /* width: 375px; */
  border-radius: 55px 55px 0 0;
  background-color: #d0fae6;
  margin: 0 -20px;
}
.mchart-details.asq {
  background-color: #e2f2f3;
}
// .mchart-details::before {
//   content: "";
//   box-sizing: border-box;
//   height: 2px;
//   width: 55px;
//   border: 4px solid #bfbebe;
//   position: relative;
//   top: 15px;
//   border-radius: 5px;
//   display: block;
//   margin: 0 auto;
// }
.mchart-details {
  .pull-line {
    //  content: "";
    box-sizing: border-box;
    height: 2px;
    width: 55px;
    border: 4px solid #bfbebe;
    position: relative;
    top: 15px;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
  }
}
.mchart-name2 {
  position: relative;
  left: 10px;
}
.mchart-result {
  padding: 0px 35px;
}
.mchart-response p {
  color: #707575;
  font-size: 16px;
  // font-weight: 600;
  line-height: 22px;
}
.mchart-response a {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.mchart-response {
  padding: 0px 35px;
}
.mchart-button {
  width: 80%;
  margin: 0 auto;
}
.mchart-pdf {
  text-align: center;
}
.mchart-pdf a {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.bg-colour {
  background-color: #6dbac2;
}
@media only screen and (max-width: 350px) {
  .bg-colour {
    background-color: #6dbac2;
  }
}

.score-meaning {
  color: #707575;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.child-detail-divider {
  height: 1px;
  background: rgba(109, 186, 194, 0.5);
  width: 75%;
  margin: auto;
}
