.home-img-phy {
  width: 100%;
  padding: rem-calc(50px) rem-calc(25px) rem-calc(25px);
  margin: auto;

  @include screen(custom, min, 768) {
    width: 50%;
  }
}

.invite-patient-btn {
  width: 64%;
  margin-left: 18%;
  margin-right: 18%;

  @include screen(custom, min, 768) {
    width: 36%;
    margin-left: 32%;
    margin-right: 32%;
  }
}

.invite-image-container {
  padding: 0px;
  background: none;
}

.form-wrap-invite {
  margin: rem-calc(20px);
  @include screen(custom, min, 768) {
    margin: 0;
  }
}

.tooltip-text-icon {
  display: inline-block;
  vertical-align: middle;
  img {
    margin-bottom: -2px;
  }
}

.detail-card-phy {
  margin-top: 0;
}
.high-risk-tooltip .tooltip-inner {
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
  color: #777777;
  padding: 10;
  min-width: 400px;
  text-align: left;
  .tt-header {
    align-items: center;
    font-size: rem-calc(18px);
    line-height: rem-calc(20px);
    letter-spacing: rem-calc(0.24px);
    border-bottom: 1px solid #f7f7f7;
    svg {
      margin-right: rem-calc(8px);
    }
    img {
      margin-right: rem-calc(8px);
    }
  }
  .legend-info {
    .asq-type {
      font-size: rem-calc(14px);
      line-height: rem-calc(17px);
      letter-spacing: rem-calc(0.186667px);
      font-weight: 500;
      .info {
        font-weight: normal;
        align-items: center;
      }
      .indicator {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #f6656d;
        align-self: center;
      }
      .yellow {
        background-color: #e4d654;
      }
      .green {
        background-color: #25858f;
      }
    }
  }
}

.expand-assessment-date {
  font-style: normal;
  font-weight: normal;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  letter-spacing: rem-calc(0.213333px);
  color: #767575;
}
.high-risk-tooltip .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top: 5px solid #ffffff;
}

.dashboard-text-top {
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  letter-spacing: 0.186667px;
  color: #666666;
}

.add-patients {
  font-weight: bold;
  font-size: rem-calc(16px);
  line-height: rem-calc(19px);
  text-align: center;
  letter-spacing: 0.213333px;
  color: #ffffff;
  background: #f6656d;
  box-shadow: inset 2px 4px 3px #eeadad,
    inset -5px -3px 3px rgba(181, 37, 37, 0.5);
  border-radius: 25px;
  padding: rem-calc(2px) rem-calc(30px);
  border: none;
  // margin-left: auto;
  height: 40px;
}

.patient-table-nav {
  background: #ffffff;
  border: 0.5px solid #eeeeee;
  box-shadow: 0px -2px 4px rgba(231, 231, 231, 0.5);
  border-radius: 2px;
  display: inline-block;
  padding: rem-calc(10px) 0;
  border-bottom: none;
  .nav-item {
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    letter-spacing: 0.186667px;
    color: #5d5d5d;
    display: flex;
    padding: rem-calc(0px) rem-calc(25px);
    &:not(:last-child) {
      border-right: 1px solid #e9e9e9;
    }
    &.active {
      color: #033e39;
      font-weight: bold;
    }
  }
}

.dashboard-paitent-table {
  background: #ffffff;
  border: 0.5px solid #eeeeee;
  box-shadow: 0px 2px 4px rgba(231, 231, 231, 0.5);
  padding: rem-calc(15px);
  overflow: auto;
  .seperator {
    &:first-child {
      width: 25%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 60%;
    }
  }
  .seperator-2 {
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
  .seperator-3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.213333px;
    color: #525252;
    &:nth-child(1) {
      width: 27%;
    }
    &:nth-child(2) {
      width: 27%;
    }
    &:nth-child(3) {
      width: 23%;
    }
    &:nth-child(4) {
      width: 23%;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.186667px;
      color: #777777;
    }
  }
  .link {
    color: #e98760;
    cursor: pointer;
    font-weight: 600;
  }
  .sub-column {
    width: 25%;
  }
}

.patient-table-header {
  min-width: 1120px;
  padding: rem-calc(10px) rem-calc(50px);
  background: #f1efef;
  border-radius: 6px;
  .table-header {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    letter-spacing: 0.186667px;
    color: #777777;
    font-weight: 600;
    &:first-child {
      width: 25%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 60%;
    }
  }
  .table-header-2 {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    letter-spacing: 0.186667px;
    color: #777777;
    font-weight: 600;
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
  .table-header-3 {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    letter-spacing: 0.186667px;
    color: #777777;
    font-weight: 600;
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 27%;
    }
    &:nth-child(3) {
      width: 27%;
    }
    &:nth-child(4) {
      width: 23%;
    }
    &:nth-child(5) {
      width: 23%;
    }
  }
}

.patient-card-wrap {
  position: relative;
  min-height: 60px;
  overflow: hidden;
  &.card-open {
    max-height: initial;
    .patient-card {
      border-radius: 4px 4px 0 0;
    }
    .arrow-icon {
      transform: rotate(0deg);
    }
  }
}

.patient-card {
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 2px 4px #e1e1e1;
  border-radius: 4px;
  padding: rem-calc(20px) rem-calc(50px);
  font-weight: 600;
  font-size: rem-calc(16px);
  line-height: rem-calc(18px);
  letter-spacing: 0.213333px;
  color: #525252;
  .value {
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }
  .name:hover {
    text-decoration: underline;
  }
}
.patient-card-sub {
  padding: rem-calc(20px) rem-calc(50px);
  background: #f1f0f0;
  border-radius: 0px 0px 4px 4px;
  box-shadow: none;
  .value {
    font-weight: 600;
    font-size: rem-calc(16px);
    line-height: rem-calc(20px);
    letter-spacing: 0.213333px;
    color: #525252;
  }
  .seperator-3 {
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
    }
  }
}

.patient-card-holder {
  min-width: 1120px;
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.arrow-icon {
  position: absolute;
  left: 25px;
  top: 22px;
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.value-legend {
  &::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #f6656d;
    border-radius: 2px;
    margin-left: 5px;
  }
  &.green {
    &::after {
      background: #25858f;
    }
  }
  &.yellow {
    &::after {
      background: #f0e442;
    }
  }
}

.value-legend-mark {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #f6656d;
  border-radius: 2px;
  margin-left: 5px;
  &.green {
    background: #25858f;
  }
  &.yellow {
    background: #f0e442;
  }
}

.action-required-div {
  background: rgba(243, 81, 81, 0.23);
  border-radius: rem-calc(22px);
  font-weight: 600;
  font-size: 12px !important;
  line-height: rem-calc(20px);
  letter-spacing: rem-calc(0.213333px);
  color: #f35151;
  padding: rem-calc(4px 8px);
}

.delete-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.delete-modal {
  & .modal-popup-container {
    max-width: 550px !important;

    & .delete-child-popup-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 146.5%;
      letter-spacing: 0.64px;
      color: #333333;
    }
    & .popup-cancel-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-decoration-line: underline;
      color: #f6656d;
    }
  }
}

.delete-child-details {
  cursor: pointer;
}

.physician-dashboard-container {
  & .modal-popup-container-2 {
    max-width: 819px !important;
    overflow: auto;
  }
}

.add-user {
  color: #fff !important;
}

.add-user-button {
  background-color: #a5a6a7;
  height: 50px;
  padding: 16px;
  border-radius: 10px;
  color: #fff;
}

.image-upload {
  border: dashed 3px #c1cecf;
  background-color: #a5a6a7;
  width: 70px;
  height: 70px;
  margin-right: 5%;
}

.image-upload-icon {
  color: #fff !important;
}

.image-upload-input-label {
  position: relative;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.image-upload-input {
  opacity: 0;
  width: 0;
  height: 0;
}
