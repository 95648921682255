.sidenav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 11;
}
.sidenav {
  min-width: 280px;
  width: 70%;
  height: 100vh;
  background: white;
  padding-right: 40px !important;
  overflow: auto;
}
.sidenav-logo img {
  // width: 60px;
  height: 60px;
}
.sidenav-logo p {
  color: $theme-color-green;
  font-size: 24px;
  line-height: 29px;
}
.sidenav-list {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #6dbac2;
  cursor: pointer;
}
.sidenav-list i {
  font-size: 20px;
}
.sidenav-list p {
  font-family: "transcendregular";
  font-size: 20px;
  color: #707575;
  line-height: 26px;
  margin-left: 10px;
  margin-top: 7px;
}

// .sidenav-list:first-child i {
//   color: $theme-color-green !important;
// }
// .sidenav-list:nth-child(2) i {
//   color: #6dbac2 !important;
// }
// .sidenav-list:nth-child(3) i {
//   color: #c26d9c !important;
// }
// .sidenav-list:nth-child(4) i {
//   color: #f6656d !important;
// }
// .sidenav-list:nth-child(5) i {
//   color: $theme-color-green !important;
// }
// .sidenav-list:nth-child(6) i {
//   color: #f6656d !important;
// }
// .sidenav-list:nth-child(7) i {
//   color: #79b89b !important;
// }
// .sidenav-list:nth-child(8) i {
//   color: #6dbac2 !important;
// }
// .sidenav-list:last-child i {
//   color: $theme-color-green !important;
// }
