@mixin page-header {
  font-size: rem-calc(24px);
  font-weight: bold;
  color: $text-header;
  line-height: rem-calc(29px);
  font-family: "Lato-Bold";
}

@mixin page-sub-header {
  font-size: rem-calc(20px);
  font-weight: bold;
  color: $text-header;
  line-height: rem-calc(24px);
  font-family: "Lato-Bold";
}

@mixin sec-header {
  font-size: rem-calc(18px);
  font-weight: 600;
  color: $text-header;
  line-height: rem-calc(22px);
  font-family: "Lato-Semibold";
}

@mixin sec-sub-header {
  font-size: rem-calc(16px);
  font-weight: 500;
  color: $text-header;
  line-height: rem-calc(19px);
  font-family: "Lato-Medium";
}

@mixin button {
  color: $text-color-1;
  font-family: "Lato-Regular";
  font-size: rem-calc(15px);
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(18px);
  text-align: center;
  border-radius: 4px;
  background-color: $btn-color;
  width: 100%;
  padding: rem-calc(10px);
  text-transform: uppercase;
  border: none;
}

@mixin button-outline {
  font-family: "Lato-Regular";
  font-size: rem-calc(15px);
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(18px);
  text-align: center;
  border-radius: 4px;
  border-color: $btn-color;
  border-width: 1px;
  background-color: transparent;
  width: 100%;
  padding: rem-calc(10px);
  color: $btn-color !important;
}

@mixin button-plain {
  color: $btn-color;
  font-family: "Lato-Regular";
  font-size: rem-calc(15px);
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(18px);
  text-align: center;
  background-color: transparent;
  width: 100%;
  padding: rem-calc(10px);
  border: none;
}

@mixin mdInput {
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #999;
  color: $field-text-color;
  display: block;
  font-size: rem-calc(15px);
  margin-top: rem-calc(24px);
  outline: 0;
  padding: 0 0px 8px 0px;
  width: 100%;
  box-shadow: none !important;

  // IE 10-11
  &:-ms-input-placeholder {
    ~ .form-field__label {
      @include label-inactive();
    }
  }

  // All other browsers except Edge
  &:placeholder-shown {
    ~ .form-field__label {
      @include label-inactive();
    }
  }

  &:focus {
    ~ .form-field__label {
      color: $primary-color;
      @include label-active();
    }

    ~ .form-field__bar {
      border-bottom: 2px solid $primary-color;
      transform: scaleX(150);
    }
  }
}
