@import "../base/placeholder.scss";

.btn {
  @extend %row-center;
  padding: 7px 18px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: no-drop;
    pointer-events: none;
  }
  &:focus {
    box-shadow: none;
  }
  &---primary {
    color: #fff;
    border: 2px solid transparent;
    background: var(--primary-brand);
    box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
      1px 1px 2px rgba(0, 0, 0, 0.25), 6px 6px 7px -5px rgba(0, 0, 0, 0.25);
    &:hover {
      transition: all 300ms;
      background: #84cdd6;
      box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
        1px 1px 2px rgba(0, 0, 0, 0.25), 6px 6px 7px -5px rgba(0, 0, 0, 0.25);
    }
  }
  &---secondary {
    color: #fff;
    border: 2px solid transparent;
    background: var(--secondary-brand);
    box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
      1px 1px 2px rgba(13, 1, 2, 0.25), 6px 6px 7px rgba(13, 1, 2, 0.25);
    &:hover {
      transition: all 300ms;
      background: #c5343c;
      box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
        1px 1px 2px rgba(13, 1, 2, 0.25), 6px 6px 10px 3px rgba(13, 1, 2, 0.15);
    }
  }
  &---outline {
    color: var(--primary-brand);
    border: 2px solid var(--primary-brand);
    background-color: transparent;
    &:hover {
      color: var(--primary-brand);
      transition: all 300ms;
      background: rgba(37, 133, 143, 0.1);
      border: 2px solid transparent;
    }
  }
  &---secondary-outline {
    color: var(--secondary-brand);
    border: 2px solid var(--secondary-brand);
    background-color: #fff;
    &:hover {
      color: var(--secondary-brand);
      background: rgba(246, 101, 109, 0.1);
      border: 2px solid transparent;
      transition: all 300ms;
    }
  }
  &---xs {
    padding: 3px 18px;
  }
  &--green {
    color: var(--secondary-brand);
    border: 2px solid #f6656d;
    background-color: #fff;
    &:hover {
      color: var(--secondary-brand);
      border: 2px solid transparent;
      background: rgba(246, 101, 109, 0.1);
      transition: all 300ms;
    }
  }
  &---br8 {
    border-radius: 8px;
  }
}

.swal-button--confirm {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 7px 32px !important;
  border: 2px solid transparent;
  background: var(--secondary-brand) !important;
  box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
    1px 1px 2px rgba(13, 1, 2, 0.25), 6px 6px 7px rgba(13, 1, 2, 0.25) !important;
  border-radius: 8px;
  &:hover {
    transition: all 300ms;
    background: #c5343c !important;
    box-shadow: -6px -6px 8px #ffffff, -2px -2px 2px #ffffff,
      1px 1px 2px rgba(13, 1, 2, 0.25), 6px 6px 10px 3px rgba(13, 1, 2, 0.15) !important;
  }
}
