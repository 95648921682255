:root {
  --primary-brand-color: #25858f;
}
$border-light-gray: #dedede;
$bg-white: #ffffff;
$primary-text-color: #707575;
$fw-700: 700;
$fw-600: 600;
$font-16: 16px;
$font-18: 18px;
$font-14: 14px;
$font-12: 12px;
$secondary-text-color: #24858f;
$bg-gray: #f0efef;
$dark-gray: #777777;
$dark-gray2: #333333;
$gray-color1: #f6f6f6;
$gray-color2: #f0efef;
$gray-color3: #b2b2b2;
$gray-color4: #f4f4f4;
$gray-color5: #e1e1e1;
$text-col2: #4d4f4f;
$text-col3: #4b4d4d;
$arrow-color: #616161;
$col-red: #f6656d;
$border-col-custom: #cff2e1;
$border-col-custom2: #f2f2f2;
$action-red: #f6656d;
