.create-profile-container {
  padding: 20px;
}
.welcome-sub {
  color: #575758;
  font-size: 16px;
  line-height: 22px;
}
.form-group-date {
  width: 150px !important;
}
.form-group-date .date-icon {
  font-size: 21px;
  color: #9ca2a2;
}

.google-places-autocomplete input {
  @include mdInput;
}
// .hide input[type=file] {
//   display:none;
//   margin:10px;
//   }
//   .hide input[type=file] + label {
//   display:inline-block;
//   margin:20px;
//   padding: 4px 32px;
//   background-color: #FFFFFF;
//   border:solid 1px #666F77;
//   border-radius: 6px;
//   color:#666F77;
//   }
//   #hide input[type=file]:active + label {
//   background-image: none;
//   background-color:#2D6C7A;
//   color:#FFFFFF;
//   }
