@import "../base/placeholder.scss";

.auth-module {
  &-container {
    height: 100vh;
    @extend %row-start;
    background-color: #fff;
    overflow: hidden;
    &--reset-password {
      .auth-module__page-right__main-container {
        width: 385px;
      }
      .auth-module__page-right {
        padding-top: 40px;
      }
      .sticky-note-content {
        position: sticky;
        bottom: 0;
        color: var(--secondary-text);
        font-size: 14px;
        line-height: 1.4;
        background-color: #fff;
        padding-top: 40px;
        padding-bottom: 15px;
        margin-top: auto;
        z-index: 2;
      }
    }
  }
  &__page-left {
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    padding: 40px;
    background: #f3fbfc;
    @media only screen and (max-width: 900px) {
      display: none;
    }
    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__page-right {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 80px 80px 0;
    margin: auto;
    overflow-y: auto;
    @media only screen and (max-width: 1025px) {
      padding: 80px 20px 0;
    }
    &__main-container {
      width: 380px;
      margin: auto;
      @media only screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }
  &__banner {
    flex: 1;
    width: 100%;
    @extend %row-center;
    > img {
      max-width: 100%;
    }
  }
}
.pwd-confirmation-check {
  position: absolute;
  right: -40px;
  top: 12px;
}
.mobile-app-links {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  padding-bottom: 15px;
  background-color: #fff;
}
