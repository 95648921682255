.swal-success {
  .swal-button--confirm {
    background-color: #519fa7;
    box-shadow: inset -3px -3px 3px 0 rgba(37, 133, 143, 0.8),
      inset 3px 3px 3px 0 #95d9e0;
    &:hover {
      background-color: #519fa7;
    }
  }
}
