@import "./variables.scss";
.main-physician,
.main-daycare {
  // margin-top: 68px;
  min-height: calc(100vh - 68px);
  max-height: 100vh;
  background: #f8f8f8;
  overflow-x: hidden;
  overflow-y: auto;
  // font-family: "Open Sans";
  @include screen(custom, min, 700) {
    width: calc(100% - 56px);
    margin-left: 56px;
  }
}

.section-page {
  // max-width: 1200px;.
  max-width: 1225px;
  margin: 0 auto;
}

.section-container-phy {
  background-color: $sec-bg;
  position: relative;
  @include screen(custom, min, 768) {
    // width: calc(100vw - 60px);
    // margin-left: 60px;
  }
}

.section-container-pad-phy {
  padding: rem-calc($section-padding-top) rem-calc($section-padding-right)
    rem-calc($section-padding-bottom) rem-calc($section-padding-left);
}

.section-container-daycare {
  padding: rem-calc($section-padding-top) 0;
  overflow: hidden;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.empty-home-text {
  color: #333333;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.24px;
  text-align: center;
  padding-bottom: rem-calc(30px);
}

.empty-home-text.verification {
  width: 100%;
  margin: auto;
  @include screen(custom, min, 768) {
    width: 40%;
  }
}

.breadcrumb-div {
  display: flex;
}

.breadcrumb-div a {
  color: #666666;
}
.breadcrumb-text {
  font-style: normal;
  font-weight: normal;
  font-size: rem-calc(12px);
  line-height: rem-calc(20px);
  letter-spacing: rem-calc(0.16px);
  color: #666666;
  width: fit-content;
}
.inner-sub-heading {
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: $text-col2;
  }
}
.profile-detail-div {
  .title-main {
    h3 {
      font-size: $font-18;
      font-weight: $fw-600;
      color: $text-col2;
    }
  }
  .detail-card-label {
    color: #777777;
    font-size: rem-calc(13px);
    line-height: rem-calc(16px);
    padding-bottom: rem-calc(5px);
  }

  .detail-row {
    margin-bottom: rem-calc(12px);
  }
  .detail-card-text {
    font-weight: bold;
  }
}

.notification-div {
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
}

.notification-text {
  color: #383737;
  font-size: rem-calc(14px);
  font-weight: 500;
  letter-spacing: rem-calc(0.19px);
  line-height: rem-calc(22px);
}

.unread {
  background-color: #def7f5;
}

@import "header.scss";
@import "navigation.scss";
@import "physician-dashboard";
@import "patient-details";
