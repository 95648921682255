.pdf.p-20 {
  // padding: 50px 20px !important;
}
.pdf .title {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.pdf .title-sub {
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.pdf .titlesub-left {
  font-size: 12px;
  line-height: 22px;
  text-decoration: underline;
  font-weight: bold;
  color: #000;
}
.pdf p {
  font-size: 12px;
  line-height: 22px;
  color: #000;
}
.pdf ul,
.pdf ol {
  margin-left: 20px;
}
.pdf li {
  list-style-type: disc;
  font-size: 12px;
  line-height: 22px;
  color: #000;
}

.pdf ol li {
  list-style-type: upper-latin;
}
.pdf ol li ol li {
  list-style-type: decimal;
}
.pdf ol li ol li ol li {
  list-style-type: lower-latin;
}

.pdf strong i {
  font-style: italic;
}
.flex-wrap-mobile .input-wrap {
  margin-bottom: 5px;
}
.options-wrap.flex-wrap-mobile .input-wrap {
  width: 50%;
  min-width: 50%;
}
.options-wrap.flex-wrap-mobile {
  flex-wrap: wrap;
}
.pdf,
.input-wrap {
  pointer-events: none;
}

@media (max-width: 600px) {
  .flex-wrap-mobile {
    flex-wrap: wrap;
  }
  .flex-wrap-mobile .input-wrap {
    width: 100%;
    margin-left: 0 !important;
  }
}
