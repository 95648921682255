.ques-bottom-div {
  position: absolute;
  bottom: 0px;
  right: $section-padding-right;
  left: $section-padding-left;
}

.copyright-div {
  font-size: rem-calc(10px);
  margin: rem-calc(20px) 0px rem-calc(30px);
}

.question-text {
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.21px;
  line-height: 20px;
}

.question-sub-text {
  color: #707575;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

// .question-resp-div {
//   margin-top: rem-calc(20px);
//   margin-bottom: rem-calc(30px);
// }

.question-progress-bar-outer {
  // border-radius: rem-calc(2.5px);
  background-color: #d8d8d8;
  height: 5px;
}

.question-progress-bar-inner {
  background-color: #f6656d;
  height: 5px;
  // border-radius: rem-calc(2.5px);
}

.question-video-div video {
  outline: none;
}

.followup-question-text {
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.21px;
  line-height: 20px;
  margin-bottom: rem-calc(30px);
}

.fwp-ques-bottom-div .in-dev-div {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.oval {
  height: 20px;
  width: 20px;
  border: 2px solid #adaeae;
  border-radius: 50%;
  display: inline-block;
}

.oval-active {
  height: 20px;
  width: 20px;
  border: 2px solid #f6656d;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.oval-active-inner {
  height: 10px;
  width: 10px;
  background-color: #f6656d;
  border-radius: 50%;
  display: inline-block;
  top: 20%;
  left: 20%;
  position: absolute;
}

.oval-title {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  color: $theme-color-green;
}

.assessment-bold {
  color: $theme-color-green;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.assessment-semi {
  color: $theme-color-green;
  font-size: 18px;
  line-height: 24px;
}
.assessment-bold:after {
  box-sizing: border-box;
  height: 2px;
  // width: 75px;
  border: 2px solid $theme-color-green;
  content: "";
  display: block;
  margin-top: 5px;
}
.resume {
  color: #f6656d;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  &:hover {
    color: #f6656d;
  }
}
.assessment-click {
  text-align: center;
}
.assessment-button {
  width: 80%;
  margin: 0 auto;
}

// ---------------------------------------ASQ
.asqAreaHeader {
  color: #666666;
  font-family: "transcendregular";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 15px;
}

.followup-border {
  border-top: 1px solid #f4f4f4;
}

.screening-daycare {
  margin: -1.6rem auto;
  width: 90%;
  .page-container {
    background-color: transparent;
    .question-section {
      padding: 20px 0;
      .asqAreaHeader {
        color: #666666;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 20px;
        text-transform: uppercase;
      }
      .btn-assessment {
        width: 70%;
      }
    }
  }
}
.modal-result-container {
  top: 15%;
  &.modal-dialog {
    max-width: 600px;
    .click-dashboard-container {
      text-align: center;
      .detail-card-text {
        text-align: left;
        line-height: 22px;
        button {
          font-size: 16px;
        }
      }
      .image-content {
        img {
          height: 250px;
        }
      }
      p {
        color: #707575;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
  }
}

//--------------------------------------------------------------------------------------------------------------------------
//-----------------------------------------     ASQ Revamp     -------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------

.overall-clarification {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.asq-area-info {
  font-size: 24px;
  color: #9ca2a2;
}

.btn-new-screening {
  box-shadow: inset -3px -3px 3px 0 rgba(37, 133, 143, 0.8),
    inset 3px 3px 3px 0 #95d9e0 !important;
}

.cancel-screening {
  color: #707575;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

.pdf-arrow {
  vertical-align: sub;
  margin-left: 5px;
}

.do-next-text {
  color: #707575;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.do-next-action {
  margin-bottom: 20px;
  .p {
    color: #707575;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.swyc-scenario-update {
  background-color: #ebf9f3;
  padding: 12px;
  color: #757070;
  margin-bottom: 20px;
}

.begin-btn {
  box-shadow: inset -3px -3px 3px 0 rgba(209, 76, 83, 0.8),
    inset 3px 3px 3px 0 #ffaeb2 !important;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.journaling-btn {
  box-shadow: inset -3px -3px 3px 0 rgba(37, 133, 143, 0.8),
    inset 3px 3px 3px 0 #95d9e0 !important;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.next-screening {
  color: #9ca2a2;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
.next-screening-date {
  color: #6dbac2;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}
.info-screening {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

.breakpoint-div {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
  border-top: 1px solid rgba(156, 162, 162, 0.5);
  p {
    position: absolute;
    padding-left: 21.5px;
    padding-right: 21.5px;
    background-color: white;
    color: #707575;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

.breakpoint-report {
  p {
    background-color: #e2f1f3;
  }
}

.breakpoint-report-mchat {
  p {
    background-color: #cffae5;
  }
}

.white-btn-div {
  margin-left: 20px;
  margin-right: 20px;
  .white-btn {
    min-height: 40px;
    background-color: #f0f0f0 !important;
    box-shadow: inset 3px 3px 3px 0 #ffffff,
      inset -3px -3px 3px 0 rgba(0, 0, 0, 0.25) !important;
    color: #25858f;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.low-score-btn {
  box-shadow: 0 2px 12px 0 rgba(246, 101, 109, 0.5) !important;
  border-radius: 25px;
}

.white-btn-secondary {
  height: 40px;
  background-color: #f0f0f0 !important;
  box-shadow: inset 3px 3px 3px 0 #ffffff,
    inset -3px -3px 3px 0 rgba(0, 0, 0, 0.25) !important;
  color: #f6656d !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.failed-action-div {
  padding-left: 15px;
  padding-right: 15px;
}

.next-screening-div {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 61px;
  padding-right: 61px;
  height: 64px;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  margin-bottom: 20px;
  text-align: center;
}

.mchat-action-new {
  color: #707575;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.pcp-details {
  .name {
    color: #f6656d;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    word-break: break-word;
  }
  .name-i {
    color: #f6656d;
    font-size: 14px;
    margin-right: 6px;
  }
  .email {
    color: #25858f;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 27px;
    text-decoration: underline;
    word-break: break-all;
  }
  .email-i {
    color: #9ca2a2;
    font-size: 14px;
    margin-right: 6px;
  }
}
