%row {
  display: flex;
  align-items: center;
}
%row-start {
  display: flex;
  align-items: flex-start;
}
%row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
%row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
%primary-font {
  font-family: "Open Sans", sans-serif;
}
%truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
%flex-col-start {
  display: flex;
  align-items: start;
  flex-direction: column;
}
%flex-col-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}
%custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #ededed;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    background-color: #c7c7c7;
    border-radius: 100px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    // background-color: #555;
  }
  /* Corner */
  &::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }
}
