// // video-preview modal::Physician

.video-preview-modal {
  background-color: #5a5a5a61;
  .modal__overlay {
    width: calc(100vw - 628px);
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
  }
  .modal-container {
    width: 628px;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    background: #ffffff;
    margin: 0;
    border-radius: 0;
  }
  .modal__header {
    height: 60px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-text {
      font-family: Transcend;
      font-style: normal;
      font-weight: normal;
      font-size: rem-calc(18px);
      line-height: rem-calc(22px);
      letter-spacing: rem-calc(0.24px);
      color: #333333;
      margin-bottom: 0;
    }
    & img {
      cursor: pointer;
    }
  }
  .modal__body {
    height: calc(100vh - 165px);
    overflow-y: auto;
    overflow-x: hidden;
    &__onboard {
      height: calc(100vh - 110px);
    }

    & .video-milestone-text {
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }
  }
  .modal__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.5rem 2.5rem;
    background-color: #ffffff;
    border-top: 1px solid #f2f2f2;
    z-index: 1;
    .back-button {
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 29px;
      color: #707575;
    }
  }

  .history {
    margin-bottom: 60px;
    .history-uncheck-heading {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #424040;
      margin-bottom: 10px;
    }
    .history-uncheck-reason {
      font-size: 16px;
      line-height: 22px;
      color: #222222;
    }
  }

  .history-details {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .history-details-uncheck {
      & h5 {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #424040;
        margin-bottom: 10px;
      }
      & p {
        margin-bottom: 5px;
      }
    }
  }
}

.faq-video-preview {
  .modal-popup-container1 {
    position: absolute;
    top: 40%;
    left: 50%;
    // max-height: 80vh !important;
    overflow: hidden;
    transform: translate(-50%, -40%);
    width: calc(100% - 20px);
    max-width: 350px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
    // height: 197px;
  }
  .modal__header {
    position: absolute;
    top: 20px;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    .video-milestone-text {
      color: $theme-color-green;
      width: 32px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .header-text {
      font-style: normal;
      font-weight: 600;
      font-size: rem-calc(18px);
      line-height: rem-calc(22px);
      letter-spacing: rem-calc(0.24px);
      color: #333333;
      margin-bottom: 0;
    }
  }
}

.modal-popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.modal-popup-pin {
  z-index: 10000 !important;
}

.modal-popup .modal-popup-container {
  position: absolute;
  top: 40%;
  left: 50%;
  max-height: 80vh;
  overflow: auto;
  transform: translate(-50%, -40%);
  width: calc(100% - 20px);
  max-width: 350px;
  padding: 25px 30px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
}

.modal-popup-container-2 {
  position: absolute;
  top: 43%;
  left: 50%;
  max-height: 80vh;
  transform: translate(-50%, -40%);
  width: calc(100% - 20px);
  max-width: 350px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.5);
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
    z-index: 10;
  }
}

.cancel-button {
  color: #f6656d;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  &:focus {
    outline: none;
  }
}
.cancel-button.primary {
  color: $theme-color-green;
}

.modal_header {
  img {
    vertical-align: sub;
    cursor: pointer;
  }
  .modal_title {
    font-family: "transcendregular";
    font-size: 20px;
    color: $theme-color-green;
  }
}

.asq-report-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #328c96;
}

.asq-report-subtext {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #707575;
}

.questions-div {
  thead {
    tr,
    th {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 146.5%;
      letter-spacing: 0.64px;
      color: #575757;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  tbody {
    tr,
    td {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 146.5%;
      letter-spacing: 0.64px;
      color: #333333;
      padding: 10px;
    }
  }
}

.modal_header2 {
  font-family: "transcendregular" !important;
  font-size: 22px !important;
  letter-spacing: 0 !important;
  line-height: 27px !important;
  color: $theme-color-green !important;
  font-weight: normal !important;
}

.modal_body_text2 {
  color: #707575 !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  font-weight: normal !important;
}
