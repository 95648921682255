// --------------------------------->> My Children <<----------------------------------------
.mychildren-head i {
  font-size: 27px;
  color: $theme-color-green;
}
.mychildren-nav {
  padding: 0 30px;
  cursor: pointer;
}
.mychildren-nav p {
  color: $theme-color-green;
  font-size: 18px;
  line-height: 24px;
}
.mychildren-nav p.active {
  font-weight: bold;
  position: relative;
}
.mychildren-nav p.active:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: $theme-color-green;
  display: block;
  bottom: -5px;
  left: 0;
}
.mychildren-content {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
}

.custom-circle {
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #eef5f1 16.73%,
    #cae8da 100%,
    #dbf0e6 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 1px 13px 0 rgba(186, 186, 186, 0.5);
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 55px;
  height: 55px;
  line-height: 62px;
  color: #519fa7;
  font-size: 20px;
}
.custom-circle p {
  position: relative;
  text-transform: uppercase;
  color: #519fa7;
  font-size: 15.6px;
  letter-spacing: 0;
  line-height: 22px;
}

.custom-circle-2 {
  width: 69px;
  height: 69px;
  p {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.more-option i {
  font-size: 25px;
  color: #f6656d;
}
.more-option {
  position: absolute;
  top: 15px;
  right: 15px;
}
.child-card-divider {
  box-sizing: border-box;
  height: 1px;
  width: 90%;
  margin: auto;
  background: #a2f5ce;
}
.dob {
  color: #707575;
  font-size: 16px;
  line-height: 1px;
  margin-top: -14px;
}
.mychildren-button {
  width: 85%;
  margin: 0 auto;
}
.mychildren-button .btn {
  font-size: 16px;
  line-height: 25px;
}
.more-items {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(173, 172, 172, 0.5);
  position: absolute;
  right: -10px;
  bottom: -40px;
  border-radius: 5px;
}
.more-items::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: -14px;
  right: 10px;
  border: 7px solid white;
  border-color: transparent;
  border-bottom-color: white;
}
.more-items::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: -16px;
  right: 9px;
  border: 8px solid white;
  border-color: transparent;
  border-bottom-color: rgba(173, 172, 172, 0.2);
}
.more-items p {
  color: #707575;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 10px;
  white-space: nowrap;
}

.child-head {
  background-color: $theme-color-green;
  padding: 27px 30px;
}
.child-head h2 {
  color: #ffffff;
  font-family: "transcendregular";
  font-size: 26px;
  line-height: 31px;
}
.child-head i {
  font-size: 22px;
  padding-top: 6px;
  color: #fff;
}
.child-img {
  text-align: center;
}
.child-img img {
  max-width: 100%;
}
.child-text {
  color: #707575;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}
@media screen and (max-width: 360px) {
  .child-head {
    width: 375px;
  }
}

.todo-item-div {
  &:not(:last-child) {
    border-bottom: 1px solid #f4f2f2;
  }
  .todo-icon {
    font-size: 24px;
    padding-top: 4px;
    min-width: 28px;
  }
  .arrow-div {
    height: 27px;
    width: 27px;
    border-radius: 50%;
    background-color: #f6656d;
    // box-shadow: inset 0 -1px 3px 0 rgba(116, 71, 71, 0.5),
    //   inset 1px 3px 3px 0 #ffc4c7;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #ffffff;
      font-size: 20px;
    }
  }
  .head {
    color: #707575;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
  .sub-head {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
}
.empty-head {
  color: #707575;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

// --------------------------------->> Add Child <<----------------------------------------

.gender-select input {
  display: none;
}
.gender-select label {
  width: 143px;
  height: 70px;
  padding: 7px;
  background: #ffffff;
  border: 2px solid rgba(246, 101, 109, 0.5);
}
.gender-select img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.gender-select .female {
  border-radius: 35px 0 0 35px;
  margin-bottom: 0;
}
.gender-select .male {
  border-radius: 0 35px 35px 0;
  margin-bottom: 0;
}
.gender-select label .selected {
  display: none;
}
.gender-select label .unselected {
  display: block;
}
.gender-select .gender-text {
  text-align: center;
  color: rgba(246, 101, 109, 0.5);
}

.gender-select input:checked + label {
  background: #f6656d;
  box-shadow: inset -5px -3px 3px 0 rgba(181, 37, 37, 0.5),
    inset 2px 4px 3px 0 #eeadad;
}
.gender-select input:checked + label .selected {
  display: block;
}

.gender-select input:checked + label .unselected {
  display: none;
}
.gender-select input:checked + label .gender-text {
  color: white;
}

.pounds-num {
  .num-input-btn {
    width: 51px !important;
    height: 53px !important;
    cursor: pointer;
    border: none;
  }
  .prev {
    background: url(../images/img/MinusStepper.svg) no-repeat;
  }
  .next {
    background: url(../images/img/PlusStepper.svg) no-repeat;
  }
  p {
    box-sizing: border-box;
    height: 45px;
    width: 45px;
    border: 1px solid #bfbebe;
    background-color: #ffffff;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    color: #000000;
    position: relative;
    top: 4px;
    margin: 0 -3px;
  }
}

.pounds .custom-select {
  position: relative;
  top: -2px;
}

.radio-group .custom-radio {
  width: calc(100% / 3);
}

.child-image-upload-div {
  .image-selector {
    min-height: 91px;
    min-width: 91px;
    height: 91px;
    width: 91px;
    box-sizing: border-box;
    border: 1px dashed #f6656d;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    i {
      color: #9ca2a2;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  .image-selector-info {
    color: #707575;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}
.preview-img {
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.preview-div {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

// --------------------------------->> Child Details <<----------------------------------------
.circles-details {
  height: 220px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.circle1 {
  height: 119px;
  width: 119px;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #eef5f1 16.73%,
    #cae8da 54.07%,
    #dbf0e6 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 1px 13px 0 rgba(186, 186, 186, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle1 p {
  position: relative;
  // top: 32px;
  // left: 40px;
  font-size: 35px;
  color: #519fa7;
}
.circle2 {
  height: 91px;
  width: 91px;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #eef5f1 16.73%,
    #cae8da 54.07%,
    #dbf0e6 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 1px 13px 0 rgba(186, 186, 186, 0.5);
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -50px;
  text-align: center;
  .sub {
    font-size: 13px !important;
  }
}
.circle-title {
  position: relative;
  top: 30px;
  // left: 20px;
  font-size: 19px;
  color: $theme-color-green;
  font-family: "transcendregular";
}
.circle3 {
  height: 91px;
  width: 91px;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #eef5f1 16.73%,
    #cae8da 54.07%,
    #dbf0e6 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 1px 13px 0 rgba(186, 186, 186, 0.5);
  border-radius: 50%;
  margin-left: auto;
  margin-top: -30px;
  margin-right: 25px;
}
.circle-title1 {
  position: relative;
  top: 35px;
  left: 30px;
  font-size: 16px;
  color: $theme-color-green;
  font-family: "transcendregular";
}

.details-arrow {
  position: absolute;
  top: 35%;
}
.details-arrow.left {
  left: 0;
}
.details-arrow.right {
  right: 0;
  transform: scaleX(-1);
}

.child-info {
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  text-transform: uppercase;
}

.sub-description i {
  font-size: 21px;
  color: #6dbac2;
}
.sub1-description i {
  font-size: 21px;
  color: #6dbac2;
}
.round-circle {
  font-size: 27px;
  margin-left: auto;
  color: #f6656d !important;
}
.sub-description {
  padding: 15px 0px;
  border-bottom: 1px solid white;
}
.sub1-description {
  padding: 15px 0px;
  border-bottom: 1px solid white;
}
.sub1-description img {
  width: 21px;
}
.description-wrapper {
  margin: 0 -20px;
  padding: 0 20px 30px 20px;
  position: relative;
  background: #d0fae6;
}
.description-wrapper:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.description-wrapper .bg-curve {
  width: 100%;
  position: absolute;
  left: 0;
  top: -78px;
  z-index: 1;
  height: 135%;
  &.screening-daycare {
    height: 100%;
  }
}
.description-wrapper .description-content {
  position: relative;
  z-index: 2;
  .collapsable-content {
    background: #f5fdf9;
    padding: 20px 0;
    transition: 0.3s ease all;
    .para-head {
      color: #707575;
      font-weight: 400;
      font-size: 15px;
    }
    .setting-checkbox {
      position: absolute;
      right: 0;
      margin-right: 10px;
    }
    .collapse-icon {
      .fas {
        transition: 0.3s ease all;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .description-wrapper .description-content {
    padding-top: 20px;
  }
}

.action-text-popup {
  color: #f6656d !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
