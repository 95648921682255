.user-select input {
  display: none;
}
.user-select label {
  width: 138px;
  height: 78px;
  padding: 7px;
  background: #ffffff;
  border: 2px solid rgba(246, 101, 109, 0.5);
  @media screen and (max-width: 425px) {
    height: 70px;
    width: 110px;
  }
  @media screen and (max-width: 320px) {
    height: 58px;
    width: 95px;
  }
}
.user-select .parent {
  border-radius: 35px 0 0 35px;
  margin-bottom: 0;
}
.user-select .physician {
  border-radius: 0 35px 35px 0;
  margin-bottom: 0;
}
.user-select label .selected {
  display: none;
}
.user-select label .unselected {
  display: block;
}
.user-select .img-container {
  height: 35px;
  @media screen and (max-width: 320px) {
    height: 20px;
  }
}
.user-select .user-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(246, 101, 109, 0.5);
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
}

.user-select input:checked + label {
  background: #f6656d;
  box-shadow: inset -5px -3px 3px 0 rgba(181, 37, 37, 0.5),
    inset 2px 4px 3px 0 #eeadad;
}
.user-select input:checked + label .selected {
  display: block;
}

.user-select input:checked + label .unselected {
  display: none;
}
.user-select input:checked + label .user-text {
  color: white;
}
.checkbox-text {
  text-decoration-line: underline;
  color: #f35151;
  font-weight: bold;
}
.allow-screening-info {
  position: relative;
  top: 3px;
  padding-left: 5px;
}

.arrow-position{
  position: absolute;
  margin-left: 95%;
  margin-top: 2%;
}