@import "../base/placeholder.scss";

.dashboard {
  height: 100vh;
  @extend %row-start;
  background-color: #f8f8f8;
  // overflow: hidden;
  &__main {
    width: calc(100% - 56px);
    display: flex;
    flex-direction: column;
  }
  &__header {
    @extend %row-between;
    padding: 16px 24px;
    height: 56px;
    background-color: #fff;
    border-bottom: 1px solid #dedede;
    &__title {
      @media only screen and (max-width: 1200px) {
        max-width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .avatar-container {
      @extend %row-center;
      width: 34px;
      height: 34px;
      background-color: #be82c2;
      border-radius: 100%;
    }
  }
  &__nav-header {
    @extend %row-between;
    flex-shrink: 0;
    padding: 20px 24px;
    @media only screen and (max-width: 1200px) {
      white-space: nowrap;
      overflow-x: overlay;
      margin-bottom: 10px;
      padding-top: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: #d3cfcf;
      height: 4px;
    }
    &::-webkit-scrollbar {
      background-color: #f5f5f5;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #afacac;
      height: 4px;
    }
  }
  &__search-bar {
    width: 320px;
    .input-field--search {
      width: 100%;
      border: 1px solid #dedede;
      border-radius: 4px;
    }
    @media only screen and (max-width: 1200px) {
      margin-left: 16px;
    }
    @media only screen and (max-width: 900px) {
      max-width: 220px;
    }
  }
}

.nav-title {
  position: relative;
  color: var(--secondary-text);
  // font-size: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 16px;
  cursor: pointer;
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 0;
    background-color: var(--primary-brand);
    transform: scaleX(0);
    transition: all 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform-origin: center;
  }
  &:not(:last-child)::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    right: -16px;
    background-color: #dcdcdc;
  }
  &:not(:first-child) {
    margin-left: 16px;
  }
  &:hover {
    color: var(--primary-brand);
  }
  &:hover .info-icon path {
    fill: var(--primary-text);
    transition: all 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &.active {
    color: var(--primary-brand);
    font-weight: bold;
    .info-icon path {
      fill: var(--primary-text);
    }
  }
  &:hover::after,
  &.active::after {
    transform: scaleX(1);
  }
  .info-icon {
    position: absolute;
    right: -26px;
    top: 5px;
  }
  &--default {
    color: var(--primary-text);
    font-size: 16px;
    font-weight: 400;
    margin-left: 0 !important;
    margin-right: 0 !important;
    &:not(:last-child) {
      margin-right: 40px !important;
    }
    &::before {
      content: none !important;
    }
    &::after {
      background-color: var(--primary-text);
    }
    &:hover,
    &.active {
      color: var(--primary-text);
    }
    &.error {
      &::before {
        content: "" !important;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 6px;
        height: 6px;
        background-color: var(--text-red);
        border-radius: 100%;
      }
    }
  }
}
