.head-row-phy {
  background-color: $theme-color-green;
  padding: rem-calc(20px);
}

.header-text-phy {
  color: #ffffff;
  font-size: rem-calc(18px);
  font-weight: 500;
  letter-spacing: rem-calc(0.24px);
  line-height: rem-calc(28px);
  font-family: "transcendregular";
}

.header-container-phy {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  // z-index: 1;
  z-index: 1000;
}

.header-icons {
  font-size: 22px;
  color: white;
  cursor: pointer;
}

.notification-unread {
  position: absolute;
  top: 0;
  right: -3px;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  background: #ee646d;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
}

.notification-unread.phy {
  width: 16px;
  height: 16px;
  right: -6px;
  font-size: 11px;
  font-weight: bold;
}

.mobile-menu {
  @include screen(custom, min, 800) {
    display: none;
  }
}
.desktop-logo-icon {
  height: 45px;
  border-radius: 50%;
  width: 45px;
}
.desktop-logo-icon {
  @include screen(custom, max, 799) {
    display: none;
  }
}
.desktop-icon {
  @include screen(custom, max, 800) {
    display: none;
  }
}

.pf-text {
  font-weight: 500;
  font-size: rem-calc(25px);
  line-height: rem-calc(28px);
  color: #ffffff;
  background: #033e39;
  padding: rem-calc(20px 15px);
  position: fixed;
  top: 0;
  left: 0;
}

.header-container {
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  @include screen(custom, max, 800) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
// .head-row {
//   background-color: $theme-color-1;
//   padding: rem-calc(20px);
// }

.header-text {
  color: #ffffff;
  font-size: rem-calc(18px);
  font-weight: 500;
  letter-spacing: rem-calc(0.24px);
  line-height: rem-calc(22px);
  text-align: center;
}

.brand-header-name {
  font-family: "transcendregular";
}
.brand-header-name-sub {
  font-family: "transcendregular";
  font-size: 16px;
}
