.switch {
  &-container {
    width: 44px;
    height: 22px;
  }
  &-label {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #cdcdcd;
    border-radius: 12px;
    transition: all 300ms ease-in;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2),
        0px 2px 2px rgba(0, 0, 0, 0.05);
      transition: left 0.25s ease;
      border-radius: 100%;
    }
  }
  &-input {
    display: none;
    &:checked + .switch-label {
      background-color: var(--primary-brand);
      &::after {
        left: 52.3%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
