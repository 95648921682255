.bread-crumb {
  padding: 0;
}

.bread-crumb-list {
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #666666;
  margin-right: 10px;
  // font-family: "Lato-Regular";
  cursor: pointer;
  &:not(:last-child) {
    position: relative;
    margin-right: 25px;
    &::after {
      content: "";
      width: calc(100% + 25px);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/bread-crumb-arrow.svg) right 10px center
        no-repeat;
    }
  }
}

.patient-details {
  .link {
    font-weight: bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    letter-spacing: 0.186667px;
    color: #f35151;
  }
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }

  .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(233, 135, 96, 0.25);
    border: #adb5bd solid 1px;
  }
}

.patient-information {
  padding: rem-calc(30px) rem-calc(15px);
  font-size: rem-calc(15px);
  line-height: rem-calc(18px);
  letter-spacing: 0.2px;
  color: #5b5a5a;
  align-items: flex-start;
  @include screen(custom, max, 600) {
    flex-wrap: wrap;
  }
  &__left,
  &__right {
    width: 50%;
    @include screen(custom, max, 600) {
      width: 100%;
      margin-top: 15px;
      flex-wrap: wrap;
    }
  }
  .patient-avatar {
    min-width: rem-calc(96px);
    min-height: rem-calc(96px);
    border-radius: 50%;
    background: #ffdbdb;
    border: 1.17px solid #f35151;
    font-size: rem-calc(25px);
    line-height: rem-calc(96px);
    text-align: center;
    letter-spacing: 0.333333px;
    color: #e98760;
    margin-right: rem-calc(20px);
    // font-family: "Lato-Regular";
    @include screen(custom, max, 600) {
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  .patient-name {
    font-size: rem-calc(17px);
    line-height: rem-calc(20px);
    // font-family: "Lato-bold";
    font-weight: 600;
  }
}

.white-bg {
  background: #ffffff;
}

.patient-table-holder {
  @extend .white-bg;
  .table-header {
    border-bottom: 1px solid #f1f1f1;
    padding: rem-calc(10px) rem-calc(30px);
    padding-left: rem-calc(15px);
    color: #424040;
    .title {
      // font-family: "Lato-Medium";
      font-size: rem-calc(18px);
      line-height: rem-calc(22px);
      letter-spacing: 0.24px;
      svg {
        margin-right: 10px;
        display: inline-block;
        vertical-align: sub;
      }
      img {
        margin-right: 10px;
        display: inline-block;
        vertical-align: sub;
      }
    }
    .view-all {
      // font-family: "Lato-Medium";
      font-size: rem-calc(16px);
      line-height: rem-calc(19px);
      letter-spacing: 0.213333px;
    }
  }

  .table-body {
    padding: rem-calc(15px);
    max-height: 317px;
    overflow: auto;
    &.view-all {
      max-height: initial !important;
    }
  }

  .table-footer {
    border-top: 1px solid #f1f1f1;
    padding: rem-calc(10px) rem-calc(30px);
    padding-left: rem-calc(15px);
    color: #424040;
    .view-all {
      // font-family: "Lato-Medium";
      font-size: rem-calc(16px);
      line-height: rem-calc(19px);
      letter-spacing: 0.213333px;
    }
  }
}

.table-2 {
  width: 100%;
  min-width: 650px;
  th {
    padding: 6px 10px;
  }
  thead {
    tr {
      background: #f5f5f5;
      border-radius: 3px;
      border: none;
    }
    th {
      // font-family: "Lato-bold";
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.186667px;
      color: #777777;
      svg {
        margin-left: 5px;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.213333px;
      color: #525252;
      padding: 13px 10px;
    }
    a {
      text-decoration: none;
      color: #525252;
      &:hover {
        text-decoration: none;
      }
    }
  }
  th,
  td {
    &:first-child {
      width: 40px;
    }
    &:nth-child(2) {
      width: 170px;
    }
    &:nth-child(3) {
      width: 90px;
    }
    &:nth-child(4) {
      width: 120px;
    }
    &:nth-child(5) {
      width: 50px;
      text-align: center;
    }
    &:nth-child(6) {
      width: 50px;
      text-align: center;
    }
    &.value-legend-full {
      &::after {
        width: 50px;
      }
    }
  }
}

.table-3 {
  width: 100%;
  min-width: 650px;
  th {
    padding: 6px 10px;
  }
  thead {
    tr {
      border: none;
    }
    th {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #5d5d5d;
      svg {
        margin-left: 5px;
      }
    }
    .area {
      font-weight: bold !important;
      text-align: left !important;
    }
  }
  tbody {
    td {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.213333px;
      color: #222222;
      padding-bottom: 12px;
    }
  }
  th,
  td {
    &:first-child {
      text-align: center;
      width: 50px;
      vertical-align: top;
      img {
        margin-top: 5px;
      }
    }
    &:nth-child(2) {
      width: 280px;
    }
    &:nth-child(3) {
      text-align: center;
      width: 100px;
    }
    &:nth-child(4) {
      width: 120px;
      text-align: center;
    }
    &:nth-child(5) {
      width: 100px;
      text-align: center;
    }
    &:nth-child(6) {
      cursor: pointer;
      text-align: center;
    }
  }
}

.export {
  padding: 0 7px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.186667px;
  color: $theme-color-green;
  background: #b9f8ff;
  border: 1px solid #6dbac2;
  border-radius: 2px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.patient-table-nav-2 {
  padding: rem-calc(3px) 0;
  .nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.patientDetails {
  // font-family: "Lato-Medium";
  font-size: rem-calc(16px);
  line-height: rem-calc(20px);
  letter-spacing: 0.213333px;
  color: #222222;
  margin-top: 0 !important;
  &:not(:last-child) {
    width: 85%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .custom-control-label {
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
}

.orange-text {
  color: #f35151;
}

.video-table {
  .patientDetails {
    max-width: 100%;
    span {
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

.feedback-container {
  background: #f8f8f8;
  padding: rem-calc(10px);
  .title {
    // font-family: "Lato-Medium";
    font-size: rem-calc(14px);
    line-height: rem-calc(17px);
    letter-spacing: 0.186667px;
    text-transform: uppercase;
    color: #7c7c7c;
  }
  .desc {
    font-weight: 500;
    font-size: rem-calc(14px);
    line-height: rem-calc(24px);
    letter-spacing: 0.186667px;
    color: #353434;
    max-width: 320px;
  }
}

.feedback-section {
  padding: rem-calc(15px);
}

.previous-feedback-div {
  max-height: 317px;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  &:last-of-type {
    border-bottom: 1px solid #d6d6d6;
  }
}
.previous-feedback {
  padding-bottom: 10px;
  // border-bottom: 1px solid #d6d6d6;
}

.post-feedback {
  .section-desc {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    letter-spacing: 0.186667px;
    color: #706d6d;
  }
}
.patient-table-nav-3 {
  width: 100%;
  border: 0.5px solid #eeeeee;
  .nav-item {
    padding: 0 rem-calc(7px);
    font-size: 12px;
    line-height: 14px;
  }
}

.feedback-textarea {
  width: 100%;
  background: #f3f3f3;
  resize: none;
  border: none;
  outline: none;
}

.btn-post {
  width: rem-calc(88px);
  height: rem-calc(45px);
  font-size: rem-calc(16px);
  line-height: rem-calc(19px);
  justify-content: center;
  letter-spacing: 0.213333px;
  color: #ffffff;
  background: #e98760;
  border: 1px solid #e98760;
  box-shadow: 0px 4px 4px rgba(233, 135, 96, 0.53);
  border-radius: 3px;
}

.milestone-circle {
  min-height: rem-calc(19px);
  max-height: rem-calc(19px);
  min-width: rem-calc(19px);
  border: 1px solid #b7b4b4;
  border-radius: 50%;
}

.journal-header-tab-div {
  cursor: pointer;
  .nav-item {
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    letter-spacing: 0.186667px;
    color: #5d5d5d;
    display: inline-block;
    padding: rem-calc(0px) rem-calc(10px);
    &:first-child {
      border-right: 1px solid #e9e9e9;
    }
    &.active {
      color: #033e39;
      font-weight: bold;
    }
  }
}

.milestone-pointer {
  background: #d55e00;
  border-radius: 2px;
  width: 8px;
  height: 8px;
  align-self: center;
  margin-left: 4px;
}

.delayed-img {
  vertical-align: middle;
}
