.breadcrumb {
  &-wrapper {
    @extend %row-center;
  }
  &__list {
    position: relative;
    @extend %row-center;
    color: var(--primary-brand);
    font-weight: 600;
    padding-right: 11px;
    margin-right: 6px;
    &:last-child {
      color: var(--primary-text);
      padding-right: 0;
    }
    &:not(:last-child) {
      text-decoration: underline;
      cursor: pointer;
    }
    &:not(:last-child):after {
      content: "/";
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
