.otp-text {
  color: #575758;
  font-size: 16px;
  line-height: 22px;
}
.otp-text.verification-action {
  color: #707575;
  font-weight: 600;
  letter-spacing: 0;
}
.verification-cancel {
  color: #707575;
}
.otp-input input {
  border: none;
  border-bottom: 2px solid #f6656d;
  width: 40px;
  background: transparent;
  text-align: center;
}
.proceed {
  width: 80%;
}
.otp-text span {
  color: #f6656d;
}
