@import "./variables.scss";
.dashboard-header-parent {
  background: none !important;
  box-shadow: none !important;
}
.dashboard-header {
  width: 100%;
  height: 32px;
  background: #c26d9c;
  margin-top: 15px;
}

.dashboard-header-div {
  display: flex;
  overflow: auto;
  position: absolute;
  width: 100%;
  overflow: auto;
  left: 0;
  top: 74%;
  transform: translateY(-50%);
  align-items: center;
  padding: 0 20px;
}

.dashboard-header .circle {
  border-radius: 50%;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #eef5f1 16.73%,
    #cae8da 100%,
    #dbf0e6 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 1px 13px 0 rgba(186, 186, 186, 0.5);
  margin-bottom: 5px;
}

.dashboard-header .circle-main-container {
  // position: relative;
  text-align: center;
  width: 87px;
  // top: -89%;
}

.dashboard-header .circle-main-container-sec {
  text-align: center;
  width: 87px;
}

.dashboard-header .circle-main-container .circle {
  width: 87px;
  height: 87px;
  line-height: 87px;
  color: #519fa7;
  font-size: 24px;
}

.dashboard-header .circle-main-container-sec .circle {
  width: 87px;
  height: 87px;
  line-height: 87px;
  color: #519fa7;
  font-size: 23px;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #ebf3f3 16.73%,
    #cae5e8 54.07%,
    #dcf0f2 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  // border: 3.2px solid #FFFFFF;
  // box-shadow: none;
}

.dashboard-header .circle-main-container p {
  font-size: 11px;
  color: $theme-color-green;
}

.dashboard-header .circle-main-container-sec p {
  font-size: 11px;
  color: $theme-color-green;
}

.dashboard-header .circle-sub-container {
  // position: relative;
  text-align: center;
  width: 53px;
  // top: -50%;
}

.dashboard-header .circle-sub-container-sec {
  text-align: center;
  width: 62px;
}

.dashboard-header .circle-sub-container .circle {
  width: 53px;
  height: 53px;
  line-height: 53px;
  color: #519fa7;
  font-size: 20px;
}

.dashboard-header .circle-sub-container-sec .circle {
  width: 62px;
  height: 62px;
  line-height: 55px;
  color: #519fa7;
  font-size: 16px;
  background: radial-gradient(
    circle,
    #ffffff 0%,
    #ebf3f3 16.73%,
    #cae5e8 54.07%,
    #dcf0f2 83.64%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 3.2px solid #ffffff;
  box-shadow: none;
}

.dashboard-header .circle-sub-container p {
  font-size: 11px;
  color: $theme-color-green;
}
.dashboard-header .circle-sub-container-sec p {
  font-size: 11px;
  color: $theme-color-green;
}

.dashboard-header .circle-sub-container.add-button .circle {
  background: url(../images/img/add-button-circle.svg) top center/cover
    no-repeat;
}

.child-card {
  border: 2px solid #d0fae6;
  box-shadow: none;
  .child-name {
    color: $theme-color-green;
    font-family: "transcendregular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .child-dob {
    color: #6b6f70;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
  .action-item-outer {
    height: 25px;
    width: 72px;
    box-sizing: border-box;
    border: 1px solid #cff2e1;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .details-button {
    width: 89px !important;
    background-color: #cff2e1 !important;
    border-color: transparent !important;
  }
  .action-item {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
    .action-item.details {
    border-color: transparent;
    background-color: #ecfdf5;
  }
}

.graph-holder .card-header img {
  width: 18px;
  margin-right: 5px;
}

.graph-holder .card-header p {
  font-size: 18px;
  color: $theme-color-green;
}

.graph-holder .card .medical-icon {
  font-size: 21px;
  color: #f6656d;
}

.card-entries {
  padding: 10px 30px;
}

.card-entries .entries-title p {
  font-size: 18px;
  color: $theme-color-green;
}

.card-entries .entries-title i {
  font-size: 21px;
  color: $theme-color-green;
}

.card-entries .per-week .text {
  font-size: 18px;
  color: $theme-color-green;
}

.card-entries .per-week .count {
  font-size: 36px;
  color: $theme-color-green;
}

.menu-card-holder .card {
  width: calc(100% / 2 - 15px);
  padding: 10px 15px;
  text-align: center;
  i {
    font-size: 48px !important;
  }
}

.menu-card-holder .card-menu2 {
  background-color: #519fa7;
  box-shadow: inset -5px -6px 3px 0 #316d73, inset 4px 6px 3px 0 #6fb3ba;
}

.menu-card-holder .card .title {
  font-size: 18px;
  color: $theme-color-green;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.menu-card-holder .card .journal {
  font-size: 54px;
  color: $theme-color-green;
}

.menu-card-holder .card .sub-text {
  font-size: 14px;
  color: $theme-color-green;
  text-align: center;
  margin-top: 10px;
}

.card.notification-settings i {
  font-size: 20px;
  color: $theme-color-green;
}

.card.notification-settings p {
  color: $theme-color-green;
  font-size: 18px;
  font-weight: 600;
}

/* changes */
.foot-text {
  color: $theme-color-green;
  font-size: 14px;
  line-height: 19px;
}

.graph-sub img {
  width: 132px;
  padding-top: 10px;
}

// screening card

.disabled {
  color: #9ca2a2 !important;
}

.para-head-small {
  color: $theme-color-green;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.para-head-large {
  color: $theme-color-green;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.countdown-div {
  box-sizing: border-box;
  height: 78px;
  width: 78px;
  border: 1px solid #6dbac2;
  border-radius: 4px;
  margin: auto;
}

.countdown-day-count {
  color: $theme-color-green;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 49px;
  text-align: center;
}

.screening-text {
  color: $theme-color-green;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.screening-text-light {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
/* DayCare dashboard related css */
.dashboard-daycare {
  .dashboard-header {
    .circle-sub-container {
      &:first-child {
        margin-left: 30px;
      }
      &.active {
        width: 87px;
        .circle {
          width: 87px;
          height: 87px;
          line-height: 87px;
        }
      }
      p {
        font-size: $font-14;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .circle {
      box-shadow: none;
      border: 4px solid $gray-color1;
      background: radial-gradient(
        circle,
        #ffffff 0%,
        #ebf3f3 16.73%,
        #cae5e8 54.07%,
        #dcf0f2 83.64%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .title-wrapper {
    border-radius: 5px;
    background-color: $gray-color2;
    padding: 10px 30px;
    h3 {
      color: $dark-gray;
      font-size: 14px;
      font-weight: $fw-700;
    }
  }
  .list-content {
    padding: 20px 30px;
    ul {
      li {
        color: $primary-text-color;
        font-size: $font-16;
        font-weight: $fw-600;
        padding: 0 10px 10px;
        word-break: break-all;
        &.list-group-item {
          border: none;
          div.image-parent {
            .btn-begin {
              border: 1px solid $border-col-custom;
              border-radius: 10px;
              background-color: $bg-white;
              display: inline-block;
              padding: 4px 10px;
              text-align: center;
              border: 1px solid $col-red;
              cursor: pointer;
              margin-left: 10px;
              min-width: 82px;
              font-size: $font-12;
              transition: 0.3s ease all;
              font-weight: normal;
              &:hover {
                border-color: $theme-color-green;
                color: $theme-color-green;
                transition: 0.3s ease all;
              }
            }
            .btn-filled-in {
              color: #6dbac2;
              border: 0 !important;
              transition: none;
              min-width: 82px;
              font-weight: normal;
            }
          }
        }
        &.custom-font {
          font-size: $font-14;
          font-weight: normal;
        }
      }
    }
    table {
      &.screening-assigned,
      &.screening-admin-assigned {
        width: 100%;
        tr {
          th,
          td {
            padding: 4px 0 !important;
          }
        }
        thead {
          th {
            color: $col-red;
            font-size: 12px;
            font-weight: $fw-600;
            letter-spacing: 0;
          }
        }
        tbody {
          tr {
            td {
              &:first-child {
                color: $primary-text-color;
                font-size: $font-16;
                font-weight: $fw-600;
                padding: 0 10px 10px;
                word-break: break-all;
              }
            }
          }
        }
        ul {
          padding: 0 !important;
          vertical-align: baseline;
          li {
            padding: 0;
          }
        }
      }
      &.screening-assigned {
        tr {
          th,
          td {
            width: 50%;
          }
        }
      }
      &.screening-admin-assigned {
        tr {
          th,
          td {
            &:nth-child(2),
            &:nth-child(4) {
              width: 20%;
            }
            &:nth-child(1),
            &:nth-child(3) {
              width: 30%;
            }
          }
          .btn-completed {
            border: 1px solid $border-col-custom;
            border-radius: 10px;
            background-color: $bg-white;
            display: inline-block;
            padding: 4px 15px;
            height: 30px;
            min-width: 112px;
            text-align: center;
          }
          .btn-assign {
            border: 1px solid $border-col-custom;
            border-radius: 10px;
            background-color: $bg-white;
            display: inline-block;
            padding: 4px 25px;
            height: 30px;
            min-width: 112px;
            text-align: center;
            &.red {
              border: 1px solid $col-red;
              background-color: $bg-white;
              cursor: pointer;
              transition: 0.3s ease all;
              &:hover {
                background-color: $col-red;
                color: $bg-white;
                transition: 0.3s ease all;
              }
            }
            &.blue {
              border-color: #25858f;
              // color: #25858F;
              border: 1px solid #25858f;
              background-color: $bg-white;
              cursor: pointer;
              transition: 0.3s ease all;
              &:hover {
                background-color: #25858f;
                color: $bg-white;
                transition: 0.3s ease all;
              }
            }
          }
          .form-group {
            margin-bottom: 0;
            select {
              height: 30px;
              max-width: 160px;
              font-size: 14px;
              background-size: 14px;
              vertical-align: top;
              color: $dark-gray;
              padding: 4px 10px !important;
              line-height: 20px;
              padding-right: 30px !important;
            }
          }
        }
      }
    }
  }
  .invite-parent-section {
    h3 {
      color: $secondary-text-color;
      font-size: $font-16;
      line-height: 25px;
    }
    button.btn {
      width: 165px;
      font-size: $font-18;
      font-weight: normal;
    }
  }
  .header-title {
    background: $gray-color2;
    padding: 10px;
    border-radius: 5px;
    .title {
      color: $dark-gray;
      font-size: $font-14;
      font-weight: $fw-600;
    }
  }
  .collapsible-wrapper {
    position: relative;
    padding: 15px 15px 0;
    &:last-child {
      padding-bottom: 10px;
    }
    .collapsible-row-content {
      overflow: hidden;
      &.expand {
        .child-section {
          max-height: 300px; /* make it as big as you think it will ever go */
          transition: max-height 0.3s ease;
        }
      }
      .parent-section {
        border: 1px solid $border-light-gray;
        border-radius: 5px;
      }
      .child-section {
        background: $gray-color2;
        border-radius: 5px;
        max-height: 0;
        transition: max-height 0.3s ease;
        ul.list-content {
          li {
            &:first-child {
              color: $text-col3;
            }
            padding-bottom: 10px;
            color: $primary-text-color;
            font-size: $font-14;
            font-weight: $fw-600;
            span {
              font-weight: normal;
              &.accepted-text {
                color: $dark-gray;
                font-size: 12px;
              }
            }
          }
        }
        i.fa.fa-plus-circle.add-icon-parent {
          color: #519fa7;
          margin: 0 5px;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .btn-round-white-bg {
        box-sizing: border-box;
        width: 99px;
        border: 1px solid $border-col-custom;
        border-radius: 12.5px;
        background-color: $bg-white;
        color: $primary-text-color;
        font-size: $font-12;
        line-height: 25px;
        margin: 5px 0;
        &.btn-pending {
          border: 1px solid #f6656d;
          border-radius: 12.5px;
          background-color: #f7a4a8;
          color: $bg-white;
        }
        &.btn-completed {
          border: 1px solid #519fa7;
          border-radius: 12.5px;
          background-color: #92c4c9;
          color: $bg-white;
        }
      }
    }
    .name {
      .list-group {
        border: 0;
        .arrow-up {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 10px solid $arrow-color;
          cursor: pointer;
          margin-right: 5px;
        }
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 10px solid $arrow-color;
          cursor: pointer;
          margin-right: 5px;
        }
      }
      li {
        border: none;
        &:hover {
          background-color: transparent;
        }
        &:first-child {
          border: 0;
          color: $text-col3;
          font-size: $font-16;
          font-weight: $fw-700;
          padding: 0;
        }
      }
    }
    .wrong-invite-notification {
      position: absolute;
      right: 25px;
      top: 0;
      margin-top: -30px;
      cursor: pointer;
    }
    .text-normal {
      color: $dark-gray;
      font-size: $font-14;
      line-height: 25px;
      &.link-view {
        button {
          font-weight: $fw-600;
          color: $col-red;
        }
      }
      &.font-sm {
        font-size: 13px;
      }
      span {
        color: $gray-color3;
        font-size: $font-14;
        font-weight: $fw-600;
      }
      .resume-link {
        a {
          font-weight: 600;
          color: $col-red !important;
        }
      }
    }
    .text-bold {
      color: $dark-gray;
      font-size: $font-16;
      line-height: 25px;
      font-weight: $fw-600;
    }
  }
  .section-pending-request {
    border: 1px solid $border-light-gray;
    border-radius: 5px;
    background-color: $gray-color4;
    box-shadow: 0 2px 4px 0 $gray-color5;
    box-sizing: border-box;
    padding: 10px;
    margin: 15px;
    ul {
      li {
        padding-bottom: 10px;
        &:first-child {
          color: $primary-text-color;
          font-size: $font-16;
          font-weight: $fw-600;
          span {
            vertical-align: middle;
            padding-right: 10px;
            img {
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }
  .user-daycare {
    position: absolute;
    right: 0;
    color: $bg-white;
    font-size: $font-16;
    top: 0;
    padding: 10px;
  }
}
.empty-section {
  img {
    height: 400px;
  }
  p {
    color: $secondary-text-color;
    text-align: center;
    font-size: $font-16;
  }
}
.no-screening-available,
.no-journal-available {
  height: 150px;
  div {
    color: $primary-text-color;
    font-size: $font-16;
    font-weight: $fw-600;
    line-height: 22px;
    margin: auto;
  }
}
.modal-sidebar {
  z-index: 1049;
  position: fixed;
  width: calc(100%);
  height: 100%;
  background: rgba(185, 185, 185, 0.5);
  top: 0;
  transform: translateX(100%);
  @media screen and (min-width: 800px) {
    margin-left: -60px;
  }
  @include transition3s;
  .section-journal-content {
    height: 100vh;
    background-color: $bg-white;
    height: 100vh;
    position: absolute;
    top: 0;
    transform: translateX(100%);
    width: 50%;
    max-width: 850px;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    right: 0;
    @include transition3s;
    .modal-popup-container {
      left: 25%;
    }
    .body-main {
      textarea {
        &:focus {
          border: 1px solid $theme-color-green;
          outline: none;
        }
      }
    }
    .btn-custom-journal {
      min-width: 160px;
      padding: 6px !important;
    }
  }
  &.open {
    transform: translateX(0%);
    @include transition3s;
    .section-journal-content {
      transform: translateX(0%);
      @include transition3s;
      .modal-popup-container-2 {
        left: 25%;
      }
    }
  }
  .header-main {
    border-bottom: 1px solid $border-col-custom2;
    padding: 20px;
    h3 {
      color: $dark-gray2;
      font-family: "transcendregular";
      font-size: $font-18;
      font-weight: $fw-600;
      letter-spacing: 0.24px;
      display: inline-block;
      padding-right: 30px;
    }
    button {
      &:focus {
        outline: none;
      }
    }
  }
  .body-main {
    padding: 15px 20px;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    .journal-entry {
      margin-bottom: 20px;
      h3 {
        color: $secondary-text-color;
        font-size: $font-16;
        font-weight: $fw-600;
        line-height: 35px;
        margin-bottom: 10px;
      }
      .date-section {
        display: inline-block;
        border: 1px solid #bfbebe;
        padding-left: 15px;
        border-radius: 3px;
        color: $dark-gray;
        font-size: $font-14;
      }
      input.profile__datepicker {
        font-size: $font-14;
        color: $dark-gray;
        padding-left: 5px;
        border: 0 !important;
        &:disabled {
          background-color: $bg-white;
          cursor: default;
        }
      }
    }
    .child-mood-div {
      .child-mood-outer {
        margin-right: 20px;
      }
    }
    .journal-button {
      .btn {
        width: 120px;
        padding: 5px;
      }
    }
    p.p-title {
      color: $dark-gray;
      font-size: $font-14;
      font-weight: $fw-600;
    }
    .journal-title {
      color: #777777;
      font-size: 14px;
      font-weight: normal;
      line-height: 19px;
    }
  }
  select.journal-date {
    font-size: $font-14 !important;
    background-size: 15px !important;
    width: 150px !important;
    margin-left: 10px;
    color: $dark-gray !important;
  }
  .body-main {
    &.view-journal {
      .description {
        font-size: $font-14 !important;
        color: $dark-gray !important;
        border: 0 !important;
        padding-left: 0;
        max-width: 90%;
        line-height: 25px;
        height: auto;
        max-height: 150px;
        overflow-y: auto;
        word-break: break-word;
        margin-bottom: 15px;
      }
      .checkbox-div-checked {
        position: relative;
        .behaviour-icon {
          &.fa-info-circle {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            cursor: pointer;
          }
        }
      }
      .checkbox-div-checked .checkbox-icon {
        display: inline-block;
        position: relative;
        min-width: unset;
        min-height: unset;
        border-radius: 4px;
        background: white;
        border: none;
        img {
          height: 20px;
          top: -10px;
        }
      }
      .journal-edit-btn {
        background-color: #f6656d;
        min-width: 114px;
        font-size: 16px;
        padding: 6px;
      }
    }
  }
  .view-media {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #fff;
    top: 0;
    .body-media {
      p {
        color: $dark-gray2;
        font-size: $font-16;
        padding: 10px 10px 20px;
        &.col-dark-gray {
          color: $dark-gray;
          padding-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
}

.main-daycare {
  .modal-popup-container {
    max-width: 600px;
  }
  .dashboard-header-div {
    width: calc(100% - 230px);
  }
  .user-daycare {
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.table-scrollable {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tbody {
    display: block;
    max-height: 200px;
    overflow: auto;
  }
  thead {
    width: calc(100% - 1em);
  }
}

// New dashboard css

.card-new {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 7px 0 rgba(200, 200, 200, 0.8);
}

.card-bg1 {
  background-image: url(../images/new/Shape1.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.card-bg2 {
  background-image: url(../images/new/Shape2.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.card-bg3 {
  background-image: url(../images/new/Shape3.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.dashboard-card-title {
  color: #25858f;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.dashboard-card-sub {
  color: #9ca2a2;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  padding-left: 35px;
  padding-bottom: 5px;
}

.dashboard-card-info-img {
  position: absolute;
  &.journal {
    left: -22px;
    bottom: -17px;
  }
  &.screening {
    left: -22px;
    bottom: -18px;
  }
  &.village {
    left: -21px;
    bottom: -9px;
  }
  &.arrow {
    top: 0px;
    right: 15%;
    width: 50px;
    height: 50px;
  }
}

.card-action-btn {
  height: 25px;
  width: 100px;
  border-radius: 10px;
  background-color: rgba(246, 101, 109, 0.1);
  color: #f6656d;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  position: relative;
}

.icon-container {
  height: 24px;
  width: 24px;
  border-radius: 2px;
  background-color: #d0fae6;
  i {
    color: $theme-color-green;
  }
}
.child-detail {
  padding-right: 22.5px;
  .child-name {
    color: $theme-color-green;
    font-family: "transcendregular";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  .child-age {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
}
.in-care {
  color: #707575;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.button-notify {
  box-sizing: border-box;
  height: 8.5px;
  width: 8.5px;
  border: 0.5px solid #ffffff;
  background-color: #ee646d;
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
  position: absolute;
  top: -2.5px;
  right: -2.5px;
  border-radius: 50%;
}
.button-risk {
  height: 15px;
  width: 15px;
  position: absolute;
  top: -10px;
  right: -5px;
}
.warning-shadow {
  box-shadow: 0px 0px 14px 0px rgba(246, 101, 109, 0.5),
    0px 1px 7px 0px rgba(200, 200, 200, 0.8);
}

.child-age-distribution {
  position: relative;
  padding: 0 10px;
  .distribution-title {
    position: absolute;
    bottom: 30px;
    color: #686666;
    font-size: 12px;
    margin: 15px 5px 15px 0;
  }
}

.accpet-invite {
  border-radius: 10px;
  border: 1px solid green;
  padding: 10px;
  color: green;
  text-align: right;
}

.accpet-invite:hover {
  background-color: green;
  color: #fff;
}

.custom-scrollbar::-webkit-scrollbar-track
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #EDEDED;
    // border-left: 3.5px solid white;
    // border-right: 3.5px solid white;
    // border-top: 3.5px solid white;
    // border-bottom: 3.5px solid white;
    background-clip: padding-box;
    width: 12px;
    height: 12px;
}

.custom-scrollbar::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D2D2D2;
  border-radius: 100px;
    width: 12px;
    height: 12px;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
