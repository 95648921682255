.hidden {
  display: none;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.flex-col {
  flex-direction: column;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.w-1 {
  width: 25%;
}
.w-2 {
  width: 50%;
}
.w-3 {
  width: 75%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.text-white {
  color: #fff;
}
.text-10 {
  font-size: 10px;
}
.text-11 {
  font-size: 11px;
}
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 14px;
}
.text-15 {
  font-size: 15px;
}
.text-base {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 20px;
}
.text-2xl {
  font-size: 24px;
}
.tet-3xl {
  font-size: 30px;
}
.text-32 {
  font-size: 32px;
}
.text-36 {
  font-size: 36px;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}
.mx-7 {
  margin-left: 28px;
  margin-right: 28px;
}
.mx-8 {
  margin-left: 32px;
  margin-right: 32px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-5 {
  margin-right: 20px;
}
.mr-6 {
  margin-right: 24px;
}
.mr-7 {
  margin-right: 29px;
}
.mr-8 {
  margin-right: 32px;
}
.mr-9 {
  margin-right: 36px;
}
.mr-10 {
  margin-right: 40px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-6 {
  margin-bottom: 24px;
}
.mb-7 {
  margin-bottom: 28px;
}
.mb-8 {
  margin-bottom: 32px;
}
.mb-9 {
  margin-bottom: 36px;
}
.mb-10 {
  margin-bottom: 40px;
}
.mb-11 {
  margin-bottom: 44px;
}
.mb-12 {
  margin-bottom: 48px;
}
.mb-13 {
  margin-bottom: 52px;
}
.mb-14 {
  margin-bottom: 56px;
}
.mb-15 {
  margin-bottom: 60px;
}
.mb-16 {
  margin-bottom: 64px;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-5 {
  margin-left: 20px;
}
.ml-6 {
  margin-left: 24px;
}
.ml-7 {
  margin-left: 28px;
}
.ml-8 {
  margin-left: 32px;
}
.ml-9 {
  margin-left: 36px;
}
.ml-10 {
  margin-left: 40px;
}
.ml-11 {
  margin-left: 44px;
}
.ml-12 {
  margin-left: 48px;
}
.ml-13 {
  margin-left: 52px;
}
.ml-14 {
  margin-left: 56px;
}
.ml-15 {
  margin-left: 60px;
}
.ml-16 {
  margin-left: 64px;
}
.ml-17 {
  margin-left: 68px;
}
.ml-18 {
  margin-left: 72px;
}
.mt-auto {
  margin-top: auto;
}
.mt-1 {
  margin-top: 4px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 20px;
}
.mt-6 {
  margin-top: 24px;
}
.mt-7 {
  margin-top: 28px;
}
.mt-8 {
  margin-top: 32px;
}
.mt-10 {
  margin-top: 40px;
}
.mt-12 {
  margin-top: 48px;
}
.mt-13 {
  margin-top: 52px;
}
.mt-14 {
  margin-top: 56px;
}
.mt-15 {
  margin-top: 60px;
}
.mt-16 {
  margin-top: 64px;
}
.mt-17 {
  margin-top: 68px;
}
.mt-18 {
  margin-top: 72px;
}
.mt-19 {
  margin-top: 76px;
}
.mt-20 {
  margin-top: 80px;
}
.pt-1 {
  padding-top: 4px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-3 {
  padding-top: 12px;
}
.pt-4 {
  padding-top: 16px;
}
.pt-5 {
  padding-top: 20px;
}
.pt-5 {
  padding-top: 20px;
}
.pt-6 {
  padding-top: 24px;
}
.pt-7 {
  padding-top: 28px;
}
.pt-8 {
  padding-top: 32px;
}
.pt-9 {
  padding-top: 36px;
}
.pt-10 {
  padding-top: 40px;
}
.pl-1 {
  padding-left: 4px;
}
.pl-2 {
  padding-left: 8px;
}
.pl-3 {
  padding-left: 12px;
}
.pl-4 {
  padding-left: 16px;
}
.pl-5 {
  padding-left: 20px;
}
.pl-6 {
  padding-left: 24px;
}
.pl-7 {
  padding-left: 28px;
}
.pl-8 {
  padding-left: 32px;
}
.pl-9 {
  padding-left: 36px;
}
.pl-10 {
  padding-left: 40px;
}
.pl-11 {
  padding-left: 44px;
}
.pl-12 {
  padding-left: 48px;
}
.pl-13 {
  padding-left: 52px;
}
.pl-14 {
  padding-left: 56px;
}
.pl-15 {
  padding-left: 60px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pb-5 {
  padding-bottom: 20px;
}
.pb-6 {
  padding-bottom: 24px;
}
.pb-7 {
  padding-bottom: 28px;
}
.pb-8 {
  padding-bottom: 32px;
}
.pb-9 {
  padding-bottom: 36px;
}
.pb-10 {
  padding-bottom: 40px;
}
.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-7 {
  padding-left: 28px;
  padding-right: 28px;
}
.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}
.px-9 {
  padding-left: 36px;
  padding-right: 36px;
}
.px-10 {
  padding-left: 40px;
  padding-right: 40px;
}
.px-11 {
  padding-left: 44px;
  padding-right: 44px;
}
.px-12 {
  padding-left: 48px;
  padding-right: 48px;
}
.px-13 {
  padding-left: 52px;
  padding-right: 52px;
}
.px-14 {
  padding-left: 56px;
  padding-right: 56px;
}
.px-15 {
  padding-left: 60px;
  padding-right: 60px;
}
.px-16 {
  padding-left: 64px;
  padding-right: 64px;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-7 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.p-1 {
  padding: 4px;
}
.p-2 {
  padding: 8px;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.p-5 {
  padding: 20px;
}
.p-6 {
  padding: 24px;
}
.p-7 {
  padding: 28px;
}
.p-8 {
  padding: 32px;
}
.p-9 {
  padding: 36px;
}
.p-10 {
  padding: 40px;
}
.-mx-1 {
  margin-left: -4px;
  margin-right: -4px;
}
.-mx-2 {
  margin-left: -8px;
  margin-right: -8px;
}
.-mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.-mx-4 {
  margin-left: -16px;
  margin-right: -16px;
}
.-mx-5 {
  margin-left: -20px;
  margin-right: -20px;
}
.-mx-6 {
  margin-left: -24px;
  margin-right: -24px;
}
.pr-1 {
  padding-right: 4px;
}
.pr-2 {
  padding-right: 8px;
}
.pr-3 {
  padding-right: 12px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-5 {
  padding-right: 20px;
}
.pr-6 {
  padding-right: 24px;
}
.pr-7 {
  padding-right: 28px;
}
.pr-8 {
  padding-right: 32px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.opacity1 {
  opacity: 0.1;
}
.opacity2 {
  opacity: 0.2;
}
.opacity3 {
  opacity: 0.3;
}
.opacity4 {
  opacity: 0.4;
}
.opacity5 {
  opacity: 0.5;
}
.opacity6 {
  opacity: 0.6;
}
.opacity7 {
  opacity: 0.7;
}
.opacity8 {
  opacity: 0.8;
}
.opacity9 {
  opacity: 0.9;
}
.leading1 {
  line-height: 1.1;
}
.leading2 {
  line-height: 1.2;
}
.leading3 {
  line-height: 1.3;
}
.leading4 {
  line-height: 1.4;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded {
  border-radius: 4px;
}
.rounded-sm {
  border-radius: 6px;
}
.rounded-md {
  border-radius: 8px;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-12 {
  border-radius: 12px;
}
.rounded-lg {
  border-radius: 16px;
}
.rounded-full {
  border-radius: 100%;
}
.text-primary {
  color: var(--primary-text);
}
.text-secondary {
  color: var(--secondary-text);
}
.primary-text {
  color: var(--primary-text);
}
.secondary-text {
  color: var(--secondary-text);
}
.text-primary-brand {
  color: var(--primary-brand);
}
.text-secondary-brand {
  color: var(--secondary-brand);
}
.text-red {
  color: var(--error);
}
.text-red-color {
  color: var(--error);
}
.text-green {
  color: var(--green);
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-tertiary {
  color: var(--tertiary-text);
}
.text-grey {
  color: var(--text-grey);
}
.bg-green {
  background-color: var(--green);
}
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: transparent;
}
.object-cover {
  object-fit: cover;
}
.object-contain {
  object-fit: contain;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.list-style-disc {
  list-style: disc;
}
.list-position-inside {
  list-style-position: inside;
}
.text-underline {
  text-decoration: underline;
}
.text-normal {
  font-style: normal;
}
.text-italic {
  font-style: italic;
}
.z10 {
  z-index: 10;
}
.z20 {
  z-index: 20;
}
.z30 {
  z-index: 30;
}
.z40 {
  z-index: 40;
}
.z50 {
  z-index: 50;
}
.text-italic {
  font-style: italic;
}
.resize-none {
  resize: none;
}
.hidden {
  display: none !important;
}
