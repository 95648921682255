@import "config";
@import "util";
@import "mixins";
// @import "formfields";

@font-face {
  font-family: "transcendregular";
  src: url(fonts/Transcend/transcend_regular-webfont.woff);
  src: url(fonts/Transcend/transcend_regular-webfont.woff2);
  font-weight: normal;
  font-style: normal;
}

.in-dev-div {
  font-size: rem-calc(10px);
  text-align: justify;
  @include screen(custom, min, 768) {
    // padding: rem-calc($section-padding-left) rem-calc(80px);
    // width: 100%;
    // margin-left: 60px;
  }
}

.addToHomeScreenToast {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: #17a2b8;

  .toast {
    max-width: none !important;
    .head {
      color: #25858f;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  img {
    width: rem-calc(12px);
  }
}

.left-image-container {
  background: #e8fffd;
  width: 50vw;
  padding: 3vw;

  @include screen(custom, max, 768) {
    display: none;
  }

  .bg-image {
    width: 50vw;
    margin-top: rem-calc(30px);
    &.w-35 {
      width: 35vw;
    }
  }

  svg {
    width: 50vw;
    margin-top: rem-calc(30px);

    &.w-35 {
      width: 35vw;
    }
  }
}

.main-daycare {
  .right-container {
    @include screen(custom, max, 600) {
      margin: 0px calc(6% + 20px) 20px;
    }
  }
  .right-container-pin {
    @include screen(custom, max, 600) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .dashboard-header {
    @include screen(custom, max, 600) {
      margin-top: 35px;
    }
  }
  .breadcrumb-div {
    @include screen(custom, max, 600) {
      margin-top: 20px;
    }
  }
}

.right-container {
  width: 35vw;
  margin: 0 auto;
  @include screen(custom, max, 768) {
    min-width: 70vw;
  }
  @include screen(custom, max, 600) {
    min-width: 100vw;
  }
}
.right-container--login {
  @include screen(custom, max, 600) {
    background-image: url(../images/img/background-2.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
}

.right-container--register {
  @include screen(custom, max, 600) {
    background-image: url(../images/img/background.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
  }
}

.page-container-auth {
  min-height: 100vh;
  background: #ffffff;
}

.page-container-pad {
  padding: rem-calc($container-padding-top) rem-calc($container-padding-right)
    rem-calc($container-padding-bottom) rem-calc($container-padding-left);
}

.page-head {
  @include page-header;
  &--mobile {
    display: none;
    visibility: hidden;
    @include screen(custom, max, 768) {
      display: block;
      visibility: inherit;
    }
  }
}

.w-90 {
  width: 90% !important;
  margin: 0 auto;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}
.auto-margin {
  margin: auto˝;
}

.red-i {
  color: #f6656d !important;
}
// .main {
//   margin-top: 70px;
// }

.disclaimer-div {
  p {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
  span {
    color: #25858f !important;
    text-decoration: underline;
    cursor: pointer;
  }
}

.action-text {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  .clinical-history-btn {
    text-decoration: none;
    color: #f6656d;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #f6656d;
    }
  }
}

.arrow-reverse {
  transform: scaleY(-1);
}

.container-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.fab-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.fab-outer {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: rgba(246, 101, 109, 0.85);
  box-shadow: 0 2px 7px 0 rgba(200, 200, 200, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #ffffff;
    font-size: 26.18px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
}

@import "reset.scss";
@import "global.scss";
@import "flexbox.scss";
@import "grid.scss";
@import "physician.scss";
@import "settings.scss";
@import "login2.scss";
@import "sidenav.scss";
@import "spacing.scss";
@import "splash.scss";
@import "variables.scss";
@import "verify.scss";
@import "createprofile.scss";
@import "dobDatepicker.scss";
@import "geosuggest";
@import "child.scss";
@import "profile.scss";
@import "notification.scss";
@import "physicianfeedback.scss";
@import "assessment.scss";
@import "village.scss";
@import "journal.scss";
@import "checklist.scss";
@import "modal.scss";
@import "register.scss";
@import "report.scss";
@import "healthcenters.scss";
@import "dashboard.scss";
@import "graph.scss";
@import "otp.scss";
@import "admin.scss";
@import "spinner.scss";
@import "faq.scss";
@import "privacypopup.scss";
@import "popup.scss";
@import "privacy.scss";
@import "pdf.scss";
@import "swycWeb.scss";
@import "swal.scss";
@import "swycQuestions";
@import "screening";
