.swyc-web-report {
  .swyc-section-wrapper {
    border-radius: 30px;
    background-color: rgba(226, 242, 243, 0.4);
    .section-details {
      background-color: transparent;
      .section-label,
      .status-description {
        font-size: 19px;
      }
      .state-icon {
        img {
          height: 34px;
        }
      }
      .section-title-and-status {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .dropdown-icon {
        display: none;
      }
    }
  }
  .swyc-result-details {
    padding: 40px 40px 0px 40px;
    .left-box,
    .right-box {
      .label {
        font-size: 16px;
      }
      .text {
        font-size: 21px;
      }
    }
  }
  .next-steps {
    padding: 0 30px 15px;
    .title {
      font-size: 21px;
      padding: 40px 0;
    }
    .danger-section {
      margin-top: 0;
      padding: 20px 30px 0;
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      &:last-child {
        padding-bottom: 20px !important;
      }
    }
  }
  .section-copy-result {
    text-align: center;
    padding: 30px;
    h3 {
      color: #6dbac2;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 0;
    }
    p {
      color: #707575;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      padding: 20px;
      margin: auto;
    }
  }
  .why-subscribe {
    text-align: center;
    padding: 30px;
    h3 {
      color: #f6656d;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: 0;
    }
    .list-content-wrapper {
      padding: 50px 15px 20px;
      li {
        &:not(:last-child) {
          padding-bottom: 50px;
        }
        .text-content {
          color: #707575;
          font-size: 18px;
          line-height: 24px;
          padding: 0 5px 0 30px;
          text-align: left;
        }
      }
    }
    .section-subscribe-reason {
      .bullets {
        background-color: #d26268;
        color: #ffffff;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        padding: 10px 20px;
        border-radius: 50%;
        height: 55px;
        width: 55px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .register-swyc-web {
    padding: 10px 20px 20px;
    button {
      min-width: 210px;
      background-color: #f6656d;
      box-shadow: inset -3px -3px 3px 0 rgba(209, 76, 83, 0.8),
        inset 3px 3px 3px 0 #ffaeb2;
    }
  }
}
.swyc-wrapper {
  .react-datepicker-popper {
    z-index: 11;
  }
  .container-header {
    .page-container {
      min-width: 570px;
      @media screen and (max-width: 700px) {
        min-width: auto;
      }
      @media screen and (max-width: 768px) {
        .image-res-center {
          text-align: center;
        }
      }
      form {
        background-color: #fff;
      }
    }
    @media screen and (min-width: 1000px) {
      .res-logo-align {
        text-align: right;
      }
    }
    @media screen and (max-width: 1000px) {
      .res-auto {
        margin: auto;
      }
      .res-logo-align {
        text-align: center;
      }
    }
  }
  .section-start {
    padding: 25px;
    text-align: center;
    h3 {
      color: #25858f;
      font-size: 21px;
      font-weight: 600;
    }
  }
  h4.inner-title {
    color: #707575;
    font-size: 16px;
    line-height: 25px;
  }
  .section-stage {
    max-width: 70%;
    margin: auto;
    text-align: center;
  }
  .disclaimer-swyc {
    position: absolute;
    bottom: 0;
  }
}
.swyc-web-questions {
  .question-resp-div {
    .swyc-response-btn {
      .far {
        font-size: 25px !important;
      }
      .fa-circle {
        color: #25858f !important;
      }
    }
    .response-buttons-div {
      .btn-assessment p {
        max-width: 100% !important;
      }
    }
  }
}
