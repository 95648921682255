.faq .card-header {
  padding: 10px 15px;
  border: 1px solid #e2f1f3;
  img {
    transition: 0.3s ease all;
  }
}
.faq .card-body {
  padding: 20px 15px;
}

.faq .video-list {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
}
.faq .card {
  max-height: 45px;
  overflow: hidden;
}

.faq .card.expanded {
  max-height: initial;
  overflow: auto;
  transition: height 0.35s ease;
  .card-header {
    img {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: 0.3s ease all;
    }
  }
}

.faq .question {
  color: #707575;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  font-weight: 600;
}
.faq .answer {
  color: #707575;
  font-size: 12px;
  line-height: 17px;
  margin-top: 12px;
}
.faq .faq-container {
  margin-bottom: 20px;
}

.faq .faq-container .answer {
  display: none;
}

.faq .faq-container.open .answer {
  display: block;
}
