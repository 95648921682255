.navigation__checkbox {
  display: none;
}
.navigation__button {
  position: fixed;
  top: 2px;
  right: 25px;
  display: none;
  transition: all 0.2s;
  z-index: 20;
  cursor: pointer;
  @media only screen and (max-width: 1300px) {
    display: block;
  }
}
.navigation__icon {
  position: relative;
  margin-top: 24px;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  display: inline-block;
  background-color: var(--primary-brand);
  width: 30px;
  height: 3px;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -9px;
}
.navigation__icon::after {
  top: 9px;
}
.navigation__button:hover .navigation__icon::before {
  top: -10px;
}
.navigation__button:hover .navigation__icon::after {
  top: 10px;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

//mobile menu navigation
.mobile-menu-navigation {
  &-wrapper {
    position: absolute;
    width: 0;
    height: 100vh;
    top: 0;
    right: 0;
    padding-top: 48px;
    background-color: #fff;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
    z-index: 12;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  &__list-item {
    position: relative;
    color: var(--primary-text);
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px 0;
    margin: 15px 0;
    letter-spacing: 0.1em;
    opacity: 0;
    // transform: scale(0.75) translate3d(0, 100px, 0);
    transition: font-weight 300ms;
    cursor: pointer;
    > a {
      color: var(--primary-text);
      font-weight: 600;
    }
    &.error > a {
      position: relative;
      color: var(--primary-text);
      font-weight: 600;
      &::before {
        content: "" !important;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 6px;
        height: 6px;
        background-color: var(--text-red);
        border-radius: 100%;
      }
    }
    &:hover,
    &.active {
      font-weight: bold;
      transition-delay: 0;
    }
    &:hover > a {
      font-weight: bold;
    }
    // &:first-of-type {
    //   transition: all 0.3s 0.3s;
    //   margin-top: 50px;
    // }
    // &:nth-of-type(2) {
    //   transition: all 0.3s 0.45s;
    // }
    // &:nth-of-type(3) {
    //   transition: all 0.3s 0.6s;
    // }
    // &:nth-of-type(4) {
    //   transition: all 0.3s 0.75s;
    // }
    // &:nth-of-type(5) {
    //   transition: all 0.3s 0.9s;
    // }
  }
}

.navigation__checkbox:checked ~ .mobile-menu-navigation-wrapper {
  opacity: 1;
  visibility: visible;
  width: 300px;
  .mobile-menu-navigation__list-item {
    opacity: 1;
    // transform: scale(1) translate3d(0, 0, 0);
  }
}
