*,
body,
input,
input::placeholder,
select,
button,
textarea {
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

body {
  min-height: 100%;
  color: #707575;
  /* background: #3eaab6; */
  background: #cee4e6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

// Hide Input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pos-relative {
  position: relative !important;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80% !important;
}

.section-container {
  min-height: 100vh !important;
}

.page-container {
  width: 100%;
  min-height: calc(100vh - 144px);
  background-color: white;
}
.page-container1 {
  width: 100%;
  min-height: calc(100vh - 144px);
}

.page-container-no-disclaimer {
  min-height: calc(100vh - 70px);
}

.common-container {
  background-color: white;
}

.pe {
  pointer-events: all !important;
}
.bg1 {
  background-image: url(../images/img/background.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.bg2 {
  background-image: url(../images/img/background-2.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg3 {
  background-image: url(../images/img/background-3.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.bg4 {
  background-image: url(../images/img/background-4.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.mx-container {
  // max-width: 350px;
  margin: 0 auto;
}

/* Brand Logo with name */
.brand-logo img {
  height: 60px;
  // object-fit: cover;
}

.brand-logo h1 {
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 28px;
  font-weight: normal;
}

.brand-logo--secondary {
  height: 90px;
  background-color: $theme-color-green;
  padding-top: 10px;
}

.brand-logo--secondary h1 {
  color: #ffffff;
}

/* Section Title (page title) */
.section-title {
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 19px;
  font-weight: normal;
  line-height: 23px;
  margin-bottom: 30px;
  /* white-space: nowrap; */
}

/* Form group label and input style */
.form-group {
  margin-bottom: 25px;
  position: relative;
}

.form-group label {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;
}
.headline-color {
  color: #25858f;
}
.form-group .form-control-custom {
  width: 100%;
  padding: 10px 15px;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.mobile-number-css {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.edit-profile-logo-preview {
  border: 2px dotted gray;
  height: 85px;
  width: 85px;
  padding: 3px;
}
.image-logo-preview {
  margin-top: -153px !important;
  margin-left: 450px !important;
}
.custom-role-css {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.form-group .form-control-custom::placeholder {
  color: #707575;
  font-size: 16px;
  line-height: 22px;
}

.form-group .form-control-custom:focus {
  border: 1px solid $theme-color-green;
  outline: none;
}

.form-group .form-group-error {
  position: absolute;
  // bottom: -17px;
  font-size: 12.5px;
  color: red;
  font-weight: bold;
}

.form-group .toggle-password-view {
  position: absolute;
  top: 45px;
  right: 15px;
  color: gray;
  font-size: 18px;
}

.form-group .custom-search-box {
  position: relative;
}

.form-group .custom-search-box input {
  padding-right: 40px;
}

.form-group .custom-search-box i {
  position: absolute;
  right: 15px;
  top: 14px;
  font-size: 18px;
  color: #9ca2a2;
}

.form-group-info {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.text-italic {
  font-style: italic !important;
}

@mixin mdInput {
  appearance: none;
  background: transparent;
  width: 100%;
  padding: 10px 15px;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.3s ease;

  // IE 10-11
  &:-ms-input-placeholder {
    color: #707575;
    font-size: 16px;
    line-height: 22px;
  }

  // All other browsers except Edge
  &:placeholder-shown {
    color: #707575;
    font-size: 16px;
    line-height: 22px;
  }

  &:focus {
    border: 1px solid $theme-color-green;
    outline: none;
  }
}

.form-group .custom-select {
  width: 100%;
  padding: 10px 15px;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background: #ffffff url(../images/img/custom-select-arrow.svg) right 15px
    center no-repeat;
  transition: all 0.3s ease;
  -webkit-appearance: none;
  height: 44px;
}

.form-group .custom-select:focus {
  border: 1px solid $theme-color-green;
  outline: none;
  box-shadow: none;
}

.error-message {
  font-size: 12.5px;
  color: red;
  font-weight: bold;
}

.success-message {
  font-size: 12px;
  color: green;
}
/* Button Style */
.btn {
  color: #ffffff;
  // font-size: 21px;
  // line-height: 31px;
  // font-weight: 600;
  // padding: 10px 15px;
  // border-radius: 25px;
  /* background-color: white; */
}
.btn:hover {
  color: #ffffff;
}

.btn.btn--primary {
  background: #3eaab6;
  box-shadow: inset -5px -6px 3px 0 #316d73, inset 4px 6px 3px 0 #6fb3ba;
  border-radius: 25px;
}
.btn.btn--secondary {
  background: #f6656d;
  box-shadow: inset -5px -6px 3px 0 rgba(181, 37, 37, 0.5),
    inset 2px 6px 3px 0 #eeadad;
  border-radius: 25px;
}

.btn.btn--red {
  font-size: 14px;
  color: #f6656d;
  padding: 0px 15px;
  background-color: rgba(246, 101, 109, 0.1);
}

.btn-assessment {
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 19px !important;
  padding: 5px 8px !important;
  text-align: center;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: #d8d8d8;
  box-shadow: inset -5px -2px 2px 0 rgba(199, 197, 197, 0.81),
    inset 2px 3px 2px 0 rgba(245, 244, 244, 1);
  color: #787272;
  &:hover {
    color: #787272;
    outline: none !important;
  }
  &:focus {
    outline: none;
  }
}
.btn-assessment.btn--active {
  background: #f6656d;
  box-shadow: inset -5px -6px 3px 0 rgba(181, 37, 37, 0.5),
    inset 2px 6px 3px 0 #eeadad;
  color: #ffffff;
  &:hover {
    color: #ffffff;
    outline: none;
  }
}

.btn.btn--Cancel {
  font-size: 14px;
  background-color: #fff;
  color: #787272;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

/* Custom Checkbox */
.custom-checkbox label {
  cursor: pointer;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkbox-icon {
  display: inline-block;
  position: relative;
  min-width: 27px;
  min-height: 27px;
  border-radius: 4px;
  background: white;
  border: 1px solid #f6656d;
}
.custom-checkbox .checkbox-icon img {
  position: absolute;
  top: -6px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.custom-checkbox input:checked + label .checkbox-icon img {
  opacity: 1;
  visibility: visible;
}

.checkbox-div-checked {
  .checkbox-icon {
    display: inline-block;
    position: relative;
    min-width: 27px;
    min-height: 27px;
    border-radius: 4px;
    background: white;
    border: 1px solid #f6656d;
  }
  .checkbox-icon img {
    position: absolute;
    top: -6px;
    left: 0;
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
  }
}

/* Custom Radio button */

.custom-radio > input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-radio > i {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 0.2s;
  box-shadow: inset 0 0 0 8px #fff;
  border: 1px solid gray;
  margin-right: 5px;
  background: white;
}
.custom-radio > input:checked + i {
  box-shadow: inset 0 0 0 5px #fff;
  background-color: #f6656d;
  border: 1px solid #f6656d;
  color: #f6656d;
}

.form-group .custom-checkbox label {
  margin-bottom: 0;
}
.form-group .custom-radio {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

/* Header CSS */
.container-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.header {
  padding: 20px;
  padding-left: 12px;
  height: 70px;
  background: $theme-color-green;
  // position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.header .header-menu {
  cursor: pointer;
  padding: 8px;
}

.header .page-name {
  font-family: "transcendregular";
  font-size: 20px;
  color: #ffffff;
  margin-top: 7px;
  margin-left: 20px;
}

.header .header-icons {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.header--secondary {
  background: white;
}

.header--secondary .page-name,
.header--secondary .header-icons {
  color: $theme-color-green;
}

.header .header-logo img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.header .header-logo p {
  font-family: "transcendregular";
  font-size: 24px;
  color: #ffffff;
  margin-top: 10px;
}

.header .header-notification {
  position: relative;
}

.header-actions {
  i {
    color: $theme-color-green;
  }
}

.header .notification-unread {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  font-weight: bold;
  color: #ffffff;
  background: #ee646d;
  border: 0.5px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
  padding-top: 1px;
}

.card {
  transition: height ease-out 1s;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
  border-radius: 10px;
  cursor: pointer;
}
.swyc-card {
  margin: auto;
  display: flex;
  padding: 15px 5px;
  flex-direction: column;
  justify-content: center;
  min-height: 440px;
  border-radius: 10px;
  border: solid 2px #f6656d;
  .font-large {
    font-size: 16px !important;
  }
  .font-small {
    font-size: 14px !important;
  }
  .para-head {
    color: #25858f;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
  }
  .para-title {
    font-size: 16px;
    line-height: 30px;
  }
  .danger-score {
    color: #f6656d;
  }
}
.card-b {
  border: 2px solid #f6656d;
}
.card-highlighted {
  background-color: #6dbac2;
  box-shadow: inset -3px -3px 5px 0 #458d94, inset 3px 4px 5px 0 #87ebf6;
  .title,
  .sub-text {
    color: #ffffff !important;
  }
}

.reject {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.textArea {
  color: #000;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-left {
  text-align: left !important;
}

.text-red {
  color: #f6656d;
  padding: 0px 15px;
}

.text-center {
  text-align: center !important;
}

/* Media query CSS */
@media screen and (max-width: 350px) {
}

.screening-i {
  color: #f6656d !important;
}
.journal-i {
  color: $theme-color-green !important;
}
.village-i {
  color: $theme-color-green !important;
}
.village-i2 {
  color: #6dbac2 !important;
}
.checklist-i {
  color: #6dbac2 !important;
}
.feedback-i {
  color: #c26d9c !important;
}
.settings-i {
  color: #79b89b !important;
}
.ft-col-secondary {
  color: #24858f !important;
}

.w-30 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-35 {
  width: 35%;
}
.w-45 {
  width: 45%;
}
.pos-rel-t2 {
  position: relative;
  top: 2px;
}
.main-daycare {
  input:disabled {
    background: #9ca2a2;
    cursor: not-allowed;
  }
  button:disabled {
    cursor: not-allowed;
  }
  .max-overflow-400 {
    overflow-y: auto;
    max-height: 400px;
  }
  .modal-popup {
    z-index: 10000 !important;
  }
  .faq {
    .answer {
      font-size: 14px;
      line-height: 25px;
    }
  }
}
.w-break {
  word-break: break-all;
}

.fs-14 {
  font-size: 14px !important;
}
.sort-icon {
  &.dsc {
    img {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}
.transparent {
  background: transparent !important;
}
@mixin transition3s {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@mixin placeholder-font14 {
  ::-webkit-input-placeholder {
    font-size: 14px !important;
    color: #bfb3ae !important;
  }
  ::-moz-placeholder {
    font-size: 14px !important;
    color: #bfb3ae !important;
  }
  :-ms-input-placeholder {
    font-size: 14px !important;
    color: #bfb3ae !important;
  }
  :-moz-placeholder {
    font-size: 14px !important;
    color: #bfb3ae !important;
  }
}
.no-underline {
  text-decoration: none !important;
}
.border-radius-s5 {
  border-radius: 5px 5px 0 0 !important;
}

.custom-drc-style {
  table {
    width: auto !important;
  }
}

.section-data-release-consent {
  h3 {
    color: #4b4d4d;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  p,
  a {
    color: #f6656d;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.section-daycare-legal {
  max-width: 700px !important;
}

.app-version {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.75;
  position: absolute;
  bottom: 20px;
}

.text-red {
  color: red !important;
}

.custom-modal {
  .modal-popup-container {
    padding: 25px 0px !important;
    border-radius: unset !important;
  }
  .modal_header {
    padding: 0px 25px 12px 25px !important;
    border-bottom: 1px solid #ddd;
  }
  .modal_title {
    font-family: unset !important;
  }
  .modal-body-text {
    font-weight: 500;
    margin-top: 20px !important;
    padding: 0px 25px !important;
  }
}

.add-details-modal-text {
  font-size: 19px !important;
  color: #707575 !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  font-weight: normal !important;
}

.right-0 {
  right: 0 !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.daycare-notification-count {
  top: -3px !important;
  right: -4px !important;
}

.swyc-intro-modal-image {
  width: 100%;
}

.swyc-intro-modal-ul {
  list-style: disc;
  margin-left: 15px;
  li {
    margin-top: 15px;
  }
}

.modal-body-text {
  &.swyc {
    max-height: 55vh;
    overflow: scroll;
    padding: 0 10px;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.width-100 {
  width: 100% !important;
}
.x-hidden {
  overflow-x: hidden !important;
}
.remove-media-button {
  color: #9ca2a2;
  font-size: 14px;
  font-weight: 600;
}

.uncheck-milestone-button {
  color: #f6656d;
  font-size: 14px;
  font-weight: 600;
}

.resend-invite-villager {
  color: #f6656d;
  font-weight: 600;
  margin-top: 10px;
  &:hover {
    color: #f6656d;
  }
}
button:disabled {
  cursor: default;
  opacity: 0.7;
}

.download-link {
  color: #25858f;
  font-weight: 600;
  &:hover {
    color: #106871;
  }
}
.download-patient-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #525252;
  &:hover {
    color: #525252;
  }
}
.patient-bulk-upload {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #777777;
  padding: 12px 35px;
  background: #e4e4e4;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: #fbd7d9;
  }
  & img {
    vertical-align: bottom;
    margin-right: 15px;
  }
}
.react-csv-input {
  display: none;
}
.upload-link {
  color: #25858f;
  font-weight: 600;
}
.space-between {
  justify-content: space-between;
}

.invite-physician-bulk {
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }
}

.swal-button {
  &--success {
    background-color: #25858f;
    &:hover,
    &:active,
    &:focus {
      background-color: #106871 !important;
    }
  }
}

.mt-20 {
  margin-top: 20px;
}
.existing-patient-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  text-align: left;

  & td,
  & th {
    border: 1px solid #ddd;
    text-align: left;
  }
  & th,
  & td {
    padding: 6px;
  }
}

.mt-30 {
  margin-top: 30px;
}

.ml-20 {
  margin-left: 20px;
}

.toggleLabel {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSpan {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #cbcecf;
  transition: 0.3s;
  border-radius: 30px;
}

.toggleSpan:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggleInput:checked + .toggleSpan {
  background-color: #25858f;
}

.toggleInput:checked + .toggleSpan:before {
  transform: translateX(29px);
}

.toggleStrong {
  left: 100%;
  width: max-content;
  line-height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.pointer-disable {
  cursor: not-allowed;
}

.form-field-success {
  text-align: left;
  color: #25858f;
  margin-top: 10px;
}

.pwd-progress-outer {
  background-color: #cdcdcd;
  height: 10px;
  border-radius: 100px;
}

.swal-text {
  color: #374957;
  font-weight: 500;
}

.swal-icon {
  &--error {
    border-color: #f6656d;
    &__line {
      background-color: #f6656d;
    }
  }
  &--success {
    border-color: #62cdc4;
    &__line {
      background-color: #25858f;
    }
  }
}
