:root {
  --primary-brand: #25858f;
  --secondary-brand: #f6656d;
  --primary-text: #374957;
  --secondary-text: #9b9b9b;
  --tertiary-text: #434343;
  --text-grey: #6b6b6b;
  --text-red: #ed2e39;
  --error: #ed2e39;
  --border1: #dedede;
  --border2: #dcdcdc;
}
