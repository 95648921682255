.split-progress-bar {
  position: relative;
  width: 100%;
  height: 24px;
  background-color: #e3e3e3;
  &--sm {
    height: 16px;
  }
  &:first-of-type {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  &:last-of-type {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  &.error {
    background-color: #f19da1;
    .split-progress-bar__points-container {
      color: #f6656d;
      border: 2px solid #f19da1;
    }
  }
  &.warning {
    background-color: #f4dd7c;
    .split-progress-bar__points-container {
      color: #6b6b6b;
      border: 2px solid #f4dd7c;
    }
  }
  &.success {
    background-color: #62cdc4;
    .split-progress-bar__points-container {
      color: var(--primary-brand);
      border: 2px solid #25858f;
    }
  }
  &:not(:last-child) {
    margin-right: 5px;
  }
  &-container {
    @extend %row;
  }
  &__points-container {
    position: absolute;
    @extend %row-center;
    color: var(--primary-brand);
    font-weight: bold;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #ffffff;
    border: 2px solid #25858f;
    border-radius: 100%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    outline: 4px solid #fff;
  }
}

.pointer {
  &-container {
    position: absolute;
    top: -48px;
    right: 0;
  }
  &-value {
    position: absolute;
    top: 4px;
    left: 12px;
    color: #fff;
    font-weight: 700;
  }
}
