@import "../base/placeholder.scss";

.aside-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  @extend %flex-col-center;
  flex-shrink: 0;
  width: 56px;
  background-color: #fff;
  height: 100vh;
  border-right: 1px solid #dedede;
  padding: 8px 0;
  &__list {
    width: 40px;
    height: 40px;
    @extend %row-center;
    flex-shrink: 0;
    margin-bottom: 20px;
    background-color: transparent;
    border-radius: 4px;
    transition: all 300ms;
    cursor: pointer;
    .icon-white {
      display: none;
    }
    .icon-green {
      display: none;
    }
    &.active {
      background-color: #84cdd6;
      .icon-white {
        display: block;
      }
      .icon-default {
        display: none;
      }
      &:hover {
        background-color: #84cdd6;
        .icon-green {
          display: none;
        }
      }
    }
    &:hover {
      background-color: #effdff;
      .icon-default {
        display: none;
      }
      .icon-green {
        display: block;
      }
    }
    &--notifications {
      position: relative;
      &__count {
        display: inline-flex;
        @extend %row-center;
        position: absolute;
        top: -5px;
        right: -3px;
        width: 18px;
        height: 18px;
        background-color: var(--text-red);
        border-radius: 100%;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }
    }
    &__container {
      display: flex;
    }
  }
  .box-tooltip__container {
    padding: 6px 12px;
    right: initial;
    left: 60%;
    top: 60%;
    transform: none;
    white-space: nowrap;
  }
}
