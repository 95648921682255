.box-tooltip {
  position: relative;
  &__container {
    position: absolute;
    top: 3px;
    left: 6px;
    max-width: 220px;
    padding: 8px 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--tertiary-text);
    transform: translate(2px, 10px);
    opacity: 0;
    visibility: hidden;
    border-radius: 4px;
    transition: all 300ms;
    z-index: 2;
  }
  &:hover .box-tooltip__container {
    opacity: 1;
    visibility: visible;
  }
}
