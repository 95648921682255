.splash-screen {
  // background-color: rgba(162, 245, 206, 0.25);
  background-color: rgb(194, 233, 224);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
}

.splash-container {
  /* border: 4px solid var(--primary-brand-color); */
  border: 4px solid $theme-color-green;
  border-radius: 10px;
  height: calc(100vh - 50px);
  position: relative;
}

.brand-logo--position {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.brand-logo--position h1 {
  font-size: 32px;
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: $theme-color-green;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right: 2px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@media screen and (max-width: 350px) {
  .brand-logo--position h1 {
    font-size: 26px;
  }
}

.splash-screen-new {
  background-color: #e1f1f3;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  .loader-gif {
    width: 235px;
    height: 235px;
    border-radius: 50%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}
