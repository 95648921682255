@import "../base/placeholder.scss";

.patient-closeup {
  .dashboard__main {
    height: 100%;
    overflow: auto;
  }
  &__main {
    padding: 20px;
    overflow: auto;
  }
  .alert-box {
    &-wrapper {
      @extend %row;
      margin-left: 24px;
      @media only screen and (max-width: 1200px) {
        margin: 24px 0 0 0;
      }
    }
    position: relative;
    @extend %flex-col-center;
    justify-content: center;
    width: 158px;
    height: 124px;
    background: #ededed;
    border-radius: 8px;
    border: 4px solid transparent;
    cursor: pointer;
    &__title {
      color: var(--secondary-text);
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }
    &__desc {
      color: var(--secondary-text);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
    &__count {
      color: var(--error);
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }
    &__icon {
      position: absolute;
      top: 12px;
      right: 14px;
    }
    &:not(:last-child) {
      margin-right: 16px;
      @media only screen and (max-width: 1200px) and (min-width: 900px) {
        margin-right: 30px;
      }
    }
    &__label {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 4px 12px;
      margin-top: 8px;
      background: #ed2e39;
      border-radius: 100px;
    }
    &.active {
      background: #ffeeef;
      border: 4px solid #ed2e39;
      .alert-box {
        &__title {
          color: var(--primary-text);
        }
        &__desc {
          color: var(--primary-text);
          font-size: 16px;
        }
      }
    }
    &.pass {
      background: #e0f5f3;
      border: 4px solid #62cdc4;
      .alert-box {
        &__title {
          color: var(--primary-text);
        }
        &__desc {
          color: var(--primary-text);
          font-size: 16px;
        }
      }
    }
    &.no-data {
      opacity: 0.8;
    }
  }
  .dev-trends {
    &-wrapper {
      display: flex;
      margin-left: -12px;
      margin-right: -12px;
      @media only screen and (max-width: 1200px) {
        flex-wrap: wrap;
      }
    }
    &__container {
      width: calc(100% / 3);
      padding-left: 12px;
      padding-right: 12px;
      @media only screen and (max-width: 1200px) {
        width: 80%;
        margin: auto;
      }
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin: auto;
      }
    }
    &__card {
      padding: 20px 16px;
      background-color: #fff;
      border-radius: 4px;
      &:not(:first-child) {
        margin-top: 24px;
      }
      &.no-padding {
        padding: 0;
      }
      &--empty-state {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          z-index: 15;
        }
      }
    }
  }
  .collapse {
    &__left {
      width: 115px;
      flex-shrink: 0;
      height: 35px;
      color: var(--secondary-text);
      font-weight: 600;
      padding: 6px 15px;
      background-color: #f5f5f5;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &__header {
      @extend %row;
      width: 100%;
      max-width: 100%;
      padding: 6px 15px;
      height: 31px;
      &__title {
        max-width: 90%;
        @extend %truncate;
      }
    }
    &__right {
      @extend %truncate;
      width: 100%;
      background: #f5f5f5;
      border: 2px solid #f5f5f5;
      margin-left: 4px;
      flex: 1;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &-list {
      @extend %row-start;
      &:not(:first-child) {
        margin-top: 8px;
      }
      &--alert {
        .collapse {
          &__left {
            color: var(--text-red);
            font-weight: bold;
            background-color: #ffeeef;
          }
          &__right {
            border: 2px solid #ffeeef;
          }
          &__header {
            color: var(--text-red);
            font-weight: bold;
            background-color: #ffeeef;
          }
        }
      }
      &--normal {
        .collapse {
          &__left {
            color: var(--primary-text);
            font-weight: 600;
            background-color: #e0f5f3;
          }
          &__header {
            color: var(--primary-text);
            font-weight: 600;
            background-color: #e0f5f3;
          }
        }
      }
      &.open {
        .collapse {
          &__header {
            height: 33px;
          }
          &__body {
            display: block;
          }
        }
      }
    }
    &__body {
      display: none;
      background-color: #fff;
      padding: 16px;
      &__list {
        @extend %row-between;
        &__title {
          font-weight: 600;
        }
        &__dots-line {
          flex: 1;
          border-top: 6px dotted var(--border1);
          margin: 0 12px;
        }
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 250px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .video-caption {
      position: absolute;
      max-width: 92%;
      padding: 5px 8px;
      color: #fff;
      font-weight: 600;
      bottom: 8px;
      left: 8px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      z-index: 10;
      @extend %truncate;
    }
  }

  .video-slider {
    flex-shrink: 0;
    width: 145px;
    background: #e0f5f3;
    border: 1px solid #dedede;
    border-radius: 4px;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &-wrapper {
      @extend %row-start;
      overflow-x: auto;
    }
    &__pic {
      position: relative;
      width: 100%;
      height: 85px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      figcaption {
        position: absolute;
        max-width: 80%;
        padding: 2px 15px;
        top: 0;
        left: 0;
        font-size: 10px;
        font-weight: 600;
        background-color: #fff;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        @extend %truncate;
      }
    }
    &__title {
      font-size: 12px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__date {
      color: #6b6b6b;
      font-size: 10px;
      margin-top: 8px;
    }
    &__content {
      padding: 8px;
    }
  }
  .nav-menu-wrapper {
    @media only screen and (max-width: 1300px) {
      display: none;
    }
  }
  .nav-title--default {
    @media only screen and (max-width: 1400px) {
      margin-right: 25px;
    }
  }
  .profile-avatar-wrapper {
    @media only screen and (max-width: 1300px) {
      margin-right: 50px;
    }
  }
  .profile-avatar-content {
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  &__child-details {
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .dashboard__search-bar {
    max-width: 320px;
    @media only screen and (max-width: 900px) {
      max-width: 250px;
    }
  }
}

.collapse-full {
  &-wrapper {
    border: 1px solid #dedede;
    border-radius: 4px;
  }
  &__list {
    .collapse-full {
      &__header {
        @extend %row;
        color: var(--primary-text);
        font-weight: 600;
        padding: 8px 16px;
        background-color: #f5f5f5;
        text-transform: uppercase;
      }
      &__body {
        padding: 24px 16px;
        background-color: #fff;
        .date-label {
          font-size: 14px;
          font-weight: 600;
          padding: 4px 7px;
          background: #ffeeef;
          border-radius: 2px;
          margin-right: 12px;
        }
        .label-view-video {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          padding: 4px 7px;
          background-color: var(--text-red);
          border-radius: 2px;
          cursor: pointer;
          @media only screen and (max-width: 1400px) {
            margin-top: 10px;
          }
        }
      }
    }
    &__alert {
      .collapse-full {
        &__header {
          color: var(--text-red);
          background: #ffeeef;
        }
        &__body {
          .date-label {
            color: var(--text-red);
          }
        }
      }
    }
  }
}

.table-trend {
  border: 1px solid #dedede;
  border-radius: 4px;
  &__container {
    width: 100%;
    @extend %row-start;
    flex-wrap: wrap;
  }
  &__list {
    width: 100%;
    @extend %row-start;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(222, 222, 222, 0.5);
    }
  }
  &__item {
    position: relative;
    width: 25%;
    height: 82px;
    @extend %row-center;
    &:not(:last-child) {
      border-right: 1px solid rgba(222, 222, 222, 0.5);
    }
  }
  &__content {
    @extend %row-center;
    width: 24px;
    height: 60px;
    border-radius: 100px;
    background-color: var(--primary-brand);
    &.no-data {
      display: none;
    }
    &--delayed {
      background-color: var(--text-red);
    }
    &--age-appropriate {
      background-color: #62cdc4;
    }
  }
  &__footer {
    background: #f5f5f5;
    padding: 8px;
  }
  &__keys {
    @extend %row-center;
    padding: 8px 20px;
    background: #f5f5f5;
    &__list {
      @extend %row;
      &:not(:last-child) {
        margin-right: 32px;
      }
      &__icon {
        @extend %row-center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        background-color: var(--primary-brand);
        &--advanced {
          background-color: var(--primary-brand);
        }
        &--age-appropriate {
          background-color: #62cdc4;
        }
        &--delayed {
          background-color: var(--text-red);
        }
      }
      &__title {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }
}

.vertical-divider {
  width: 1px;
  height: 18px;
  background: var(--primary-text);
}
.card-child-details {
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 300ms;
  &__avatar-container {
    flex-shrink: 0;
    width: 114px;
    height: 114px;
    box-shadow: 0px 6px 30px -5px rgba(11, 89, 114, 0.25);
    outline: 4px solid #fff;
  }
  &__action-icons {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &.anim-arrow {
      transform: rotate(180deg);
      transition: transform 300ms;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 24px;
  }
  &__footer {
    &__col {
      @media only screen and (max-width: 1200px) {
        padding-right: 30px;
      }
      @media only screen and (max-width: 900px) {
        padding-right: 12px;
      }
    }
  }
}
