.react-datepicker__input-container input {
  @include mdInput;
  margin-top: 0px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__header__dropdown select {
  color: #000;
  font-weight: normal;
  font-size: 0.944rem;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: rgb(248, 248, 248);
  cursor: default;
  margin: 0em;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #cecece;
  border-image: initial;
}

.react-datepicker__day--outside-month {
  color: #f0f0f0 !important;
}
