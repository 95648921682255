@import "styles/main";
@import "styles/styles.scss";

body {
  @extend %primary-font;
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
