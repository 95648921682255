@import "../base/placeholder.scss";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;
  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    transition: all 0.3s;
  }
  &__container {
    position: relative;
    width: 690px;
    max-height: 92vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transform: scale(2);
    transition: all 0.3s;
    z-index: 30;
  }
  &__close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    .modal__container {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  &--no-transform {
    .modal__container {
      transform: initial !important;
    }
  }
  &--slide-right {
    justify-content: flex-end;
    .modal {
      &__container {
        width: 660px;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
      }
    }
  }
  &.open {
    opacity: 1;
    visibility: visible;
    .modal__container {
      opacity: 1;
      visibility: visible;
    }
  }

  &--forgot-password {
    width: 545px;
    margin: auto;
    .modal {
      &__container {
        padding: 40px 24px;
      }
    }
  }
  &--create-pin {
    width: 590px;
    margin: auto;
    .modal {
      &__container {
        padding: 40px 45px;
        .input-field--password {
          font-size: 24px;
        }
      }
    }
    .pwd-code-container {
      position: relative;
      width: 265px;
      height: 48px;
      margin: auto;
    }
  }
  &--update-password {
    width: 590px;
    margin: auto;
    .modal {
      &__container {
        padding: 40px 45px;
      }
    }
  }
  &--add-patient {
    .modal {
      &__container {
        transform: scale(1) translateX(100%);
        .form-group-error {
          font-size: 14px;
        }
      }
      &__header {
        background: #effdff;
        padding: 36px 22px 36px 56px;
      }
      &__body {
        padding: 40px 56px;
        .input-wrapper {
          margin-bottom: 24px;
        }
        .input-label {
          margin-bottom: 4px;
        }
      }
    }
    .upload-profile {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 112px;
      height: 112px;
      background-color: #ededed;
      border-radius: 100%;
      overflow: hidden;
      cursor: pointer;
      &__input {
        display: none;
      }
    }
    &.show {
      .modal__container {
        transform: scale(1) translateX(0%);
      }
    }
  }
  &--delete-confirmation {
    .modal__container {
      width: 545px;
    }
  }
  &--swyc {
    .modal {
      &__container {
        width: 865px;
        transform: scale(1) translateX(100%);
      }
      &__header {
        padding: 40px 32px 0 32px;
      }
      &__title {
        color: var(--text-tertiary);
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border2);
      }
      &__close-icon {
        top: 40px;
      }
      &__body {
        padding-bottom: 110px;
        .col-title {
          color: var(--text-tertiary);
          font-weight: 600;
          text-transform: uppercase;
        }
        .dev-milestone-progress-bar {
          padding: 60px 16px 18px 16px;
          border: 1px solid var(--border2);
        }
        .question-keys {
          padding: 16px 16px;
          background-color: #f8f8f8;
          &__list {
            @extend %row;
            margin-left: 56px;
          }
          &__box {
            width: 24px;
            height: 24px;
            background-color: #f19da1;
            &.error {
              background-color: #f19da1;
            }
            &.warning {
              background-color: #f4dd7c;
            }
            &.success {
              background-color: #62cdc4;
            }
          }
          &__title {
            color: var(--tertiary-text);
            font-size: 14px;
            margin-left: 8px;
          }
        }
        .swyc-question-list {
          color: var(--tertiary-text);
          font-weight: 400;
          margin-bottom: 20px;
          + .split-progress-bar-container {
            padding: 0 16px;
          }
        }
      }
    }
    .btn--next-bpsc {
      font-weight: bold;
      padding: 12px 30px;
      background: rgba(246, 101, 109, 0.8);
      border-radius: 8px;
    }
  }
  &--milestone-details {
    .modal {
      &__container {
        width: 700px;
        transform: scale(1) translateX(100%);
      }
      &__close-icon {
        top: 40px;
      }
      &__body {
        .milestone-list {
          background: #ffffff;
          border: 1px solid #dedede;
          border-radius: 4px;
          &__header {
            padding: 8px 16px;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            background-color: #84cdd6;
          }
          &__body {
            padding: 16px;
          }
          &__item {
            @extend %row-between;
            &:not(:first-child) {
              margin-top: 18px;
            }
          }
          &__title {
            font-weight: 600;
          }
          &:not(:first-child) {
            margin-top: 20px;
          }
        }
        .dots-line {
          flex: 1;
          border-top: 6px dotted #dedede;
          margin: 0 16px;
        }
      }
    }
  }
  &--milestone-details,
  &--view-video {
    .modal {
      &__body {
        .video-container {
          width: 100%;
          height: 355px;
          border-radius: 8px;
          overflow: hidden;
          .video-react.video-react-fluid,
          .video-react {
            height: 355px;
            padding-top: 0 !important;
          }
          .video-react .video-react-video {
            height: 355px;
          }
        }
      }
    }
  }
  &--view-video {
    .modal {
      &__container {
        transform: scale(1) translateX(100%);
      }
    }
    &.show {
      .modal__container {
        transform: scale(1) translateX(0%);
      }
    }
  }
}
