@import "./variables.scss";
.section-wrapper {
  margin: 0px calc(6% + 20px) 20px;
  width: 100%;
  div.main {
    background-color: $bg-white;
    margin-bottom: 20px;
  }
  .border-bottom-gray {
    border-bottom: 1px solid $border-light-gray;
  }
  .title-main {
    padding: 0.6rem 0.5rem 0.5rem;
    span {
      &:first-child {
        position: relative;
        top: 2px;
      }
      vertical-align: middle;
      padding-right: 0.5rem;
      &.title {
        color: $primary-text-color;
        font-size: $font-16;
        font-weight: $fw-600;
      }
      &.edit-icon {
        cursor: pointer;
      }
    }
  }
  .inner-details {
    ul {
      li {
        margin-bottom: 0.5rem;
        &:first-child {
          color: $secondary-text-color;
          font-size: $font-16;
          font-weight: $fw-600;
        }
        &:last-child {
          color: $primary-text-color;
          font-size: $font-14;
        }
      }
    }
  }
  .table-body {
    padding: 1rem;
    .table-settings {
      width: 100%;
      min-width: 1000px;
      th {
        padding: 12px;
      }
      thead {
        tr {
          border: none;
        }
        th {
          background: #f5f5f5;
          border-collapse: separate;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.186667px;
          color: #777777;
          svg {
            margin-left: 5px;
          }
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $border-light-gray;
        }
        td {
          font-size: $font-14;
          line-height: 20px;
          letter-spacing: 0.213333px;
          color: $dark-gray;
          padding: 13px 10px;
          &:last-child {
            text-align: center;
          }
        }
        a {
          text-decoration: none;
          color: #525252;
          &:hover {
            text-decoration: none;
          }
        }
      }
      th,
      td {
        &:first-child {
          width: 40px;
        }
        &:nth-child(2) {
          width: 160px;
        }
        &:nth-child(3) {
          width: 160px;
        }
        &:nth-child(4) {
          width: 250px;
        }
        &:nth-child(5) {
          width: 120px;
        }
        &:nth-child(6) {
          width: 120px;
        }
        &:nth-child(7) {
          width: 130px;
        }
      }
    }
    .table-form-containing {
      &.no-border {
        tr {
          border-bottom: 0;
        }
      }
      tbody {
        tr {
          td {
            .form-group {
              margin-bottom: 0;
              .custom-select {
                height: calc(1.5em + 0.75rem + 11px) !important;
                background-size: $font-16;
                max-width: 150px;
                padding-right: 33px;
                transition: none !important;
              }
              input,
              select {
                background-color: $gray-color1;
                border-radius: 0;
                border-color: $border-light-gray;
                font-size: $font-14;
                color: $primary-text-color;
                &:focus {
                  background-color: $bg-white;
                }
                &:disabled {
                  &:focus {
                    background-color: $gray-color1;
                  }
                }
              }
              button.btn {
                font-size: $font-18;
                font-weight: normal;
                padding: 4px 15px;
              }
            }
          }
        }
      }
      &.view {
        input,
        select {
          pointer-events: none;
          background: #fff !important;
          border-color: transparent !important;
        }
        .multi-select {
          pointer-events: none;
          .dropdown-container {
            background: transparent;
            border-radius: 0;
            border-color: transparent;
            svg {
              height: 0;
            }
          }
        }
      }
      &.edit {
        .form-group .form-group-error {
          margin-top: -2px;
        }
        tbody {
          tr {
            td {
              padding: 15px 12px 18px;
            }
          }
        }
      }
    }
  }

  .setting-checkbox {
    input {
      &:checked + i {
        background-color: #bde7ec !important;
      }
      + i {
        background-color: $border-light-gray !important;
      }
    }
  }
  .classroom-section {
    span.d-inline-block.edit-icon {
      vertical-align: middle;
      margin-left: 20px;
      margin-top: 2px;
    }
    table {
      @include placeholder-font14;
      &.table-settings {
        table {
          min-width: 780px;
        }
        select.custom-select {
          max-width: 100% !important;
        }
        tbody {
          tr {
            td {
              &:nth-child(2) {
                font-weight: $fw-600;
              }
            }
          }
        }
      }
    }
  }
  .submit-row {
    &:focus {
      outline: none;
    }
  }
}

.multi-select {
  position: relative;
  max-width: 230px;
  --rmsc-h: 44px;
  --rmsc-selected: $border-light-gray !important;
  .dropdown-container {
    background-color: $gray-color1;
    border-radius: 0;
    border-color: $border-light-gray;
    font-size: $font-14;
    color: $primary-text-color;
    &:focus {
      background-color: $bg-white;
      outline: none;
    }
    .dropdown-content {
      max-height: 450px;
      overflow-y: auto;
      overflow-x: hidden;
      border-color: #dedede !important;
      line-height: 22px;
      padding-top: 5px;
      -webkit-box-shadow: 1px 3px 9px 0px rgba(222, 222, 222, 1);
      -moz-box-shadow: 1px 3px 9px 0px rgba(222, 222, 222, 1);
      box-shadow: 1px 3px 9px 0px rgba(222, 222, 222, 1);
      position: static;
      .select-panel {
        ul {
          li {
            border-bottom: 1px solid #dedede;
            label {
              margin-bottom: 0;
            }
          }
        }
      }
      .panel-content {
        max-height: 145px;
      }
    }
  }
}
.main-daycare {
  .section-container {
    margin-bottom: 30px;
  }
  .custom-error {
    position: absolute;
  }
  .dropdown-heading {
    height: auto !important;
    min-height: 42px;
    .dropdown-heading-value {
      text-overflow: unset !important;
      white-space: normal !important;
      word-break: break-word;
    }
  }
}
.tooltip {
  pointer-events: none;
}
.tab-content {
  margin: 0px calc(6% + 20px) 0px;
  width: 100%;
  ul {
    li {
      padding: 10px 20px 10px 0px;
      display: inline-block;
      a {
        color: $primary-text-color;
        font-weight: $fw-600;
        font-size: $font-16;
        padding-left: 15px;
      }
      &.active {
        background-color: $bg-white;
        border-bottom: 1px solid $secondary-text-color;
        a {
          color: $secondary-text-color;
        }
      }
    }
  }
}
.assign-student-list {
  table {
    th,
    td {
      width: 22% !important;
      position: relative;
      &:first-child {
        width: 34% !important;
        div.form-group {
          padding-left: 35px !important;
          font-weight: $fw-600;
        }
      }
    }
    th {
      padding-left: 25px !important;
    }
  }
  input {
    &:read-only {
      &:focus {
        background-color: $gray-color1 !important;
      }
    }
  }
}
.section-wrapper {
  .institution-section {
    .table-body {
      .table-settings {
        thead {
          th {
            padding: 12px 18px !important;
          }
        }
        th,
        td {
          &:first-child {
            width: 40px;
          }
          &:nth-child(2) {
            width: 130px;
          }
          &:nth-child(3) {
            width: 130px;
          }
          &:nth-child(4) {
            width: 240px;
          }
          &:nth-child(5) {
            width: 170px;
          }
          &:nth-child(6) {
            width: 110px;
          }
          &:nth-child(7) {
            width: 190px;
          }
          .form-group {
            .custom-select {
              max-width: 165px;
            }
          }
        }
      }
    }
  }
}
.hideToggle {
  display: none !important;
}

.delete-child-popup-text {
  color: #707575;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}
