.swyc-questions-container {
  button.btn.btn--Cancel:focus {
    outline: 0;
    box-shadow: none;
  }
  .swyc-progress {
    color: #f6656d;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    padding: 10px 32px;
    font-weight: 600;
  }
  .swyc-section-description {
    color: #9ca2a2;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    padding-left: 12px;
    margin-bottom: 32px;
  }
  .question-resp-div {
    display: flex;
    margin: 0.575rem 0px 3vh 0px;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    .swyc-response-btn {
      position: relative;
      margin: 5px;
      .far {
        margin-right: 10px;
        font-size: 15px;
        margin-left: 5px;
      }
      .fa-check-square {
        color: #f6656d;
      }
      .fa-dot-circle {
        color: #f6656d;
      }
    }
    .response-buttons-div {
      .btn-assessment {
        padding: 0px;
        display: flex;
        align-items: center;
        font-weight: 600;
        min-height: 36px;
        height: auto;
        overflow-wrap: break-word;
        background-color: transparent;
        box-shadow: none;
        p {
          max-width: 75%;
          font-weight: 600;
          word-break: break-word;
          margin-right: 15px;
        }
      }
      .btn--active {
        color: #787272;
        background-color: #eaeaea;
        box-shadow: none;
      }
    }
  }
  .action-btn-footer {
    display: flex;
    justify-content: space-between;
    .btn.btn--Cancel {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .cancel-btn-footer {
    display: flex;
    justify-content: center;
    text-align: center;
    .btn.btn--Cancel {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .swycSectionHeader {
    padding: 0px 12px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  .multiselect-label {
    margin-top: 10px;
    margin-left: 12px;
    height: 19px;
    width: 179px;
    color: #707575;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
  .question-text {
    padding: 0px 12px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  textarea {
    color: #000;
  }
  & .cancel-btn {
    color: #787272;
    font-weight: 600;
    margin-left: 22%;
    font-size: 16px;
  }
  & .required-container {
    background: rgba(238, 100, 109, 0.11);
    padding-top: 10px;
  }
  & .required-text {
    padding: 0 20px 15px 20px;
    color: #f6656d;
    margin-bottom: 20px;
  }
}
