.progress-bar {
  &__container {
    position: relative;
    width: 100%;
    height: 10px;
    background: #ededed;
    border-radius: 100px;
  }
}

.progress{
    content: "";
    position: absolute;
    width: 80%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #84cdd6;
    border-radius: 100px;
  }
