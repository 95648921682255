@import "../base/placeholder.scss";

.patient-milestone {
  &__overdue {
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid var(--text-red);
    .patient-milestone__table {
      thead {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 2;
        tr {
          td {
            padding: 32px 12px 16px;
          }
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 24px;
      background-color: #ffeeef;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &__body {
      max-height: 390px;
      padding: 0 12px 16px;
      overflow: auto;
    }
  }
  &__table {
    width: 100%;
    thead {
      tr {
        td {
          padding: 16px 12px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 8px 12px;
          &:first-child {
            width: 50%;
          }
          &:last-child {
            width: 20%;
          }
          .milestone-overdue-title {
            letter-spacing: 0.213px;
            margin-left: 12px;
          }
          .milestone-title {
            letter-spacing: 0.213px;
            cursor: pointer;
            margin-left: 12px;
            &:hover {
              font-weight: 700;
              text-decoration: underline;
            }
          }
        }
      }
    }
    &--itemized {
      thead {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 2;
        tr {
          td {
            padding: 32px 12px 16px;
          }
        }
      }
      tbody {
        td {
          padding: 8px 12px !important;
          &:last-child {
            width: initial !important;
          }
          .no-data,
          .data-warning,
          .data-normal {
            min-height: 24px;
            max-height: 24px;
            padding: 2px 8px;
            text-align: center;
          }
          .no-data {
            background-color: #ffeeef;
          }
          .data-warning {
            background-color: rgba(244, 221, 124, 0.5);
            text-align: center;
          }
          .data-normal {
            background-color: #e0f5f3;
          }
        }
      }
    }
  }
  &__itemized {
    background-color: #fff;
    border-radius: 4px;
  }
  &__count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 4px;
    @media only screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
    &__content-left {
      max-width: 500px;
      @media only screen and (max-width: 1200px) {
        max-width: 75%;
      }
      @media only screen and (max-width: 900px) {
        max-width: 100%;
      }
    }
    &__progress-bar {
      @media only screen and (max-width: 1200px) {
        margin-top: 24px;
      }
    }
  }
  .h-scroll-container {
    min-width: 1200px;
  }
}
