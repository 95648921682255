.mchat-questions-container {
  .question-resp-div {
    display: flex;
    margin: 0.575rem 0px 3vh 0px;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    .mchat-response-btn {
      position: relative;
      margin: 5px 0;
      .far {
        margin-right: 10px;
        font-size: 15px;
        margin-left: 5px;
      }
    }
    .response-buttons-div {
      .btn-assessment {
        padding: 0px !important;
        display: flex;
        align-items: center;
        font-weight: 600;
        min-height: 36px;
        height: auto;
        overflow-wrap: break-word;
        background-color: transparent;
        box-shadow: none;
        &.btn--active {
          color: #787272;
          box-shadow: none;
          .fa-dot-circle {
            color: #f6656d;
          }
        }
        p {
          max-width: 75%;
          font-weight: 600;
          word-break: break-word;
          margin-right: 15px;
          color: #787272;
        }
        .far {
          font-size: 25px;
        }
        &.disabled {
          cursor: default;
        }
      }
    }
  }
}

.mchat-sub-ques-contaier {
  &.clickable {
    .btn-assessment {
      .fa-square {
        color: #25858f;
        // font-size: 18px;
      }
    }
  }
  .response-buttons-div {
    padding: 8px 0 0;
  }
  .btn-assessment {
    i {
      font-size: 15px;
      vertical-align: middle;
      position: relative;
      top: 1px;
    }
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    padding: 0 !important;
    &.far {
      padding: 0 !important;
    }
    &.btn--active {
      color: #787272;
      box-shadow: none;
      .far.fa-check-square {
        color: #f6656d;
        // font-size: 18px;
      }
      i.fas.fa-check-square,
      i.far.fa-square {
        color: #9ca2a2;
      }
    }
    p {
      padding-left: 10px;
    }
  }
  .question-heading {
    color: #25858f;
    font-weight: 500;
  }
}
