@import "../base/placeholder.scss";

.support {
  @extend %row-start;
  background-color: #f8f8f8;
  min-height: 100vh;
  &__main {
    @extend %row-start;
    flex: 1;
    padding: 38px 48px;
    .back-btn {
      position: relative;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      &__text {
        color: var(--primary-brand);
        font-weight: 600;
        margin-left: 12px;
      }
    }
    .nav-title {
      font-size: 20px;
    }
  }
  &__file-upload {
    &-input {
      display: none;
    }
    &-label {
      @extend %flex-col-center;
      padding: 33px;
      background: #fff;
      border: 1px dashed var(--border1);
      border-radius: 4px;
      cursor: pointer;
    }
    &-details {
      @extend %row-start;
      padding: 20px 12px 10px;
      background-color: #fff;
    }
  }
  .check-icon {
    position: absolute;
    right: -38px;
    top: 12px;
  }
  &__container {
    width: 580px;
  }
  .aside-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__banner {
    margin: 120px auto 0;
    @media only screen and (max-width: 1200px) {
      padding-left: 80px;
    }
    @media only screen and (max-width: 980px) {
      display: none;
    }
  }
}
