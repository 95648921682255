.checklist-checkbox {
  min-height: 24px !important;
  min-width: 25.5px !important;
  align-self: baseline;
  margin-top: 5px;
}

.checklist-title {
  color: #707575;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.week {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
  text-align: center;
  height: 70px;
}
.not-disabled {
  color: $theme-color-green;
}
.week i {
  font-size: 24px;
  line-height: 28px;
}
