.journal-head h2 {
  margin-top: -20px;
}
.calendar {
  position: absolute;
  top: 12px;
  font-size: 20px;
  right: 40px;
}
.journal-select {
  width: 80%;
}
.example {
  font-style: Italic;
}
textarea {
  box-sizing: border-box;
  height: 125px;
  width: 100%;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
  color: #9ca2a2;
  font-size: 16px;
}
.card-box {
  width: 100%;
  border-radius: 10px;
  // background-color: #b6dde1;
}
.card-box1 {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #f6656d;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
}
.journal-title {
  color: $theme-color-green;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.journal-date {
  width: 90% !important;
  cursor: pointer;
  .journal-calendar {
    .react-datepicker__current-month {
      display: unset;
    }
  }
  .react-datepicker__day--highlighted {
    background-color: #f6656d;
    border-radius: 50%;
    color: black;
  }
  .react-datepicker__day--highlighted:hover {
    background-color: #f6656d;
    border-radius: 50%;
    color: black;
  }
  .react-datepicker__day--highlighted:focus {
    outline: none;
  }
  .react-datepicker__day--selected {
    background-color: #f6656d;
    border-radius: 50%;
    color: #ffffff;
  }
  .react-datepicker__day--selected:hover {
    background-color: #f6656d;
    border-radius: 50%;
    color: #ffffff;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day:focus {
    outline: none;
  }
}
.behaviour-image {
  display: block;
  min-height: 100px;
}
// .behaviour-image img {
//   width: 222px;
// }
.filter {
  color: #6dbac2;
  font-size: 24px;
  line-height: 28px;
}
.plusc {
  color: #95d9e0;
  font-size: 43px;
  margin-top: -10px;
}
.yesterday {
  border-bottom: 1px solid #6dbac2;
}
.behaviour-info i {
  color: $theme-color-green;
  font-size: 24px;
  line-height: 28px;
}
.behaviour-info img {
  width: 24px;
  align-self: baseline;
}
.older {
  border-bottom: 1px solid #6dbac2;
}
.behaviour-wrapper {
  margin: 0 -20px;
  padding: 0 20px 30px 20px;
  position: relative;
}
.behaviour-wrapper:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.behaviour-wrapper .bg-curve {
  width: 100%;
  position: absolute;
  left: 0;
  top: -95px;
  z-index: 1;
}
.behaviour-wrapper .behaviour-content {
  position: relative;
  z-index: 2;
  padding: 15px 10px;
}

@media screen and (min-width: 600px) {
  .behaviour-wrapper .behaviour-content {
    padding-top: 20px;
  }
  .behaviour-wrapper .bg-curve {
    top: -105px;
  }
}
@media screen and (max-width: 350px) {
  .behaviour-wrapper .behaviour-content {
    padding: 0 10px;
  }
}
@media screen and (min-width: 1200px) {
  .behaviour-wrapper .bg-curve {
    // top: -105px;
    top: -115px;
  }
  .behaviour-wrapper {
    padding: 30px;
  }
}

.detailed-sub {
  color: #383b3b;
  font-size: 14px;
  line-height: 19px;
}
.detailed-edit i {
  color: #6dbac2;
  font-size: 24px;
  line-height: 28px;
}

// --------------------------------------->> Video Journal <<---------------------------------------

.video-element img {
  width: 29px;
  height: 23px;
}
.video-element i {
  color: #519fa7;
  font-size: 27px;
}
.social-type {
  border-bottom: 1px solid #6dbac2;
}

.video-title {
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 19px;
  font-weight: normal;
  line-height: 23px;
}
.video-folder {
  box-sizing: border-box;
  height: 135px;
  width: 235px;
  border: 1px dashed #f6656d;
  border-radius: 10px;
  background-color: #f0f0f0;
}
.video-folder label {
  cursor: pointer;
  position: absolute;
  left: 25%;
  top: 38%;
}
#browse-video {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.video-folder {
  position: relative;
}
.video-button {
  width: 95%;
}
.tick {
  font-size: 30px;
  padding-top: 44px;
  padding-left: 20px;
}

.video-preview-by {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  color: $theme-color-green;
  span {
    font-weight: bold;
  }
}

.child-mood-div {
  .child-mood-outer {
    background: #b6dde1;
    border-radius: 50%;
    padding: 2px;
    border: 1.7px solid transparent;
  }

  .active {
    border: 1.7px solid #f6656d;
    background-color: #ffffff;
    box-shadow: 0 2px 9px 0 rgba(200, 200, 200, 0.8);
  }
}

.upload-text {
  color: #f6656d;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  img {
    vertical-align: middle;
  }
}

.teal {
  color: $theme-color-green;
}
.upload-text-2 {
  margin-top: -10px;
  // word-break: break-all;
}

.milestone-header {
  height: 240px;
  .milestone-header-info {
    .header-text {
      color: #707575;
      font-size: 14px;
      font-style: italic;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
    .completed-milestone {
      color: $theme-color-green;
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
    }
  }
}

.milestone-section {
  min-height: 70px;
  width: 100%;
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}

// --------------------------------Journal Tabs---------------------------------

.journal-new-menu {
  margin: 0 -20px;
  width: calc(100% + 40px);
  margin-top: 90px;
  display: flex;
  flex-direction: column;
}
.journal-new-menu .menu-wrapper {
  position: relative;
}
.menu-bg {
  width: 100%;
  position: absolute;
  top: -190px;
}
.journal-new-menu .milestones-wrapper {
  background: #e2f1f3;
  // order: 0;
}
.journal-new-menu .timelines-wrapper {
  background: #d7feec;
  // order: 1;
  // z-index: 1;
  /* background-image: linear-gradient(180deg, #ffffff 0%, #a2f5ce 100%); */
}
.journal-new-menu .menu-title {
  position: relative;
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 22px;
  line-height: 27px;
  padding: 30px 20px 20px;
  padding-left: 55px;
  z-index: 2;
}
.journal-new-menu .menu-content-wrapper {
  min-height: 70px;
  position: relative;
  top: -110px;
}

.milestone-card {
  .milestone-menu {
    align-items: center;
    &:not(:first-child) {
      margin-top: 20px;
    }
    .left {
      width: 10%;
    }
    .right {
      width: 90%;
    }
    .milestone-name {
      color: $theme-color-green;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      border-bottom: 1px solid #6dbac245;
      // opacity: 0.55;
    }
  }
  .behaviour-name {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: 2px;
  }
  .unchecked {
    box-sizing: border-box;
    min-height: 18px;
    min-width: 18px;
    border: 0.81px solid #bfbebe;
    border-radius: 3.24px;
    align-self: baseline;
    display: inline-block;
    margin-top: 5px;
  }
  .red {
    color: #f6656d;
  }
}
.behaviour-icon {
  font-size: 21px;
  color: $theme-color-green;
  display: inline-block;
  align-self: baseline;
  margin-left: auto;
  cursor: pointer;
}

.journal-header-text {
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.milestone-info-text {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 13px;
}

@media screen and (max-width: 350px) {
  .journal-new-menu .menu-content-wrapper {
    top: -118px;
  }
}
@media screen and (min-width: 400px) {
  .journal-new-menu .menu-content-wrapper {
    top: -105px;
  }
}
@media screen and (min-width: 450px) {
  .journal-new-menu .menu-content-wrapper {
    top: -93px;
  }
}
@media screen and (min-width: 500px) {
  .journal-new-menu .menu-content-wrapper {
    top: -80px;
  }
}
@media screen and (min-width: 550px) {
  .journal-new-menu .menu-content-wrapper {
    top: -75px;
  }
}
@media screen and (min-width: 575px) {
  .journal-new-menu .menu-content-wrapper {
    top: -95px;
  }
}
@media screen and (min-width: 1200px) {
  .menu-bg {
    top: -210px;
  }
}

.menu-title-2 {
  position: relative;
  color: $theme-color-green;
  font-family: "transcendregular";
  font-size: 22px;
  line-height: 27px;
  padding: 30px 30px 20px;
  z-index: 2;
}

.title-main {
  font-size: 16px !important;
}

.age-essential-tooltip .tooltip-inner {
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
  color: #777777;
  padding: 10px;
}

// ---------------- TIMELINE NEW

.date-header {
  color: #707575;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.separator-green {
  height: 1px;
  width: 80%;
  margin: auto;
  background: rgba(109, 186, 194, 0.5);
}

.filter-div-outer {
  height: 43px;
  border: 1px solid #bfbebe;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  .filter-div-inner {
    padding: 10px 15px;
    .filter-text {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      color: $theme-color-green;
    }
  }
  .filter-expanded {
    min-height: 150px;
    position: absolute;
    left: 0%;
    right: 0;
    top: 43px;
    background: #ffffff;
    z-index: 5;
    box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .filter-icon {
      min-height: 24px;
      min-width: 24px;
      border: 0.81px solid #bfbebe;
      border-radius: 3.24px;
      img {
        top: -6px;
        left: -1px;
        width: 26px;
      }
    }
    .checkbox-text {
      color: $theme-color-green;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      text-decoration: none;
    }
    .to {
      color: #9ca2a2;
      font-family: "Open Sans";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin-left: 10px;
    }
    .villager-name-active {
      color: #6dbac2;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-decoration: underline;
    }
    .villager-name-inactive {
      color: #707575;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
}

.filter-div-expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-open {
  z-index: 0 !important;
}
.list-icon {
  color: $theme-color-green;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  vertical-align: top;
}

// .timeline-graphical-div {
//   text-align: center;
//   .timeline {
//     border-left: 3px dashed rgba(37, 133, 143, 0.5);
//     border-bottom-right-radius: 4px;
//     border-top-right-radius: 4px;
//     color: $theme-color-green;
//     margin-left: 45px;
//     position: relative;
//     list-style: none;
//     text-align: left;
//     padding: 20px 20px 10px 35px;

//     .green {
//       color: $theme-color-green !important;
//     }
//     .purple {
//       color: #c26d9c !important;
//     }
//     .gray {
//       color: #707575 !important;
//     }

//     .bold-font {
//       font-weight: bold;
//     }

//     .graphical-info {
//       color: #707575;
//       font-size: 12px;
//       letter-spacing: 0;
//       line-height: 17px;
//     }

//     h1 {
//       letter-spacing: 1.5px;
//       font-weight: 100;
//       font-size: 1.4em;
//     }

//     h2,
//     h3 {
//       letter-spacing: 1.5px;
//       font-weight: 400;
//       font-size: 1.4em;
//     }

//     .stacked {
//       height: initial;
//     }
//     .timeline-item {
//       height: 100%;
//     }
//     .items-wrapper {
//       position: relative;
//       left: 127px;
//       background: #ffffff;
//       display: inline-block;
//       border-radius: 0 10px 10px 0;
//       .items {
//         &:not(:last-child) {
//           margin-bottom: 20px;
//         }
//         img {
//           width: 121px;
//           height: 77px;
//           border-radius: 7px;
//         }
//       }
//       &::after {
//         content: "";
//         position: absolute;
//         width: 100px;
//         height: 100%;
//         z-index: -1;
//         width: 0;
//         height: 0;
//         border-style: solid;
//         border-color: transparent #f0fdf7 transparent transparent;
//         top: -28px;
//         left: -179px;
//         border-width: 41px 187px 40px 0;
//         transform: rotate(16deg);
//       }
//     }
//     .stacked {
//       &.timeline-stacked-div {
//         .items-wrapper {
//           height: 104px;
//           margin-top: 20px;
//           .items {
//             position: absolute;
//             top: 0;
//             &:nth-child(1) {
//               transform: rotate(5deg);
//               transform-origin: top left;
//               z-index: 2;
//             }
//             &:nth-child(2) {
//               z-index: 1;
//             }
//             &:nth-child(3) {
//               transform: rotate(-5deg);
//               transform-origin: top left;
//             }
//           }
//         }
//       }
//     }
//     .timeline-item {
//       position: relative;
//     }

//     .event {
//       padding-bottom: 20px;
//       position: relative;

//       &:last-of-type {
//         padding-bottom: 0;
//         margin-bottom: 0;
//         border: none;
//       }

//       &:before,
//       .line-circle {
//         position: absolute;
//         display: block;
//         top: 0;
//       }

//       &:before {
//         left: -85px;
//         content: attr(data-date);
//         color: $theme-color-green;
//         font-size: 16px;
//         font-weight: 600;
//         letter-spacing: 0;
//         line-height: 22px;
//         max-width: 40px;
//       }

//       .line-circle {
//         background-color: #f6656d;
//         border-radius: 50%;
//         height: 11px;
//         width: 11px;
//         content: "";
//         z-index: 2;
//         margin: 7px;
//         top: -2px;
//         left: -49px;
//       }

//       span {
//         left: -50px;
//         border-radius: 50%;
//         top: -3px;
//         height: 27px;
//         width: 27px;
//         border: 2px solid #f6656d;
//         background-color: #d7fcec !important;
//         position: absolute;
//       }
//       &.event-multiple {
//         span {
//           top: 2px;
//         }
//         .line-circle {
//           top: 3px;
//         }
//       }
//       &.overview {
//         &::before {
//           left: -70px;
//           top: 30px;
//         }
//         span {
//           left: -35px;
//           top: 35px;
//         }
//         .line-circle {
//           top: 36px;
//           left: -34px;
//         }
//       }
//       &.overview-end {
//         .line-circle {
//           height: 15.4px;
//           width: 15.4px;
//           opacity: 0.5;
//           background-color: $theme-color-green;
//           top: -3px;
//           left: -36px;
//         }
//         span {
//           border: 2.2px solid $theme-color-green;
//           opacity: 0.5;
//           left: -35px;
//           top: -2px;
//         }
//       }
//     }
//     .behaviour-details-container {
//       height: 77px;
//       &:nth-child(2) {
//         .left-container {
//           &::after {
//             content: "";
//             display: inline-block;
//             width: calc(50% + 20px);
//             height: 1px;
//             background-color: $theme-color-green;
//             z-index: 0;
//             position: absolute;
//             left: -20px;
//             top: -10px;
//           }
//         }
//       }
//       &:last-child {
//         .left-container {
//           &::before {
//             height: 25% !important;
//             top: -12px;
//           }
//         }
//       }
//     }
//     .left-container {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       position: relative;
//       &::before {
//         content: "";
//         display: inline-block;
//         height: calc(100% + 20px);
//         width: 1px;
//         background-color: $theme-color-green;
//         z-index: -1;
//         position: absolute;
//       }
//     }
//     .left-container2 {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//     }
//     .media-container {
//       width: 121px;
//       height: 77px;
//       border-radius: 7px;
//       box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 0.85);
//     }
//     .video {
//       background-color: black;
//       .video-outer {
//         box-sizing: border-box;
//         height: 28px;
//         width: 28px;
//         border: 1px solid #ffffff;
//         background-color: rgba(255, 255, 255, 0.4);
//         border-radius: 50%;
//       }
//       .play-button {
//         width: 0;
//         height: 0;
//         border-top: 5px solid transparent;
//         border-bottom: 5px solid transparent;
//         border-left: 6px solid rgba(0, 0, 0, 0.5);
//       }
//     }
//     .image {
//       background-color: #ffffff;
//       object-fit: scale-down;
//     }
//     .no-media {
//       border: 1px solid #f6656d;
//       background-color: #f0f0f0;
//       img {
//         height: 21px;
//       }
//       p {
//         color: #f6656d;
//         font-size: 10px;
//         font-weight: 600;
//         letter-spacing: 0;
//         line-height: 14px;
//       }
//     }
//     .behaviour-text {
//       color: $theme-color-green;
//       font-size: 12px;
//       font-weight: 600;
//       letter-spacing: 0;
//       line-height: 15px;
//       background: #d7fcec;
//       padding-top: 5px;
//       max-height: 51px !important;
//     }
//     .behaviour-img {
//       height: 28px;
//       background: #d7fcec;
//       padding-bottom: 5px;
//     }
//     .left-container-inner {
//       height: 77px !important;
//     }
//   }
// }

.graphical-view-filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 161px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(173, 172, 172, 0.5);
  z-index: 5;
  .filter-active {
    color: $theme-color-green !important;
    font-weight: 600;
    order: 1;
  }
  .filter-text {
    color: #707575;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
  }
  .filter-info {
    font-size: 14px !important;
    font-weight: 600;
  }
  .filter-popup-arrow {
    position: absolute;
    top: 0;
    right: 30%;
    padding: 10px 0px;
  }
}

.image-stack {
  height: 106px;
  img {
    width: 121px;
    height: 77px;
    border-radius: 7px;
  }
  .stack-wrapper {
    display: inline-block;
    border-radius: 0 10px 10px 0;
    position: relative;
  }
  .stack-item {
    position: absolute;
    top: 0;
    &:nth-child(1) {
      transform: rotate(5deg);
      transform-origin: top left;
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
    }
    &:nth-child(3) {
      transform: rotate(-5deg);
      transform-origin: top left;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.entries-milestone {
  color: #707575;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.entries-area {
  color: #707575;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.entries-milestone-red {
  color: #f6656d;
}

.birth-child {
  position: absolute;
  bottom: -54px;
  left: -26px;
}

// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------    Journal New    ------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------------

.journal-header {
  height: 26px;
  background-color: #c26d9c;
  color: #f0f0f0;
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #ffffff;
  }
  .week-filter {
    min-width: 45%;
    p {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
  .child-filter {
    .child-name {
      color: #ffffff !important;
      font-family: "transcendregular";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 2px;
    }
  }
  .filter-div {
    border-radius: 0 0 4px 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(173, 172, 172, 0.5);
    width: 189px;
    max-width: 189px;
    position: absolute;
    top: 26px;
    z-index: 9;
    .filter-item {
      color: #707575;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
    }
    .active-item {
      color: $theme-color-green !important;
    }
  }
  .left-filter {
    left: 0;
  }
  .right-filter {
    right: 0;
  }
}

.journal-tab {
  height: 40px;
  border-radius: 10px;
  background-color: #f0f0f0;
  .tab-item {
    width: 50%;
    text-align: center;
    color: #707575;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active-tab {
    border-radius: 10px;
    background-color: #6dbac2;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
  }
}
.milestone-card-title {
  p {
    font-family: "transcendregular";
    color: $theme-color-green;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 27px;
  }
  i {
    letter-spacing: 0;
    line-height: 18px;
    font-size: 18px;
    color: #9ca2a2;
    margin-top: -5px;
  }
  .info-active {
    color: $theme-color-green !important;
  }
}

.action-div {
  p {
    color: #9ca2a2;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
  i {
    color: #9ca2a2;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
  }
  .active {
    color: $theme-color-green !important;
  }
}

.journal-empty-state-container {
  .journal-empty-text-container {
    background-color: rgba(246, 101, 109, 0.2);
    p {
      color: #f6656d;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  img {
    display: flex;
    margin: auto;
    width: 70%;
    opacity: 0.8;
  }
}

.timeline {
  border-left: 3px dashed rgba(37, 133, 143, 0.5);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: 32.96px;
  position: relative;
  list-style: none;
  text-align: left;
  padding: 0px 0px 10px 23px;
  .ellipsis-4line {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    height: 70px;
  }
  .graphical-info {
    color: #707575;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
  }
  .green {
    color: $theme-color-green !important;
  }
  .purple {
    color: #c26d9c !important;
  }
  .gray {
    color: #707575 !important;
  }
  .bold-font {
    font-weight: bold;
  }

  .event-connected {
    &:before {
      content: "";
      width: 2px;
      background-color: rgba(37, 133, 143, 0.5);
      position: absolute;
      left: calc(121px / 2);
      height: calc(100% - 30px);
      top: 30px;
    }
  }
  .timeline-event {
    // padding-bottom: 25px;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    .date {
      color: #707575;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 11px;
      margin-bottom: 10px;
    }

    .single-entry {
      position: absolute;
      background-color: #c26d9c;
      border-radius: 50%;
      height: 11px;
      width: 11px;
      content: "";
      top: 0px;
      left: -30px;
    }
    .multiple-entry {
      height: 27px;
      width: 27px;
      padding: 5px;
      border: 2px solid #c26d9c;
      left: -38px;
      top: -8px;
      background-color: #ffffff;
      cursor: pointer;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
      &:before {
        border-radius: 50%;
        height: 11px;
        width: 11px;
        content: "";
        background-color: #c26d9c;
        display: inline-block;
        top: 6px;
        left: 6px;
        position: absolute;
      }
    }
    .expanded-entry {
      background-color: #ffffff;
      border-color: #6dbac2;
      box-shadow: none !important;
      &:before {
        background-color: #6dbac2;
      }
    }

    .entry-container {
      height: 77px;
      // width: 259px;
      display: flex;
      margin-bottom: 25px;
    }
    .behaviour-area-img {
      position: absolute;
      left: -38px;
      height: 26.71px;
      width: 26.71px;
      top: 26px;
    }
    .journal-edit {
      // position: absolute;
      // right: -31px;
      color: #9ca2a2;
      // top: -8px;
      // padding: 8px;
      cursor: pointer;
      // margin-left: auto;
    }
    .media-container {
      box-sizing: border-box;
      height: 77px;
      min-width: 121px;
      width: 121px;
      margin-right: 10px;
      .mood-icon {
        position: absolute;
        bottom: 5px;
        right: 5px;
        height: 20.25px;
        width: 21px;
      }
      .video-container-outer {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000000;
        height: 77px;
        border-radius: 7px;
        box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 0.85);
        z-index: 2;
      }
      .player-symbol {
        box-sizing: border-box;
        height: 28px;
        width: 28px;
        border: 1px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 7px solid rgba(0, 0, 0, 0.5);
        }
      }
      .entry-image {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 7px;
        box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 0.85);
        z-index: 2;
      }
      .no-media {
        height: 77px;
        border-radius: 7px;
        box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 0.85);
        border: 1px solid #f6656d;
        background-color: #f0f0f0;
        z-index: 2;
        img {
          height: 21px;
        }
        p {
          color: #9ca2a2;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 14px;
        }
      }
      .stack-no-media {
        border: none !important;
        p {
          color: #707575;
        }
      }
      .parent-entry-media {
        border: 3px solid #25858f;
      }
      .daycare-entry-media {
        border: 3px solid #c26d9c;
      }
      .villager-entry-media {
        border: 3px solid #707575;
      }
    }
    .entry-text {
      color: #707575;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 17px;
      margin-right: 20px;
    }

    .line-circle {
      background-color: #f6656d;
      border-radius: 50%;
      height: 11px;
      width: 11px;
      content: "";
      z-index: 2;
      margin: 7px;
      top: -2px;
      left: -49px;
    }

    span {
      left: -50px;
      border-radius: 50%;
      top: -3px;
      height: 27px;
      width: 27px;
      border: 2px solid #f6656d;
      background-color: #d7fcec !important;
      position: absolute;
    }
    &.event-multiple {
      span {
        top: 2px;
      }
      .line-circle {
        top: 3px;
      }
    }
    &.overview {
      &::before {
        left: -70px;
        top: 30px;
      }
      span {
        left: -35px;
        top: 35px;
      }
      .line-circle {
        top: 36px;
        left: -34px;
      }
    }
    &.overview-end {
      .line-circle {
        height: 15.4px;
        width: 15.4px;
        opacity: 0.5;
        background-color: $theme-color-green;
        top: -3px;
        left: -36px;
      }
      span {
        border: 2.2px solid $theme-color-green;
        opacity: 0.5;
        left: -35px;
        top: -2px;
      }
    }
  }
  .stack-wrapper {
    height: 106px;
    display: inline-block;
    position: relative;
    margin-top: 10px;
    margin-left: 7px;
  }
  .stack-item {
    position: absolute;
    top: 0px;
    &:nth-child(1) {
      transform: rotate(5deg);
      transform-origin: top left;
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
    }
    &:nth-child(3) {
      transform: rotate(-5deg);
      transform-origin: top left;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .stack-only-item {
    transform: rotate(0deg) !important;
  }
  .item-shadow {
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.5),
      0 2px 10px 0 rgba(200, 200, 200, 0.85) !important;
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #9ca2a2;
    cursor: pointer;
    margin-left: 5px;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #9ca2a2;
    margin-left: 5px;
  }
}

.popup-media-container {
  padding: 5px;
  overflow: auto;
  width: 100%;
  max-height: 80vh;
  border-radius: 10px;
  .media-container {
    max-height: 45vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      max-height: 45vh;
      border-radius: 10px;
    }
  }
  .media-text {
    color: #707575;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 19px;
  }
}
.action-item-div {
  p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 10px;
  }
  i {
    font-size: 16px;
  }
  .edit {
    color: $theme-color-green !important;
  }
  .delete {
    color: #f6656d !important;
  }
}

.upload-popup-div {
  height: 74px;
  width: 74px;
  border-radius: 7px;
  background-color: #e2f1f3;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  img {
    height: 40px;
  }
}

.activity-info {
  color: #707575;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 17px;
}
.uncheck-reason-modal {
  .modal-body {
    padding: 15px 10px;
    .inner-text {
      font-size: 14px;
      line-height: 22px;
    }
    .reason-text {
      font-size: 12px;
      font-style: italic;
      color: #9ca2a2;
    }
    textarea {
      color: #707575;
      font-size: 14px;
    }
  }
  .modal-popup-container-2 {
    max-height: initial;
    .body-overflow {
      max-height: 40vh;
      overflow-y: scroll;
      padding-right: 30px;
    }
  }
  .modal-btn-container {
    .btn--primary {
      min-width: 150px;
    }
    .btn-reason-cancel {
      color: #ee646d;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.uncheck-container {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
