.button1 {
  color: #707575;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  &:focus {
    outline: none;
  }
}
.notifications i {
  font-size: 21px;
}

.notifications img {
  width: 25px;
  height: 25px;
}
.notifications1 i {
  font-size: 21px;
  color: #f6656d;
}
.notifications1 img {
  width: 25px;
  height: 25px;
}
.notifications1 {
  background-color: #d0fae6;
  border-bottom: 1px solid #d0fae6;
}
.notifications {
  border-bottom: 1px solid #d0fae6;
}

.notifications,
.notifications1 {
  i.majenta {
    color: #c26d9c !important;
  }
  i.green {
    color: $theme-color-green !important;
  }
  i.red {
    color: #f6656d !important;
  }
  i.light-green {
    color: #6dbac2 !important;
  }
}

// ------------------------------>> Settings

.settings-header {
  border-bottom: 1px solid #6dbac28c;
  .title {
    color: #25858f;
    margin-bottom: 5px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    font-family: "transcendregular";
  }
  i {
    color: #9ca2a2;
    font-size: 21px;
    cursor: pointer;
  }
}
.setting-checkbox {
  cursor: pointer;
}
.setting-checkbox > input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.setting-checkbox > i {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  transition: 0.2s;
  background-color: #bde7ec;
}
.setting-checkbox > i {
  width: 40px;
  border-radius: 10px;
}
.setting-checkbox > input + i:after {
  content: "";
  display: block;
  height: 14px;
  width: 14px;
  margin: 3px;
  border-radius: inherit;
  transition: inherit;
  background-color: #878b8b;
}
.setting-checkbox > input:checked + i:after {
  margin-left: 23px;
  background-color: $theme-color-green;
  box-shadow: 0 2px 4px 0 rgba(139, 137, 137, 0.5);
}
.times-wrapper .times-select label input[type="checkbox"] {
  display: none;
}
.times-wrapper .times-select label span {
  position: relative;
  display: inline-block;
  background-color: #f0f0f0;
  color: #707575;
  border-radius: 50%;
  font-size: 14px;
  border: 1px solid #bfbebe;
  overflow: hidden;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 30px;
}
.times-wrapper .times-select label input[type="checkbox"]:checked ~ span {
  background-color: #f6656d;
  color: #fff;
}
.times-select {
  padding: 0px 3px;
}
.times {
  box-sizing: border-box;
  height: 34px;
  width: 40px;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background-color: #f0f0f0;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
}
.divider {
  font-size: 16px;
  padding: 0px 10px;
}
.setting-button {
  width: 80%;
  margin: 0 auto;
}
.setting-top {
  border-bottom: 1px solid #6dbac2;
}
.setting-wrapper {
  margin: 0 -20px;
  padding: 0 20px 10px 20px;
  position: relative;
  background: #d0fae6;
  min-height: calc(100vh - 190px);
}
.setting-wrapper:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.setting-wrapper .bg-curve {
  width: 100%;
  position: absolute;
  left: 0;
  top: -96px;
  z-index: 1;
}
.setting-wrapper .settings-content {
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 1000px) {
  .setting-wrapper {
    margin-top: 100px;
    .yesterday {
      padding-top: 25px;
    }
  }
}

.journal-days-wrapper {
  flex-wrap: wrap;
  .days-select {
    position: relative;
    display: inline-block;
    background-color: #f0f0f0;
    color: #707575;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid #bfbebe;
    overflow: hidden;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 32px;
    margin-right: 5px;
  }
  .days-selected {
    background-color: #f6656d;
    color: #ffffff;
  }
}

.notifications-bell .text-right {
  text-align: right;
}
.notifications-bell .clear-all {
  color: #f6656d;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.notifications-bell i {
  color: $theme-color-green;
  font-size: 21px;
  line-height: 21px;
  margin-right: 10px;
  margin-top: 2px;
}
.notifications-bell .notification-card {
  min-height: 105px;
  padding: 25px 20px 20px 15px;
}
// .notifications-bell .notification-card.unread {
//   border: 2px solid #f6656d;
// }
.notifications-bell .notification-card.unread .notification-icon {
  color: #f6656d;
}
.notifications-bell .notification-card .title {
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;
}
.notifications-bell .notification-card .content {
  color: #757575;
  font-size: 14px;
  line-height: 22px;
}
.notifications-bell .behaviour-image {
  margin: 0 -20px;
}
.notifications-bell .behaviour-image img {
  width: 100%;
}
.notifications-bell .behaviour-wrapper .bg-curve {
  top: -165px;
}
.notifications-bell .behaviour-wrapper .behaviour-content {
  top: -80px;
}
