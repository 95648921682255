.geosuggest {
  position: relative;
}

.geo-suggest-outer {
  position: relative;
  //   margin-top: rem-calc(24px);

  .geosuggest__label {
    position: absolute;
    font-size: rem-calc(15px);
  }

  .geosuggest__input-wrapper {
    // padding-top: rem-calc(20px);
  }

  .geosuggest__input-wrapper:focus-within {
    .geosuggest__label {
      z-index: 1;
      font-size: rem-calc(13px);
      transform: translateY(-20px);
    }
  }
}

.geo-suggest-outer-act {
  position: relative;
  margin-top: rem-calc(14px);

  .geosuggest__label {
    position: absolute;
    transform: translateY(-20px);
  }
}

.geo-suggest-outer-act .pass-show-toggle-div {
  top: 35%;
}

.geosuggest__input-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  //   padding-top: rem-calc(20px);
}

.geosuggest__input {
  @include mdInput;
}

// .geosuggest__label {
//     color: #777777;
//     font-size: 0.8125rem;
//     letter-spacing: 0.01063rem;
//     line-height: 1rem;
//     margin-bottom: 0px;
//     transition: all $animation-duration;
// }

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 1px solid silver;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  color: #666666;
  font-size: rem-calc(12px);
  letter-spacing: rem-calc(0.16px);
  line-height: rem-calc(15px);
  padding: rem-calc(10px);
  cursor: pointer;
  border-bottom: 1px solid silver;
}

.geosuggest__suggests .geosuggest__item:last-child {
  border-bottom: none;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
