.privacy-popup.modal-popup .modal-popup-container {
  overflow: hidden;
  height: 90vh;
  width: calc(100% - 16px);
  max-height: 90vh !important;
}
.modal-popup {
  z-index: 1005;
}
.physician-legal-coantainer {
  max-width: 700px !important;
}
.privacy-popup.modal-popup .modal-head {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.privacy-popup.modal-popup .modal-body-text {
  height: calc(100% - 80px);
  overflow: auto;
  font-size: 16px;
}
.privacy-popup.modal-popup .modal-footer {
  padding-top: 20px;
}
.privacy-popup.modal-popup .back-button {
  font-size: 18px;
  color: #707575;
}
.button-legal {
  max-width: 300px;
  display: block;
}
.hide {
  display: none;
}
