@import "../base/placeholder.scss";

.notifications {
  @extend %row-start;
  background-color: #f8f8f8;
  min-height: 100vh;
  &__main {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px 0;
    overflow: hidden;
    .back-btn {
      display: inline-flex;
      align-items: center;
      padding: 4px 0;
      cursor: pointer;
      &-wrapper {
        position: relative;
        padding: 0 48px;
        left: -20px;
      }
      &__text {
        color: var(--primary-brand);
        font-weight: 600;
        margin-left: 12px;
      }
    }
  }
  &__container {
    width: 100%;
    flex-shrink: 0;
  }
  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__container {
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 48px;
    }
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 48px 20px;
    margin-top: auto;
    &__left {
      display: flex;
      align-items: center;
    }
    .items-list-count {
      cursor: pointer;
      transition: all 300ms;
      &:not(:first-child) {
        margin-left: 32px;
      }
      &-wrapper {
        display: flex;
        align-items: center;
        margin-left: 32px;
      }
      &:hover {
        font-weight: 700;
      }
      &.active {
        font-weight: 700;
        text-decoration: underline;
      }
    }
    &__right {
      position: relative;
      display: flex;
      align-items: center;
    }
    &__arrow-previous,
    &__arrow-next {
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        svg path {
          fill: var(--primary-text);
        }
      }
    }
  }
  .aside-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__header {
    @extend %row;
    padding: 0 48px;
    &__action-item {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 32px;
      }
      &.disabled {
        color: var(--border2);
        cursor: none;
        pointer-events: none;
      }
    }
  }
  &__avatar {
    @extend %row-center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-color: #be82c2;
    border-radius: 100%;
    &--bell-icon {
      background-color: #ffe0e2;
    }
    &.ml-5 {
      margin-left: 20px !important;
    }
  }
  &__message {
    font-size: 14px;
  }
  &__time {
    opacity: 1;
    visibility: visible;
    color: var(--secondary-text);
    font-size: 14px;
  }
  &__delete {
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
  }
  &__content-left {
    @extend %row;
    padding-right: 50px;
    flex: 1;
  }
  &__list-container {
    position: relative;
    @extend %row-between;
    padding: 12px 16px;
    border: 1px solid var(--border1);
    background: #effdff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      border: 1px solid transparent;
      box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.25);
      background-color: #d2f3f8;
      .notifications {
        &__delete {
          opacity: 1;
          visibility: visible;
        }
        &__time {
          // display: none;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &--read {
      background-color: #fff;
      .notifications__avatar--bell-icon {
        background-color: #f8f8f8;
        svg path {
          fill: #9b9b9b;
        }
      }
      &:hover {
        background-color: #fff;
        border: 1px solid var(--border1);
      }
    }
  }
  &__empty-state {
    position: relative;
    text-align: center;
    margin-top: 50px;
    &__content {
      position: absolute;
      top: 55px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-weight: 600;
    }
  }
}
.page-item {
  border: 0px solid transparent !important;
  border-radius: 4px;
  color: #dcdcdc;
  outline: none;
  .page-link {
    color: #dcdcdc;
    background-color: #ffffff;
    border-radius: 4px;
    border: 0;
    margin-left: 8px;
    margin-right: 8px;
    outline: none;
    transition: all 300ms;
    &:focus {
      box-shadow: none;
    }
  }
  &:hover {
    .page-link {
      color: #fff;
      font-weight: 700;
      background-color: #84cdd6;
    }
  }
  &.active {
    .page-link {
      color: #fff;
      font-weight: 700;
      background-color: #84cdd6;
    }
  }
}

.pagination-end-buttons.disabled {
  color: #dcdcdc;
  pointer-events: none;
  cursor: not-allowed;
}
.page-item.disabled .page-link {
  color: #dcdcdc !important;
  pointer-events: none;
  cursor: not-allowed;
}
.pagination-btn-container {
  display: flex;
  align-items: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item.pagination-end-buttons {
  .page-link {
    color: #dcdcdc;
    font-weight: 400;
    font-size: 16px;
    outline: none;
    background-color: transparent;

    &:focus {
      outline: none;
      background-color: transparent;
    }
  }
  &:hover {
    .page-link {
      color: var(--primary-text);
      font-weight: 400;
      font-size: 16px;
      background-color: transparent;
      outline: none;
    }
    svg path {
      fill: var(--primary-text);
      transition: all 300ms;
    }
  }
  &--next {
    margin-left: 80px;
  }
}

.notifications-count-per-page {
  position: absolute;
  left: calc(50% + 13px);
  color: var(--primary-text);
}
