@import "../base/placeholder.scss";

.settings {
  @extend %row-start;
  background-color: #f8f8f8;
  min-height: 100vh;
  &__main {
    @extend %row-start;
    flex: 1;
    padding: 38px 48px 140px;
    .back-btn {
      position: relative;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      &__text {
        color: var(--primary-brand);
        font-weight: 600;
        margin-left: 12px;
      }
    }
    .image-upload {
      width: 84px;
      height: 84px;
      background: #fff;
      margin-right: 24px;
      border: 1px solid var(--border1);
      border-radius: 4px;
    }
    .geosuggest__input {
      color: var(--primary-text);
      font-size: 16px;
      background: #fff url(../../../icons/search.svg) right 16px center
        no-repeat;
      border: 1px solid var(--border1);
      border-radius: 4px;
      padding-right: 50px !important;
      &:focus {
        transition: all 300ms;
        border: 1px solid var(--primary-brand);
        background-color: #fff;
      }
      &::-webkit-input-placeholder {
        font-size: 16px;
        color: var(--secondary-text);
      }
    }
    .geo-suggest-outer-act .geosuggest__input {
      border: 1px solid var(--primary-brand);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .geosuggest__suggests {
      top: 101%;
      max-height: 230px;
      border: 1px solid var(--primary-brand);
      border-top: 1px solid transparent;
      @extend %custom-scrollbar;
    }
    .geosuggest__suggests--hidden {
      border-width: 0 !important;
      max-height: 0 !important;
    }
    .geosuggest__item {
      color: var(--primary-text);
      font-size: 16px;
      padding: 8px 15px;
      line-height: 1.4;
      transition: all 300ms;
      border: none;
      &:hover {
        color: var(--primary-brand);
        font-weight: 600;
        background-color: #effdff;
      }
    }
  }
  .upload-file {
    &__input {
      display: none;
    }
    &__label {
      @extend %row;
      cursor: pointer;
      .avatar-container {
        @extend %row-center;
        width: 84px;
        height: 84px;
        background: #fff;
        border: 1px solid #dedede;
        border-radius: 4px;
      }
    }
  }
  .check-icon {
    position: absolute;
    right: -38px;
    top: 12px;
  }
  &__container {
    width: 450px;
  }
  .aside-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__banner {
    margin: 120px 40px 0 auto;
  }
  input:disabled {
    background: #dedede;
    cursor: not-allowed;
    color: #9b9b9b;
  }
}
