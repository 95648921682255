.privacy {
  .container {
    width: 1140px;
    max-width: 100%;
    padding: 30px;
    background-color: #ffffff;
  }
  h4 {
    color: black;
    text-align: center;
    font-weight: bold;
  }
  h5 {
    color: black;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
  }
  .header5 {
    color: black;
    text-align: center;
    font-weight: bold;
  }
  h6 {
    color: black;
    text-align: center;
    margin-top: 2px;
    font-weight: bold;
  }
  p,
  li {
    color: black;
    font-size: 15px !important;
    line-height: 20px;
    font-weight: 400;
    text-align: justify;
  }
  ul,
  ol {
    margin-left: 20px;
  }
  li {
    list-style-type: disc;
    font-size: 12px;
    line-height: 22px;
    color: #000;
  }

  ol li {
    list-style-type: upper-latin;
  }
  ol li ol li {
    list-style-type: decimal;
  }
  ol li ol li ol li {
    list-style-type: lower-latin;
  }

  strong i {
    font-style: italic;
  }
  .s-text {
    font-weight: 600;
  }
  .titlesub-left {
    font-size: 12px;
    line-height: 22px;
    text-decoration: underline;
    font-weight: bold;
    color: #000;
  }
}
