.modal-2 {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1050;
}
.modal-container {
  width: 761px;
  // height: calc(100vh - 60px);
  background: #ffffff;
  // margin: 30px auto;
}
.modal-title {
  padding: 19px 40px 12px 40px;
  border: 1px solid #f2f2f2;
}
.modal-title h4 {
  font-family: "transcendregular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #333333;
}
.modal-title .close-icon {
  cursor: pointer;
}
.modal-body {
  padding: 18px 40px;
  height: calc(100% - 160px);
}
.modal-body .top-section h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #328c96;
}
.modal-body .top-section h6,
.modal-body .top-section .child-age {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #328c96;
}
.modal-body .top-section .child-age {
  color: #707575 !important;
}
.modal-body .top-section p {
  font-size: 16px;
  line-height: 22px;
  color: #707575;
}
.modal-tab {
  background: #ffffff;
  border: 0.5px solid $theme-color-green;
  border-radius: 2px;
}
.modal-tab-item {
  width: calc(50% - 7px);
  flex-basis: calc(50% - 7px);
  text-align: center;
  padding: 6px 25px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.186667px;
  color: #333333;
  margin: 2px;
  position: relative;
  cursor: pointer;
}
.modal-tab-item:first-child::after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 20px;
  width: 1px;
  background: #e9e9e9;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
}
.modal-tab-item.active {
  background: #bafff9;
  font-weight: bold;
  font-size: 16px;
  color: $theme-color-green;
}
.modal-body-content {
  height: calc(100% - 103px);
  padding: 30px 0;
  overflow: auto;
}
.modal-footer {
  padding: 20px 35px;
}
.asq-table thead th {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  color: #575757;
}
.asq-table tbody td {
  font-size: 14px;
  line-height: 146.5%;
  letter-spacing: 0.64px;
  color: #333333;
  padding: 5px 0;
}

.asq-table .circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #484747;
  margin: 0 auto;
}
.asq-table .circle.selected {
  background: #484747;
  border: 1px solid #484747;
}

.asq-table .score {
  padding: 0 15px 6px;
  border-bottom: 1px solid #484747;
}
.modal-body .asq-table th:first-child,
.modal-body .asq-table td:first-child {
  width: 57%;
}
.modal-body .asq-table th:not(:first-child),
.modal-body .asq-table td:not(:first-child) {
  padding: 10px;
  text-align: center;
  vertical-align: top;
}

.milestone-table {
  thead th {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.64px;
    // text-transform: uppercase;
    color: #575757;
  }
  tbody td {
    font-size: 14px;
    line-height: 146.5%;
    letter-spacing: 0.64px;
    color: #333333;
    padding: 5px 0;
  }
  th,
  td {
    &:first-child {
      text-align: center;
      width: 10%;
      vertical-align: top;
      img {
        margin-top: 5px;
      }
    }
    &:nth-child(2) {
      width: 60%;
    }
    &:nth-child(3) {
      text-align: center;
      width: 15%;
      vertical-align: baseline;
    }
    &:nth-child(4) {
      width: 15%;
      text-align: center;
      vertical-align: baseline;
    }
  }
}
.next-section {
  position: absolute;
  bottom: -3%;
  right: 0;
  margin-right: 40px;
}
.toggle-section {
  font-weight: bold;
  font-size: 14px !important;
  color: #f6656d !important;
  cursor: pointer;
  margin-right: 5px;
}
.modal--section-report {
  & .heading-left {
    display: flex;
    flex-wrap: wrap;
    flex: 3;
    margin-right: 20px;

    & h5 {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  & .heading-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
.swyc-section {
  & .swyc-section-details {
    padding-bottom: 30px;
    width: 90%;
  }
  & h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #328c96;
    margin-bottom: 20px;
  }
  & .swyc-linear-graph {
    border: 1px solid #b3b2b2;
    border-radius: 12px;
    padding: 20px 18px;
  }
  & .screening-heading {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.283077px;
    color: #575757;
  }
  & .circle-dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 4px;
    border: 1px solid #ffffff;
  }
  & .circle-dot.red {
    background-color: #f6656d;
  }
  & .circle-dot.yellow {
    background-color: #f9db8b;
  }
  & .circle-dot.green {
    background-color: #a2f5ce;
  }

  & .swyc-bar-graph .non-gradient .graph-section.grad-r {
    background: #f6656d;
    border-radius: 10px;
  }
  & .swyc-bar-graph .non-gradient .graph-section.grad-y {
    background: #f9db8b;
    border-radius: 10px;
  }
  & .swyc-bar-graph .non-gradient .graph-section.grad-g {
    background: #a2f5ce;
    border-radius: 10px;
  }

  & .progress-circle.circle-line-indicator.red {
    background: #e44b53 !important;
    left: 17%;
    border: 2px solid #ffffff !important;
    box-shadow: "0 2px 4px 0 rgb(0 0 0 / 50%)";
    font-weight: bold;
  }
  & .progress-circle.circle-line-indicator.red:after {
    content: "0";
  }

  & .progress-circle.circle-line-indicator.yellow {
    background: #e0be64 !important;
    left: 51%;
    border: 2px solid #ffffff !important;
    box-shadow: "0 2px 4px 0 rgb(0 0 0 / 50%)";
    font-weight: bold;
  }
  & .progress-circle.circle-line-indicator.yellow:after {
    content: "1";
  }

  ​ & .progress-circle.circle-line-indicator.green {
    background: #25858f !important;
    left: 83%;
    border: 2px solid #ffffff !important;
    box-shadow: "0 2px 4px 0 rgb(0 0 0 / 50%)";
    font-weight: bold;
  }
  & .progress-circle.circle-line-indicator.green:after {
    content: "2";
  }

  // POSI
  & .progress-circle.circle-line-indicator.shadowgreen {
    background: #25858f !important;
    left: 18%;
    top: 93%;
    border: 2px solid #ffffff !important;
    box-shadow: "0 2px 4px 0 rgb(0 0 0 / 50%)";
    font-weight: bold;
  }
  & .progress-circle.circle-line-indicator.shadowgreen:after {
    content: "0";
  }

  & .progress-circle.circle-line-indicator.shadowred {
    background: #e44b53 !important;
    left: 60%;
    top: 93%;
    border: 2px solid #ffffff !important;
    box-shadow: "0 2px 4px 0 rgb(0 0 0 / 50%)";
    font-weight: bold;
  }
  & .progress-circle.circle-line-indicator.shadowred:after {
    content: "1";
  }
  .option-radio,
  .option-radio-fill {
    height: 15px;
    width: 15px;
    border: solid 1px#302F2F;
    display: inline-block;
    margin-right: 10px;
    min-width: 15px;
  }
  .option-check,
  .option-check-fill {
    height: 15px;
    width: 15px;
    border: solid 2px #8d8d8d;
    display: inline-block;
    margin-right: 10px;
    border-radius: 3px;
    position: relative;
    min-width: 15px;
  }
  .option-radio,
  .option-radio-fill {
    border-radius: 50% !important;
  }
  .option-radio-fill {
    background-color: #6b6969 !important;
    position: relative;
  }
  .option-check-fill {
    border-color: #302f30;
  }
  .option-check-fill:after {
    transform: rotate(45deg);
    height: 8px;
    width: 4px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 2px 4px;
  }

  & .option-container-left,
  & .option-container-right {
    width: 50%;
  }
  & .option-container-right {
    padding-left: 20px;
  }
  .option-label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.212308px;
    text-transform: uppercase;
    color: #575757;
  }
  .option-divider {
    border-right: 1px solid #e7e6e6;
  }
  & .screening-answers {
    flex: 1;
    margin-right: 40px;
  }
  & .screening-options {
    flex: 2;
  }
  & .w-90 {
    width: 90% !important;
    margin: 0 !important;
  }
}
.swyc-modal {
  & .modal-body-content {
    height: calc(100%) !important;
    width: 80%;
  }
  & .modal-footer {
    border: none !important;
    margin-top: 42px !important;
    padding: 0.75rem !important;
  }
}
.modal-container .download-link a,
.swyc-section .download-link a {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.212308px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #f35151;
}
