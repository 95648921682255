.reset-password {
  display: block;
  color: #707575;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  &:hover {
    color: #707575;
    text-decoration: underline;
  }
}

.register-now {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.register-now a {
  font-weight: 600;
  color: #f6656d;
  cursor: pointer;
}

.create-account-container {
  background-image: url(../images/img/background.svg);
}

.password-strength .strength-bar {
  position: relative;
  height: 2px;
  background: rgba(156, 162, 162, 0.5);
}

.password-strength .strength-bar-filed {
  position: absolute;
  height: 2px;
}

.password-strength p {
  color: #f6656d;
}

@media screen and (max-width: 350px) {
  .login-form-wrapper {
    margin-top: 0 !important;
  }
}
