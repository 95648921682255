@import "../base/placeholder.scss";

.my-profile {
  @extend %row-start;
  background-color: #f8f8f8;
  min-height: 100vh;
  &__main {
    @extend %row-start;
    flex: 1;
    padding: 38px 48px;
    .back-btn {
      position: relative;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      &__text {
        color: var(--primary-brand);
        font-weight: 600;
        margin-left: 12px;
      }
    }
  }
  .upload-file {
    &__input {
      display: none;
    }
    &__label {
      @extend %row;
      cursor: pointer;
      .avatar-container {
        @extend %row-center;
        width: 84px;
        height: 84px;
        background: #fff;
        border: 1px dashed #dedede;
        border-radius: 4px;
      }
    }
  }
  .check-icon {
    position: absolute;
    right: -38px;
    top: 12px;
  }
  &__container {
    width: 450px;
  }
  .aside-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
  &__banner {
    margin: 120px 0 0 auto;
    padding-right: 100px;
  }
}
