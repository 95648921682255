.center-name {
  color: #403f3f;
  font-family: "Lato-Medium";
  font-size: rem-calc(15px);
  font-weight: 500;
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(18px);
  margin-bottom: rem-calc(17px);

  a {
    color: $theme-color-2 !important;
  }
}

.center-subtext {
  color: #5f605f;
  font-size: rem-calc(12px);
  letter-spacing: rem-calc(0.16px);
  line-height: rem-calc(15px);

  a {
    color: #5f605f !important;
    text-decoration: underline !important;
  }
}

.center-margin-row {
  margin: rem-calc(15px) 0px 0px;
}

.center-icn-div img {
  width: rem-calc(13px);
  height: rem-calc(13px);
  margin-top: 4px;
}

.bells {
  margin-left: auto;
}
.health-head {
  background-color: $theme-color-green;
  padding: 30px 20px;
}
.health-head h2 {
  color: #ffffff;
  font-family: "transcendregular";
  font-size: 20px;
  line-height: 24px;
}
.health-head i {
  font-size: 18px;
  color: #fff;
}
.health-search input {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 10px 40px;
  width: 100%;
  border: 1px solid #bfbebe;
  border-radius: 4px;
  background-color: #f0f0f0;
}
.health-search input::placeholder {
  color: #707575;
}

.health-search {
  position: relative;
}
.location {
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 20px;
}
.searchs {
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 20px;
}
.clinic-name {
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.clinic-common {
  color: $theme-color-green;
  font-size: 14px;
  word-break: break-word;
  &:hover {
    color: $theme-color-green !important;
  }
  // line-height: 27px;
}
.clinics {
  width: 100%;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(200, 200, 200, 0.8);
}
.clinics i {
  font-size: 14px;
}

.center-icon {
  font-size: 14px;
  margin-top: 4px;
}
@media screen and (max-width: 380px) {
  .health-head h2 {
    font-size: 17px;
  }
}
