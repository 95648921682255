@import "../base/placeholder.scss";

.create-profile {
  @extend %row-start;
  background: #f8f8f8 url(../.././../images/create-profile-banner.svg) right top
    no-repeat;
  &__main {
    padding: 40px 56px 195px;
  }
  .upload-file {
    &__input {
      display: none;
    }
    &__label {
      @extend %row;
      cursor: pointer;
      .avatar-container {
        @extend %row-center;
        flex-shrink: 0;
        width: 84px;
        height: 84px;
        background: #fff;
        border: 1px dotted var(--border1);
        border-radius: 4px;
      }
    }
  }
  .check-icon {
    position: absolute;
    right: -38px;
    top: 12px;
  }
  &__container {
    width: 450px;
  }
  .aside-menu {
    position: fixed;
    top: 0;
    left: 0;
  }
}
