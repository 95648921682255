.ellipsis {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  width: 90%;
}

.active-head {
  position: relative;
  z-index: 2;
}

.active-bold {
  color: $theme-color-green;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.active-title {
  color: $theme-color-green;
  font-size: 18px;
  line-height: 24px;
}
.active-bold::after {
  content: "";
  box-sizing: border-box;
  height: 2px;
  width: 56px;
  border: 2px solid $theme-color-green;
  display: block;
  margin-top: 5px;
}
.top-contributors img {
  width: 36px;
  height: 36px;
}
.top-contributors {
  border-bottom: 1px solid #6dbac2;
}
.contributor-name i {
  font-size: 18px;
  color: #f6656d;
}
.active-wrapper {
  margin: 0 -20px;
  padding: 0 20px 10px 20px;
  position: relative;
  background: #d0fae6;
  margin-bottom: 20px;
}
.active-wrapper:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.active-wrapper .bg-curve {
  width: 100%;
  position: absolute;
  left: 0;
  top: -78px;
  z-index: 1;
}
.active-wrapper .active-content {
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 400px) {
  .top-contributors {
    padding-top: 30px;
  }
  // .active-wrapper {
  //   // margin-top: -15px;
  // }
}
@media screen and (min-width: 1000px) {
  .top-contributors {
    padding-top: 60px;
  }
  // .active-wrapper {
  //   // margin-top: -30px;
  // }
}

// ------------------------Invite Villager------------------------------
.type-select input {
  display: none;
}
.type-select label {
  width: 143px;
  height: 45px;
  padding: 7px;
  background: #ffffff;
  border: 2px solid rgba(37, 133, 143, 0.5);
}
.type-select .care {
  border-radius: 35px 0 0 35px;
  margin-bottom: 0;
}
.type-select .observ {
  border-radius: 0 35px 35px 0;
  margin-bottom: 0;
}
.type-select .type-text {
  text-align: center;
  color: rgba(37, 133, 143, 0.5);
}

.type-select input:checked + label {
  /* background: #95d9e0; */
  background: #3eaab6;
  box-shadow: inset -4px -4px 2px 0 #316d73, inset 4px 6px 3px 0 #6fb3ba;
  /*box-shadow: inset -5px -3px 3px 0 rgba(181, 37, 37, 0.5),
      inset 2px 4px 3px 0 #eeadad;*/
}
.type-select input:checked + label .selected {
  display: block;
}

.type-select input:checked + label .unselected {
  display: none;
}
.type-select input:checked + label .type-text {
  color: white;
}
.days-wrapper .days-select label input[type="checkbox"] {
  display: none;
}
.days-wrapper .days-select label span {
  position: relative;
  display: inline-block;
  background-color: #f0f0f0;
  color: #707575;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #bfbebe;
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.days-wrapper .days-select label input[type="checkbox"]:checked ~ span {
  background-color: #f6656d;
  color: #fff;
}
.days-wrapper .days-select {
  position: relative;
  display: inline-block;
  background-color: #f0f0f0;
  color: #707575;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #bfbebe;
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 12px;
}
.days-wrapper .days-selected {
  background-color: #f6656d;
  color: #fff;
}
.days-select {
  padding: 0px 5px;
}
.days-wrapper {
  flex-wrap: wrap;
  // justify-content: center;
}
