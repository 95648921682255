.profile-container {
  min-height: 100vh;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}
.profile-head img {
  height: 30px;
}
.para-title {
  color: #707575;
  font-size: 14px;
  line-height: 19px;
}
.para-head {
  color: $theme-color-green;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.para-head-2 {
  font-size: 14px;
  font-weight: normal;
}
.para-head-3 {
  font-size: 18px;
  color: $theme-color-green;
  font-weight: 600;
  line-height: 22px;
}
.para-head-i {
  color: #f6656d;
  font-size: 18px;
  margin-left: 5px;
}
.profile-picture img {
  width: 100%;
  object-fit: cover;
}
.profile-button {
  position: absolute;
  left: 50%;
  bottom: 5px;
  width: 70%;
  margin: 0 auto;
  transform: translateX(-50%);
}
.profile-picture {
  position: relative;
  margin: 0 -20px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 44px !important;
  padding: 10px 15px !important;
  color: #000000 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  border: 1px solid #bfbebe !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  padding-left: 48px !important;
  transition: all 0.3s ease;
  &:focus {
    border: 1px solid $theme-color-green !important;
    outline: none;
    box-shadow: none;
  }
}
.react-tel-input .flag-dropdown:focus {
  border: 1px solid $theme-color-green !important;
}

.country-code {
  width: 30% !important;
}
