@import "./placeholder.scss";

.row {
  @extend %row;
}
.row-start {
  @extend %row-start;
}
.row-center {
  @extend %row-center;
}
.row-between {
  @extend %row-between;
}
.flex-col-start {
  @extend %flex-col-start;
}
.flex-col-center {
  @extend %flex-col-center;
}
.heading-level1 {
  font-size: 24px;
  font-weight: bold;
}
.truncate {
  @extend %truncate;
}
.disable-content-xs {
  opacity: 0.2;
  pointer-events: none;
}
.disable-content-sm {
  opacity: 0.3;
  pointer-events: none;
}
.disable-content-md {
  opacity: 0.4;
  pointer-events: none;
}
.disable-content-lg {
  opacity: 0.5;
  pointer-events: none;
}
.avatar-container {
  @extend %row-center;
  &--rounded-full {
    border-radius: 100%;
  }
}
.avatar-contain {
  width: initial;
  height: initial;
  object-fit: contain;
  &--full {
    width: 100%;
    height: 100%;
  }
}
.avatar-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.upload-file {
  &__input {
    display: none;
  }
}
.webkit-line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.webkit-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pt-5per {
  padding-top: 5%;
}
.custom-scrollbar2 {
  /* Track */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #ededed;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    background-color: #c7c7c7;
    border-radius: 100px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    // background-color: #555;
  }
  /* Corner */
  &::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
  }
}

.link-underline {
  color: var(--primary-brand);
  font-weight: 600;
  text-decoration: initial;
  transition: all 300ms;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: var(--primary-brand);
  }
}
.form-group-error {
  color: var(--error);
  font-size: 13px;
  font-weight: 600;
}
