.m-t-0 {
  margin-top: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-auto {
  margin: 0 auto !important;
}

.ml-auto {
  margin-left: auto !important;
}
