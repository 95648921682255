.card-title {
  color: $theme-color-green;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
}

.swyc-bar-graph {
  padding: 15px 10px;
  background-color: #ffffff;
  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }
  &:nth-child(3) {
    border-radius: 0px 0px 10px 10px;
  }
  .error-div {
    border-radius: 5px 0px 0px 5px;
    border-right: solid 0.1rem #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      margin-right: 10px;
      background: #f6656d;
    }
  }
  .success-div {
    border-radius: 0px 5px 5px 0px;
    border-left: solid 0.1rem #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      margin-right: 10px;
      background: #a2f5ce;
    }
  }
  .graph-container {
    position: relative;
  }
  .graph-section {
    height: 14px;
    width: calc(100% / 2);
  }
  .invert {
    .success-div {
      border-radius: 5px 0px 0px 5px;
      justify-content: flex-start;
      border-right: solid 0.1rem #ffffff;
    }
    .error-div {
      border-radius: 0px 5px 5px 0px;
      justify-content: flex-end;
      border-left: solid 0.1rem #ffffff;
    }
    .graph-section:last-child {
      background: linear-gradient(to right, rgba(248, 142, 148, 0.89), #f6656d);
    }
    .graph-section:first-child {
      background: #a2f5ce;
    }
  }
  .graph-section:first-child {
    background: linear-gradient(to left, #ffd6d6, #f6656d);
  }
  .graph-section:last-child {
    background: #a2f5ce;
  }
  .graph-bar {
    width: calc(100% - 15px);
    height: 1px;
    // background: #4E4E4E;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 2px;
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  .white-line {
    position: absolute;
    bottom: 7px;
    width: calc(100% - 30px);
    height: 1px;
    background: white;
    left: 50%;
    transform: translateX(-50%);
  }
  .graph-label p {
    font-size: 12px;
    line-height: 16px;
    color: #797373;
  }
  .progress-circle {
    width: 32px;
    height: 32px;
    padding: 7px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    background: lightgray;
    border: solid 1px #ffffff;
    color: #ffffff;
    line-height: 16px;
    text-align: center;
  }
}

.swyc-linear-graph {
  padding: 15px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  .error-div {
    border-right: solid 0.1rem #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      margin-right: 10px;
      background: #f6656d;
    }
  }
  .success-div {
    border-left: solid 0.1rem #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      margin-right: 10px;
      background: #a2f5ce;
    }
  }
  .graph-container {
    position: relative;
  }
  .graph-section {
    height: 155px;
    width: calc(100% / 2);
  }
  .invert {
    .success-div {
      justify-content: flex-start;
    }
    .error-div {
      justify-content: flex-end;
    }
    .graph-section:last-child {
      background: linear-gradient(to right, rgba(248, 142, 148, 0.89), #f6656d);
    }
    .graph-section:first-child {
      background: #a2f5ce;
    }
  }
  .graph-section:first-child {
    background: linear-gradient(to left, rgba(248, 142, 148, 0.89), #f6656d);
  }
  .graph-section:last-child {
    background: #a2f5ce;
  }
  .graph-bar {
    width: calc(100% - 15px);
    height: 1px;
    background: #4e4e4e;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 2px;
    transform: translate(-50%, -50%) rotateZ(-15deg);
  }
  @media screen and (min-width: 575px) {
    .graph-bar {
      transform: translate(-50%, -50%) rotateZ(-14deg);
    }
  }
  @media screen and (min-width: 765px) {
    .graph-bar {
      transform: translate(-50%, -50%) rotateZ(-12deg);
    }
  }
  @media screen and (min-width: 990px) {
    .graph-bar {
      transform: translate(-50%, -50%) rotateZ(-9deg);
    }
  }
  @media screen and (min-width: 1200px) {
    .graph-bar {
      transform: translate(-50%, -50%) rotateZ(-7deg);
    }
  }
  .white-line {
    position: absolute;
    bottom: 7px;
    width: calc(100% - 30px);
    height: 1px;
    background: white;
    left: 50%;
    transform: translateX(-50%);
  }
  .graph-label p {
    font-size: 12px;
    line-height: 16px;
    color: #797373;
  }
  .progress-circle {
    width: 32px;
    height: 32px;
    padding: 7px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(15deg);
    background: lightgray;
    border: solid 1px #ffffff;
    color: #ffffff;
    line-height: 16px;
    text-align: center;
  }
}

.mchat-graph {
  padding: 15px 10px;
  .low-div {
    width: 10% !important;
  }
  .moderate-div {
    width: 25% !important;
  }
  .high-div {
    width: 65% !important;
  }
  .progress-circle-inner {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #707575;
  }
}

.mchat-graph .graph-container {
  position: relative;
}

.mchat-graph .graph-section {
  height: 155px;
  width: calc(100% / 3);
}

.mchat-graph .graph-section:first-child {
  border-radius: 10px 0 0 10px;
  background: linear-gradient(180deg, #ffffff 0%, #a2f5ce 73.74%, #6dbac2 100%);
}

.mchat-graph .graph-section:nth-child(2) {
  background: linear-gradient(180deg, #ffffff 0%, #a2f5ce 54.95%, #6dbac2 100%);
}

.mchat-graph .graph-section:last-child {
  border-radius: 0 10px 10px 0;
  background: linear-gradient(180deg, #ffffff 0%, #a2f5ce 32.69%, #6dbac2 100%);
}

.mchat-graph .graph-bar {
  // width: calc(100% - 30px);
  width: calc(100% - 15px);
  height: 4px;
  background: #bfbebe;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 2px;
  transform: translate(-50%, -50%) rotateZ(-15deg);
}

.mchat-graph .white-line {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 30px);
  height: 1px;
  background: white;
  left: 50%;
  transform: translateX(-50%);
}

.progress-circle {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(112, 117, 117, 0.499836);
}

.progress-circle-inner {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #707575;
}

.mchat-graph .graph-label p {
  font-size: 14px;
  line-height: 16px;
  color: $theme-color-green;
}

.asq-graph {
  padding: 20px 15px;
}

.asq-graph .asq-holder {
  padding-bottom: 15px;
  border-bottom: 1px solid #6dbac2;
}

.asq-graph .asq-graph-section {
  min-height: 120px;
  width: calc(100% / 5);
  position: relative;
}

.asq-graph .asq-graph-section .label {
  color: $theme-color-green;
  font-size: 12px;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  margin-top: 15px;
}

.bar-graph-section .top {
  width: 7px;
  height: 30px;
  background: #a2f5ce;
  border-radius: 4px;
  margin-bottom: 2px;
  margin: 0 auto;
}
.bar-graph-section .middle {
  width: 7px;
  height: 40px;
  background: #6dbac2;
  border-radius: 4px;
  margin-bottom: 2px;
  margin: 0 auto;
}
.bar-graph-section .bottom {
  width: 7px;
  height: 50px;
  background: $theme-color-green;
  border-radius: 4px;
  margin: 0 auto;
}

.legend-item .legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-right: 5px;
}

.legend-item .legend-name {
  font-size: 12px;
  line-height: 14px;
  color: $theme-color-green;
}
