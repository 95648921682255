.custom-select {
  &-wrapper {
    position: relative;
  }
  &-container {
    font-size: 16px;
    padding: 10px 16px;
    padding-right: 40px;
    background-color: #f8f8f8;
    border: 1px solid var(--border1);
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    &.active {
      border: 1px solid var(--primary-brand);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .custom-select__arrow-down {
        transform: rotate(180deg);
      }
    }
  }
  &__arrow-down {
    position: absolute;
    right: 16px;
    top: calc(50% - 4px);
    transition: all 300ms;
  }
}
.select-dropdown-menu {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0 0 4px 4px;
  z-index: 99;
  height: 0;
  overflow: hidden;
  transition: all 300ms;
  opacity: 0;
  visibility: hidden;
  &__list {
    padding: 8px 15px;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      color: var(--primary-brand);
      font-weight: 600;
      background-color: #effdff;
    }
  }
  &.show {
    height: max-content;
    overflow: initial;
    border: 1px solid var(--primary-brand);
    border-top: 1px solid transparent;
    opacity: 1;
    visibility: visible;
    transition: all 300ms;
  }
}
