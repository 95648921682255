// theme colors
// $theme-color-1:#08554F;
// $theme-color-2:#E98760;
$sec-bg: #f8f8f8;
// $btn-color: #E98760;

$theme-color-green: #25858f;
$theme-color-2: #e98760;
$sec-bg: #f8f8f8;
$btn-primary: #3eaab6;
$btn-secondary: #f6656d;

// text colors
$text-color-1: #ffffff;
$text-color-2: #000000;
$text-header: #3b3b3a;

// paddings
$container-padding-left: 30px;
$container-padding-right: 30px;
$container-padding-top: 25px;
$container-padding-bottom: 25px;
$section-padding-left: 25px;
$section-padding-right: 25px;
$section-padding-top: 25px;
$section-padding-bottom: 25px;
