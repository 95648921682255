.input-field {
  font-size: 16px;
  padding: 10px 16px;
  background-color: #f8f8f8;
  border: 1px solid var(--border1);
  border-radius: 4px;
  &--bg-white {
    background-color: #fff;
  }
  &:not([value=""]) {
    background-color: #fff;
    color: #374957;
  }
  &::-webkit-input-placeholder {
    font-size: 16px;
    color: var(--secondary-text);
  }
  &:focus {
    transition: all 300ms;
    border: 1px solid var(--primary-brand);
    background-color: #fff;
  }
  &--password {
    padding-right: 50px;
  }
  &--select {
    background: url(../../../icons/chevron-down.svg) right 12px center no-repeat;
    padding-right: 40px;
    outline: none;
    -webkit-appearance: none;
    cursor: pointer;
    &--bg-white {
      background: #fff url(../../../icons/chevron-down.svg) right 12px center
        no-repeat;
    }
  }
  &--search-bar {
    background: #fff url(../../../icons/search.svg) right 12px center no-repeat;
    padding-right: 50px;
  }
  &--h32 {
    height: 32px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  &--text14 {
    font-size: 14px;
    &::-webkit-input-placeholder {
      font-size: 14px;
      color: var(--secondary-text);
    }
  }
  &--text16 {
    font-size: 16px;
    &::-webkit-input-placeholder {
      font-size: 16px;
      color: var(--secondary-text);
    }
  }
  &--border2 {
    border: 1px solid #dcdcdc;
  }
  &--text-area {
    height: initial;
  }
  &--error {
    border: 1px solid var(--error);
  }
}
.input-label {
  display: block;
  font-size: 16px;
  color: #374957;
}

.eye-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
