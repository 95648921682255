.accepted {
  background: rgba(0, 158, 115, 0.19);
  border: 1px solid #009e73;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.213333px;
  color: #009e73;
  min-width: 75px;
  text-align: center;
}

.pending {
  background: #fff1d8;
  border: 1px solid #f6be85;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.213333px;
  color: #f7b167;
  min-width: 75px;
  text-align: center;
}
.rejected {
  background: rgba(213, 94, 0, 0.19);
  border: 1px solid #d55e00;
  box-sizing: border-box;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.213333px;
  color: #d55e00;
  min-width: 75px;
  text-align: center;
}

.approve {
  border-radius: 25px;
  background: transparent;
  padding: 4px 8px;
  margin-right: 20px;
  font-weight: normal;
}

.reject {
  border-radius: 25px;
  background: transparent;
  padding: 4px 8px;
  border: 1px solid #f6656d;
  color: #f6656d;
  font-weight: normal;
}

.verified {
  color: #73d571;
  font-size: 20px;
  vertical-align: middle;
  font-weight: bold;
}

.signup-arrow {
  left: 40px !important;
  z-index: 990;
}

.table-header-checkbox {
  position: absolute;
  left: 10px;
  display: inline-block;
  margin-top: -2px;
}

.table-header-checkbox.item-checkbox {
  top: 20px;
  z-index: 100;
}
.admin-users-div {
  & .react-datepicker {
    right: 30%;
  }
  & .date-selector-div-outer {
    margin-bottom: 13px;
  }
  & .export-icon {
    display: flex;
    align-items: center;
    margin-left: 20px;
    background: #ffffff;
    border: 1px solid #f0eeee;
    border-radius: 3px;
    padding: 0px 10px;
    cursor: pointer;
  }
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: #6dbac2;
    border-color: transparent;
  }

  .custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    // box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(233, 135, 96, 0.25);
    border: #adb5bd solid 1px;
  }
}

.admin-signup-head .table-header-checkbox,
.admin-signup-body .table-header-checkbox {
  left: 35px;
}

.admin-signup-body .signup-arrow {
  left: 12px !important;
}

.date-selector-div-outer {
  display: flex;
  justify-content: flex-end;
  .date-selector-div {
    display: flex;
    flex-direction: row;
    .datepicker-start {
      margin-right: 20px;
    }
    .admin-datepicker-div {
      border: 1px solid #f0eeee;
      box-sizing: border-box;
      border-radius: 3px;
      height: 35px;
      width: 245px;
      white-space: nowrap;
      background: #fff;
      .date-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.333333px;
        color: #666666;
        margin-right: 5px;
      }
      .admin__datepicker {
        // height: 35px;
        border: none;
      }
      .react-datepicker__input-container input {
        padding: 0;
      }
      .react-datepicker__input-container input:focus {
        border: none;
      }
    }
  }
}

.admin-dashboard {
  background: #ffffff;

  .graph-container {
    border: 1px solid #f1eded;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .graph-card {
    min-height: 317px;
    .inner {
      height: 272px;
    }
  }
  .graph-card-1 {
    min-height: 455px;
    .inner {
      height: 410px;
    }
  }
  .graph-card-2 {
    min-height: 332px;
    .inner {
      height: 287px;
    }
  }

  .graph-card-3 {
    min-height: 488px;
    .inner {
      height: 443px;
    }
  }

  .graph-card-4 {
    min-height: 344px;
  }

  .graph-header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.333333px;
    color: #424040;
    opacity: 0.8;
    text-transform: uppercase;
    border-bottom: 1px solid #eeecec;
    height: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .graph-header-total {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.333333px;
    color: #424040;
  }

  @media screen and (max-width: 992px) {
    .right-graph {
      padding-top: 40px;
    }
  }

  @media screen and (max-width: 450px) {
    .date-selector-div-outer {
      justify-content: center;
      .date-selector-div {
        flex-direction: column;
        .datepicker-start {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.info-text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #979797;
}

.info-text-head {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #424040;
}

.asq-bar-outer {
  height: 22px;
  width: 100%;
}

.asq-bar {
  height: 22px;
}

.asq-info {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #777777;
  .asq-legend {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

// .asq-bar-border {
//   border-top-right-radius: 4px;
//   border-bottom-right-radius: 4px;
// }

.below-cutoff {
  background-color: #c33aff;
}

.close-cutoff {
  background-color: #b7ff24;
}

.above-cutoff {
  background-color: #ff568b;
}

.swyc-bar {
  height: 22px;
  background-color: #eef0e9;
}

.swyc-fill-red {
  background-color: #ff568b;
}

.swyc-fill-green {
  background-color: #b7ff24;
}

.asq-suggested {
  background-color: #c2a0ff;
}
.asq-completed {
  background-color: #39d388;
}

.per-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
}
.black {
  color: #000000;
}

.mchat-progress-bar-outer {
  border-radius: 6px;
  background-color: #edeef7;
  height: 5px;
}

.mchat-progress-bar-inner {
  background: #a4acff;
  border-radius: 6px;
  height: 5px;
}

.mchat-pointer {
  background-color: #7a86fb;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
}

.outer-circle {
  transform: rotate(-90deg);
}

.screening-score {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.screening-info {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #777777;
}

.table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow: auto;
  }
  .table-content .table-row,
  .table-header,
  .table-footer {
    flex-direction: row;
    display: flex;
  }
  .table-content {
    div.table-row {
      padding: 8px;
      &:not(:last-child) {
        border-bottom: 1px solid #f0eded;
      }
    }
  }
  .table-header,
  .table-footer {
    padding: 16px 8px 8px;
    color: #262626;
    font-weight: 600;
  }
  .table-header {
    border-bottom: 1px solid #686666;
  }
  .table-footer {
    border-top: 1px solid #686666;
    div:not(:first-child) {
      font-weight: normal;
      color: #707575;
    }
  }
}

.child-count-wrapper {
  .table-content .table-row,
  .table-header,
  .table-footer {
    font-size: 12px;
    div {
      &:nth-child(1) {
        width: 50%;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
        text-align: right;
      }
    }
  }
}

.swyc-stats-wrapper {
  .table-content .table-row,
  .table-header,
  .table-footer {
    font-size: 12px;
    div {
      &:nth-child(1) {
        width: 28%;
      }
      &:nth-child(2) {
        width: 18%;
        text-align: right;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 18%;
        text-align: right;
      }
    }
  }
}

.swyc-development-wrapper {
  .table-content .table-row,
  .table-header,
  .table-footer {
    font-size: 12px;
    div {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 20%;
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .table-row {
    &.form-no-bg {
      background-color: #f0eded;
      border-bottom: 1px solid #e7e4e4 !important;
      div {
        &:nth-child(1) {
          font-weight: 600;
          color: #262626;
          text-transform: capitalize;
        }
      }
    }
  }
  .table-content {
    max-height: 491px;
    overflow: auto;
  }
}
