$overlay-bg: rgb(0, 0, 0);
$nav-cont-bg: #ffffff;
$nav-logo-bg: #25858f;
$nav-logo-text-col: #ffffff;

.nav-overlay-phy {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  -webkit-box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);
  -moz-box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);
  box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);

  @include screen(custom, max, 768) {
    height: 100%;
    width: 100%;
    background-color: rgba($overlay-bg, 0.8);
  }
}

.nav-bar-container-phy,
.nav-bar-container-daycare {
  background-color: $nav-cont-bg;
  height: 100vh;
  width: 220px;
  transition: all 0.3s ease;
  @include screen(custom, min, 768) {
    width: 60px;
    &:hover {
      width: 220px;
    }
  }
}

.nav-bar-container-daycare {
  .nav-logo-cust {
    img {
      height: 45px;
    }
  }
}

.nav-logo-text-phy {
  text-align: left;
  font-size: rem-calc(25px);
  letter-spacing: 0.2px;
  line-height: rem-calc(28px);
  //   padding: rem-calc(60px) 0;
  padding: rem-calc(20px 15px);
  background: #17727b;
  width: 100%;
}

.nav-links-conainer-phy {
  // @include screen(custom, max, 768) {
  padding: rem-calc(15px);
  // }
}

.nav-icon-phy {
  min-width: 33px;
  min-height: 33px;
  background: #f0f0ef;
  border-radius: 6px;
  text-align: center;
  line-height: 33px;
  margin-right: rem-calc(15px);
  &.active {
    background: #def7f5;
  }
  // @include screen(custom, min, 768) {
  //   margin-right: 0;
  //   margin: 0 auto;
  // }
}

.nav-link-cust-phy {
  color: #222222;
  font-size: rem-calc(15px);
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(20px);
  padding: rem-calc(22px) 0;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  span {
    font-family: "transcendregular";
  }
  // span {
  //   @include screen(custom, min, 768) {
  //     display: none;
  //   }
  // }
}

.nav-item-cust-phy a {
  text-decoration: none;
}

.nav-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  background-color: $overlay-bg;
  overflow-x: hidden;
  transition: 0.5s;
  -webkit-box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);
  -moz-box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);
  box-shadow: 2px 68px 10px 0px rgba(200, 200, 200, 0.8);

  @include screen(custom, min, 768) {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}

.nav-bar-container {
  background-color: $nav-cont-bg;
  height: 100vh;
}

.nav-logo-cust {
  display: flex;
  // justify-content: center;
  align-items: center;
  background-color: $nav-logo-bg;
  color: $nav-logo-text-col;
  height: 68px;
}

.nav-logo-text {
  text-align: center;
  font-size: rem-calc(15px);
  letter-spacing: 0.2px;
  line-height: 18px;
  padding: rem-calc(60px) 0;
}

.nav-links-conainer {
  padding: rem-calc(25px);
}

.nav-item-cust {
  border-bottom: 1px solid #c9cfdf;
}

.nav-link-cust {
  color: #222222;
  font-size: rem-calc(15px);
  letter-spacing: rem-calc(0.2px);
  line-height: rem-calc(20px);
  padding: rem-calc(22px) 0;
  cursor: pointer;
  text-decoration: none;
}

.nav-item-cust a {
  text-decoration: none;
}

.app-banner {
  background-color: #e1f1f3;
  color: #25858f;
  font-weight: 600;
  padding: 25px;
  display: flex;
  .party-popper {
    width: 8%;
    img {
      width: auto;
      height: 37px;
    }
  }
  p {
    width: 72%;
  }
  .store-icon {
    width: 20%;
    img {
      width: 120px;
      height: auto;
    }
  }
}
