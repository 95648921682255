.custom-checkbox {
  &__label {
    position: relative;
    @extend %row;
    cursor: pointer;
    &--mark-as-read {
      &:hover {
        .custom-checkbox__label-text {
          transition: all 300ms;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
  &__icon {
    @extend %row-center;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1.5px solid #dedede;
  }
  &__label-text {
    position: relative;
    display: inline-block;
    margin-left: 12px;
  }
  &__input {
    display: none;
    &:checked + label {
      .custom-checkbox__icon {
        background: #25858f url(../../../icons/check-white.svg) no-repeat center;
        border: 1.5px solid transparent;
      }
    }
  }
}
